import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../Axios/axios';
import { DISCUSSION_DATA_GET_STATUSES } from './TVPagePostsSlice';

export const SINGLE_POST_DISCUSSION_DATA_GET_STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

export const singlePostSlice = createSlice({
  name: 'singlePost',
  initialState: {
    singlePostData: [],
    singlePostGetStatus: SINGLE_POST_DISCUSSION_DATA_GET_STATUSES.IDLE,
  },
  reducers: {
    openSinglePageDiscussionPanel: (state, action) => {
      state.singlePostData.comment.isOpenDiscussPanel = true;
    },
    closeSinglePageDiscussionPanel: (state, action) => {
      state.singlePostData.comment.isOpenDiscussPanel = false;
    },
    // This will add a new discussion at bottom and update the discussion count on single post
    singlePageAddDiscussion: (state, action) => {
      const { total_comment, comment } = action.payload;
      state.singlePostData.comment.list.push(comment);
      state.singlePostData.comment.total = total_comment;
    },
    // This will remove the discussion and update the discussion count on single post
    singlePageRemoveDiscussion: (state, action) => {
      const { id, total_comment } = action.payload;
      state.singlePostData.comment.list =
        state.singlePostData.comment.list.filter(
          (comment) => comment.id !== id
        );
      state.singlePostData.comment.total = total_comment;
    },
    // This will update the reactions on single post
    singlePageReactionUpdate: (state, action) => {
      const { reaction_score } = action.payload;
      state.singlePostData.reaction_score = reaction_score;
    },
  },
  extraReducers: (builder) => {
    builder
      // For Single Post Data Fetching
      .addCase(singlePostDataGet.pending, (state, action) => {
        state.TVPagePostsGetStatus =
          SINGLE_POST_DISCUSSION_DATA_GET_STATUSES.LOADING;
      })
      .addCase(singlePostDataGet.fulfilled, (state, action) => {
        state.singlePostData = action.payload;
        state.TVPagePostsGetStatus =
          SINGLE_POST_DISCUSSION_DATA_GET_STATUSES.IDLE;
      })
      .addCase(singlePostDataGet.rejected, (state, action) => {
        state.TVPagePostsGetStatus =
          SINGLE_POST_DISCUSSION_DATA_GET_STATUSES.ERROR;
      })

      // For posts' discussion Fetching

      .addCase(singlePageDiscussionDataGet.pending, (state, action) => {
        state.eventPostsGetStatus = DISCUSSION_DATA_GET_STATUSES.LOADING;
      })
      .addCase(singlePageDiscussionDataGet.fulfilled, (state, action) => {
        const { list } = action.payload;
        state.singlePostData.comment.list = list;
        state.eventPostsGetStatus = DISCUSSION_DATA_GET_STATUSES.IDLE;
      })
      .addCase(singlePageDiscussionDataGet.rejected, (state, action) => {
        state.eventPostsGetStatus = DISCUSSION_DATA_GET_STATUSES.ERROR;
      });
  },
});
export const {
  singlePageDiscussionCountUpdate,
  singlePageAddDiscussion,
  singlePageRemoveDiscussion,
  closeSinglePageDiscussionPanel,
  openSinglePageDiscussionPanel,
  singlePageReactionUpdate,
} = singlePostSlice.actions;
export default singlePostSlice.reducer;

//
export const singlePostDataGet = createAsyncThunk(
  'singlePostDataGet',
  async (postId, thunkAPI) => {
    if (localStorage.getItem('token')) {
      try {
        const response = await API.get(`/posts/${postId}`, {
          headers: { Authorization: `token ${localStorage.getItem('token')}` },
        });
        return response.data;
      } catch (e) {
        if (e.response.data.message === 'Invalid token.') {
          localStorage.removeItem('token');
        }
        return thunkAPI.rejectWithValue(e.response.data);
      }
    } else {
      try {
        const response = await API.get(`/posts/${postId}`);
        return response.data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  }
);

export const singlePageDiscussionDataGet = createAsyncThunk(
  'singlePageDiscussionDataGet',
  async (commentListURL, thunkAPI) => {
    try {
      const response = await API.get(`${commentListURL}`, {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      return response.data;
    } catch (e) {
      if (e.response.data.message === 'Invalid token.') {
        localStorage.removeItem('token');
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
