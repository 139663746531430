import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalType: null,
    contentType: '',
    project: '',
    // project: {
    //     title: '',
    //     id: '',
    // },
    
    mediaBlob: [],
    mediaState: {},
    visitedMedia:[],
    mediaExtension: {},
    thumbnail: {},
    videoBlobs:[],
    openedMedia: null,
    openedMediaState: {},
    initialize: false,
    initialVideo: null,
    aspectRatio: 2 / 3,

    postDescription: '',
    categories: [],
};

const UploadContentSlice = createSlice({
    name: 'uploadContentSlice',
    initialState,
    reducers: {
        handleContentType(state, action) {
            state.contentType = action.payload;
        },
        handleModalType(state, action) {
            state.modalType = action.payload;
        },
        handleTitle(state, action) {
            state.project = action.payload;
        },
        handleMedia(state, action) {
            const data = JSON.parse(action.payload);
            state.mediaBlob = data.mediaBlob;
            state.mediaState = data.mediaState;
            state.aspectRatio = data.aspectRatio;
            state.openedMedia = data.openedMedia;
            state.openedMediaState = data.openedMediaState;
            state.mediaExtension = data.mediaExtension;
            state.visitedMedia = data.visitedMedia;
            state.videoBlobs = data.videoBlobs;
            state.thumbnail = data.thumbnail;
            state.initialVideo = data.initialVideo;
            state.initialize = data.initialize;
        },
        handleDetails(state, action) {
            const data = JSON.parse(action.payload);
            // console.log("details", data)
            state.postDescription = data.postDescription;
            state.categories = data.categories;
        },
        handleInitialize(state) {
            return initialState;
        }
    }
});

export const { handleContentType, handleModalType, handleTitle, handleMedia, handleDetails, handleInitialize, handleAttachments} = UploadContentSlice.actions;

export default UploadContentSlice.reducer;