import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../Axios/axios';
import { apiErrorCodes } from '../apiErrorCodes';

export const USER_PROFILE_DATA_GET_STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  userProfileData: [],
  userProfileDataGetStatus: USER_PROFILE_DATA_GET_STATUSES.IDLE,
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    logout(state) {
      // Reset the state to the initial value when the user logs out
      return initialState;
    },
    updateFansCount: (state, action) => {
      // Update the state with the fans count
      state.userProfileData.users.fans_count = action.payload;
    },
    updateFavouritesCount: (state, action) => {
      // Update the state with the favourites count
      state.userProfileData.users.favourites_count = action.payload;
    },
    updateTopInterest: (state, action) => {
      // Update the state with the users' top interest
      state.userProfileData.top_interest = action.payload;
    },
    addMyContentPost: (state, action) => {
      const newPost = action.payload;
      state.userProfileData.my_posts.unshift(newPost);
    },
    deleteMyContentPost: (state, action) => {
      const idsToDelete = action.payload;
      state.userProfileData.my_posts = state.userProfileData.my_posts.filter(
        (post) => !idsToDelete.includes(post.id)
      );
    },
    savePost: (state, action) => {
      console.log(action.payload);
      const postToSave = action.payload;
      state.userProfileData.my_saved_posts.unshift(postToSave);
    },
    UnSavePost: (state, action) => {
      const idsToUnSave = action.payload;
      state.userProfileData.my_saved_posts =
        state.userProfileData.my_saved_posts.filter(
          (option) => !idsToUnSave.includes(option.post.id)
        );
    },
    newNotifications: (state, action) => {

      return {
        ...state,
        userProfileData: {
          ...state.userProfileData,
          notification_status: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // For User Profile Data Fetching
      .addCase(userProfileDataGet.pending, (state, action) => {
        state.userProfileDataGetStatus = USER_PROFILE_DATA_GET_STATUSES.LOADING;
      })
      .addCase(userProfileDataGet.fulfilled, (state, action) => {
        state.userProfileData = action.payload;
        state.userProfileDataGetStatus = USER_PROFILE_DATA_GET_STATUSES.IDLE;
      })
      .addCase(userProfileDataGet.rejected, (state, action) => {
        state.userProfileDataGetStatus = USER_PROFILE_DATA_GET_STATUSES.ERROR;
      });
  },
});
export const {
  logout,
  updateFansCount,
  updateFavouritesCount,
  updateTopInterest,
  addMyContentPost,
  deleteMyContentPost,
  savePost,
  UnSavePost,
  newNotifications,
} = userProfileSlice.actions;
export default userProfileSlice.reducer;

// Thunk for User Profile Data Fetching
export const userProfileDataGet = createAsyncThunk(
  'userProfileDataGet',
  async () => {
    try {
      const response = await API.get('/accounts/myprofile/', {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      if (response.data.code === apiErrorCodes.SUCCESS) {
        return response.data.message;
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
      }
    }
  }
);