import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMuted: true,
};

export const videoPreferenceSlice = createSlice({
  name: 'videoPreference',
  initialState,
  reducers: {
    //  reducers comes here
    toggleMuteState: (state, action) => {
        state.isMuted = action.payload;
    }
  },
});
export const { toggleMuteState } = videoPreferenceSlice.actions;
export default videoPreferenceSlice.reducer;
