import React, {useEffect, useRef, useState} from 'react';
import classes from './TVCardMediaCarousel.module.css';
import ReactMultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'

//! Post Placeholder image
import PlaceholderImg1 from '../../../../Assets/PlaceholderImages/1.webp';
import PlaceholderImg2 from '../../../../Assets/PlaceholderImages/2.webp';
import PlaceholderImg3 from '../../../../Assets/PlaceholderImages/5.webp';

import CustomDots from "./CustomDots/CustomDots";
import {LeftArrow, RightArrow} from './CustomArrow/CustomArrow';
import TVCardVideo from '../TVCardVideo/TVCardVideo';

const images = [{url: PlaceholderImg1}, {url: PlaceholderImg2}, {url: PlaceholderImg3},];
const allowedVideoExtensions = ['.mkv', '.mp4', '.mov'];

export default function TVCardMediaCarousel({postData, isVisible}) {
  const {image_field: {images}, aspect_ratio} = postData;
  const ref = useRef(null);

  const [mediaWidth, setMediaWidth] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    setMediaWidth(ref.current.offsetWidth);
  }, []);

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000, min: 1024,
      }, items: 1,
    }, mobile: {
      breakpoint: {
        max: 464, min: 0,
      }, items: 1,
    }, tablet: {
      breakpoint: {
        max: 1024, min: 464,
      }, items: 1,
    },
  };

  const afterChange = (previousSlide, {currentSlide, onMove}) => {
    setCurrentSlide(currentSlide);
  };

  return (<div ref={ref}>
    <ReactMultiCarousel
      draggable={false}
      keyBoardControl
      pauseOnHover={false}
      responsive={responsive}
      // removeArrowOnDeviceType={['mobile', 'tablet']}
      showDots
      customLeftArrow={<LeftArrow/>}
      customRightArrow={<RightArrow/>}
      customDot={<CustomDots/>}
      slidesToSlide={1}
      shouldResetAutoplay
      swipeable
      focusOnSelect={true}
      itemClass={classes.listItem}
      afterChange={afterChange}
    >
      {images.map((media, index) => {
        return (
          <div key={index} className={classes.slide}>
            {allowedVideoExtensions.some(extension => media.includes(extension)) ?
              <TVCardVideo key={index} src={media} aspectRatio={aspect_ratio} isVisible={isVisible && index===currentSlide} />
              :
              <img draggable="false" className={classes.img} alt='placeholder_image' key={index} src={media}
                style={{ aspectRatio: `${1 / aspect_ratio}` }} />
            }
          </div>
        )
      }
      )}

    </ReactMultiCarousel>
  </div>)
}
