import React, { useState } from 'react';

// Stylesheet import
import classes from './FlagElement.module.css';

// Icon import from Feather Icons
import { Flag } from 'react-feather';

// Modal component import from Material UI
import { Modal, Box, Grow } from '@mui/material';

// Cross Icon for modal
import { FiX } from 'react-icons/fi';

import API from '../../../../../Axios/axios';
import { useNavigate } from 'react-router-dom';

function FlagElement(props) {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [query, setQuery] = useState('');
  const [flagged, setFlagged] = useState(props.postFlagged);
  const token = localStorage.getItem('token');
  const [isModalActive, setIsModalActive] = useState(false);

  const openHandler = () => {
    if (!token) {
      navigate('/entry/signin');
    } else if (!flagged) {
      setIsModalActive(true);
    }
  };

  const closeHandler = () => {
    setIsModalActive(false);
  };

  const onSubmit = async (e) => {
    if (token) {
      setButtonDisabled(true);
      e.preventDefault();

      try {
        const response = await API.post(
          `/posts/${props.postId}/flaged`,
          {
            reason: query,
          },
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        setButtonDisabled(false);
        setFlagged(true);
        closeHandler();
      } catch (e) {
        if (e.response.data.message === 'Invalid token.') {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    } else {
      navigate('./entry/signin');
    }
  };

  return (
    <>
      <div className={classes.element} onClick={openHandler}>
        {/* Changing flag icon and text on reporting */}
        {flagged ? (
          <Flag className={classes.flaggedIcon} />
        ) : (
          <Flag className={classes.icon} />
        )}
        <p style={{ display: 'contents' }} className="element-heading">
          {flagged ? 'Flagged' : 'Flag'}
        </p>
      </div>

      <Modal open={isModalActive} onClose={closeHandler}>
        <div>
          <Grow in={true}>
            <Box className={classes.modalContainer}>
              <FiX className="cross-button" onClick={closeHandler} />
              <form
                className={classes.reportForm}
                onSubmit={onSubmit}
                autoComplete="off"
              >
                <p className={classes.heading}>What's wrong?</p>
                <textarea
                  className={classes.reportTextArea}
                  type="text"
                  maxLength={250}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Box className={classes.buttonBox}>
                  {query.length > 0 && !buttonDisabled ? (
                    <button
                      type="submit"
                      className={classes.reportButtonEnabled}
                    >
                      Report
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={classes.reportButtonDisabled}
                    >
                      Report
                    </button>
                  )}
                </Box>
              </form>
            </Box>
          </Grow>
        </div>
      </Modal>
    </>
  );
}

export default FlagElement;
