import React, { useState } from 'react';

// Stylesheet import
import classes from './DiscussForm.module.css';

// Icon import from Feather icon
import { Send } from 'react-feather';

// Import Zoom transition from Material UI
import { Zoom } from '@mui/material';

// SuccessIcon import
import SuccessIcon from '../../../../CommonComponents/SuccessIcon/SuccessIcon';

// Use selector import
import { useDispatch } from 'react-redux';

// API base URL imported
import API from '../../../../../Axios/axios';

import {
  addDiscussion,
  openDiscussionPanel,
} from '../../../../../Store/TVPagePostsSlice';
import {
  openSinglePageDiscussionPanel,
  singlePageAddDiscussion,
} from '../../../../../Store/singlePostSlice';

function DiscussForm({ postData, postType }) {
  // Destructuring postData props
  const {
    id,
    comment: { create_url, isOpenDiscussPanel },
  } = postData;
  const dispatch = useDispatch();
  const [inputVal, setInputVal] = useState('');
  const [isAcknowledgementActive, setIsAcknowledgementActive] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();
    // This will prevent the user from submitting the form if the entered value is empty or contains only space
    if (!inputVal || /^\s+$/.test(inputVal)) return;

    // this will change the sending button into success icon
    setIsAcknowledgementActive(true);

    // this will change the success icon into sending button again after 1 minute
    setTimeout(() => {
      setIsAcknowledgementActive(false);
    }, 1000);
    // Updating Data logic here
    setInputVal('');

    // API call
    try {
      const response = await API.post(
        `${create_url}`,
        {
          text: inputVal,
        },
        {
          headers: { Authorization: `token ${localStorage.getItem('token')}` },
        }
      );
      if (response.data) {
        if (postType === 'single') {
          // This will add a new discussion on the TVPagePostSlice
          dispatch(singlePageAddDiscussion(response.data));
          if (!isOpenDiscussPanel) {
            // This will open the discussion panel on the TV page only if it is closed
            dispatch(openSinglePageDiscussionPanel(id));
          }
        } else {
          // This will add a new discussion on the TVPagePostSlice
          dispatch(addDiscussion(response.data));
          if (!isOpenDiscussPanel) {
            // This will open the discussion panel on the TV page only if it is closed
            dispatch(openDiscussionPanel(id));
          }
        }
      }
    } catch (e) {
      if (e.response.data.message === 'Invalid token.') {
        localStorage.removeItem('token');
      }
    }
  };

  // Change handler for discussion input
  const changeHandler = (event) => {
    setInputVal(
      event.target.value.replace(/[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@. ]/gm, '')
    );
  };

  return (
    <div>
      <form
        // action='submit'
        className={classes.discussForm}
        onSubmit={submitHandler}
      >
        <input
          type="text"
          className={classes.discussInput}
          placeholder="Discuss..."
          onChange={changeHandler}
          value={inputVal}
        />
        <div className={classes.acknowledgementContainer}>
          <SuccessIcon checked={isAcknowledgementActive} />
        </div>
        <button type="submit" className={classes.sendBtn}>
          <Zoom in={!isAcknowledgementActive}>
            <Send className={classes.sendIcon} />
          </Zoom>
        </button>
      </form>
    </div>
  );
}

export default DiscussForm;
