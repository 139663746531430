import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import SecondModal from '../../AccountDeletion/ReasonForLeavingModal/SecondModal';
import { FiX } from 'react-icons/fi';

import classes from './FirstModal.module.css';
import { Grow } from '@material-ui/core';

const FirstModal1 = (props) => {
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    props.handleModal();
  };
  const ModalOpen = () => {
    setSecondModalOpen(true);
  };
  const ModalClose = () => {
    setSecondModalOpen(false);
    props.handleModal();
  };
  return (
    <>
      {secondModalOpen ? (
        ''
      ) : (
        <Modal open={open} onClose={handleClose}>
          <div>
            <Grow in={true}>
              <Box className={classes.firstModal}>
                <Box>
                  <FiX className="cross-button" onClick={handleClose} />
                </Box>
                <Box className={classes.firstModalMain}>
                  <Box className={classes.firstModalHeading}>
                    <h3>Are You Sure?</h3>
                  </Box>

                  <Box className={classes.firstModalBody}>
                    <button className={classes.yesButton} onClick={ModalOpen}>
                      YES
                    </button>
                    <button className={classes.noButton} onClick={handleClose}>
                      NO
                    </button>
                  </Box>
                </Box>
              </Box>
            </Grow>
          </div>
        </Modal>
      )}

      {secondModalOpen && <SecondModal ModalClose={ModalClose} />}
    </>
  );
};

export default FirstModal1;
