import React, { useState } from 'react';

// Cross button import
import { FiX } from 'react-icons/fi';

// Card component import
import Card from '../../../CommonComponents/Card/Card';

// MUI stuff import
import { Modal, Box, Grow } from '@mui/material';

// Stylesheet import
import classes from './OptionModal.module.css';
import Helpdesk from '../Helpdesk/Helpdesk';

export default function OptionModal(props) {
  // State for opening and closing the option modal
  const [open, setOpen] = useState(true);

  const [helpdeskAndFeedbackModel, setHelpdeskAndFeedbackModel] =
    useState(false);

  const [typeOfModel, setTypeOfModel] = useState('');

  // function fr opening the helpdesk submission modal
  const helpdeskClickHandler = () => {
    setHelpdeskAndFeedbackModel(true);
    setOpen(false);
    setTypeOfModel('question');
    // props.handleModal();
  };

  // function for opening the feedback submission modal
  const FeedbackClickHandler = () => {
    setHelpdeskAndFeedbackModel(true);
    setOpen(false);
    setTypeOfModel('suggestion');
    // props.handleModal();
  };

  // modal close handler
  const optionModalCloseHandler = () => {
    setOpen(false);
    props.handleModal();
  };

  return (
    <>
      {helpdeskAndFeedbackModel && (
        <Helpdesk typeOfModel={typeOfModel} handleModal={props.handleModal} />
      )}
      <Modal open={open} onClose={optionModalCloseHandler}>
        <Card>
          <div>
            <Grow in={true}>
              <Box className={classes.popup}>
                <div className={classes.container}>
                  <div className={classes.header}>
                    <FiX
                      className="cross-button"
                      onClick={optionModalCloseHandler}
                    />
                    <h4 className="component-heading">Please Select</h4>
                  </div>
                </div>
                <div className={classes.container}>
                  <div className={classes.item} onClick={helpdeskClickHandler}>
                    <h4 className="element-heading">I have a question </h4>
                  </div>
                  <div className={classes.item} onClick={FeedbackClickHandler}>
                    <h4 className="element-heading">I have a suggestion</h4>
                  </div>
                </div>
              </Box>
            </Grow>
          </div>
        </Card>
      </Modal>
    </>
  );
}
