// Stylesheet import
import classes from './SearchInputCI.module.css';

// Icon import from Feather icon
import { Search as SearchIcon } from 'react-feather';

const SearchInput = (props) => {
  return (
    <form
      className={classes.form}
      style={{
        width: props.width,
        display: props.display,
        justifyContent: props.justify,
      }}
    >
      <input
        type={props.data.type}
        className={classes.input}
        placeholder={props.placeholder}
        autoFocus={props.showClose}
        onChange={props.onChange}
        value={props.value}
      />
      <SearchIcon
        className={classes.icon}
        sx={{ fontSize: 25, color: '#b9b9b9' }}
      />
    </form>
  );
};
export default SearchInput;
