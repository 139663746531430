import React, {useState} from 'react';

// Stylesheet import
import classes from './TVCardInteraction.module.css';

// Icon import from Feather icon
import {Clock as Time, Copy, MessageSquare as Discuss, Paperclip as Attachments,} from 'react-feather';

// Reactions component import
import TVCardReactions from '../TVCardReactions/TVCardReactions';

// MyToolTip Component import
import MyToolTip from '../../../CommonComponents/MyToolTip';
// useDispatch hook import
import {useDispatch} from 'react-redux';

// Import to calculate time difference
import calcTimeDifference from '../../../../HelperFunctions/calcTimeDifference';

// Import to round off the number
import numberRoundOff from '../../../../HelperFunctions/numberRoundOff';

import CopyLinkModal from './CopyLinkModal/CopyLinkModal';
import GoToSignInModal from '../../../CommonComponents/GoToSignInModal/GoToSignInModal';

import formatDate from '../../../../HelperFunctions/formatDate';
import AttachmentDownloadModal from './AttachmentDownloadModal/AttachmentDownloadModal';
import {closeDiscussionPanel, discussionDataGet, openDiscussionPanel,} from '../../../../Store/TVPagePostsSlice';
import {
  closeSinglePageDiscussionPanel,
  openSinglePageDiscussionPanel,
  singlePageDiscussionDataGet,
} from '../../../../Store/singlePostSlice';
import API from "../../../../Axios/axios";
import {apiErrorCodes} from "../../../../apiErrorCodes";
import {useNavigate} from "react-router-dom";

function Interaction({postData, postType}) {
  // Destructuring postData props
  const {
    id,
    created_at,
    reaction_score: {total_score},
    comment: {list_url, total, isOpenDiscussPanel},
    file_attachments_count
  } = postData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openAttachmentDownloadModal, setOpenAttachmentDownloadModal] = useState(false);
  const [isCopyLinkModal, setIsCopyLinkModal] = useState(false);
  const [goToSignInModal, setGoToSignInModal] = useState(false);
  const [attachmentModalData, setAttachmentModalData] = useState(false);

  const attachmentCliCkHandler = async () => {
    try {
      const response = await API.get(`/posts/${id}/file_attachments/`, {
        headers: {Authorization: `token ${localStorage.getItem('token')}`},
      });
      if (response.data.code === apiErrorCodes.SUCCESS) {
        setOpenAttachmentDownloadModal(true);
        setAttachmentModalData(response.data.message);
      }

    } catch (error) {
      console.log(error.response.data);
      if (error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED || error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED || error.response.data.code === apiErrorCodes.INVALID_TOKEN || error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };

  const copyButtonClickHandler = async () => {
    setIsCopyLinkModal(true);
    // This will copy the link to the clipboard
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/post/${id}`);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  const discussIconClickHandler = async (postId) => {
    if (localStorage.getItem('token')) {
      if (postType === 'single') {
        if (total !== 0) {
          if (isOpenDiscussPanel) {
            // It will close the discussion panel only if it is open
            dispatch(closeSinglePageDiscussionPanel());
          } else {
            // This will get the discussion data from the API server and after that open the discussion panel
            dispatch(singlePageDiscussionDataGet(list_url))
              .then(() => {
                dispatch(openSinglePageDiscussionPanel());
                window.scrollTo({
                  top: document.documentElement.scrollHeight, behavior: 'smooth',
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      } else if (total !== 0) {
        if (isOpenDiscussPanel) {
          // It will close the discussion panel only if it is open
          dispatch(closeDiscussionPanel(postId));
        } else {
          // This will get the discussion data from the API server an after that open the discussion panel
          dispatch(discussionDataGet(list_url))
            .then(() => {
              dispatch(openDiscussionPanel(postId));
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    } else {
      setGoToSignInModal(true);
    }
  };

  return (<>
    {goToSignInModal && (<GoToSignInModal setGoToSignInModal={setGoToSignInModal}/>)}
    {isCopyLinkModal && (<CopyLinkModal setIsCopyLinkModal={setIsCopyLinkModal} text={`Link copied.`}/>)}
    {openAttachmentDownloadModal && (<AttachmentDownloadModal
      setOpenAttachmentDownloadModal={setOpenAttachmentDownloadModal}
      attachmentModalData={attachmentModalData}
    />)}
    <div className={classes.interactions}>
      <div className={classes.interactionContainer}>
        <TVCardReactions postData={postData} postType={postType}/>
        {file_attachments_count > 0 && <MyToolTip title="Attachment" placement="top">
          <Attachments
            className={classes.attachmentIcon}
            onClick={attachmentCliCkHandler}
          />
        </MyToolTip>}
        <MyToolTip title={formatDate(created_at)} placement="top">
          {file_attachments_count === 0 ? <Time className={classes.clockIcon} style={{marginLeft: 'auto'}}/> :
            <Time className={classes.clockIcon}/>}
        </MyToolTip>
        <MyToolTip title="Discussion" placement="top">
          <Discuss
            className={classes.discussIcon}
            onClick={() => discussIconClickHandler(id)}
          />
        </MyToolTip>
        <MyToolTip title="Copy Link" placement="top-end">
          <Copy
            className={classes.shareIcon}
            onClick={copyButtonClickHandler}
          />
        </MyToolTip>
      </div>
      <div className={classes.counterContainer}>
        <p className={classes.reactionCounter}>
          {total_score === 0 ? '' : total_score}
        </p>
        <p
          className={classes.attachmentCounter}
          onClick={attachmentCliCkHandler}
        >
          {file_attachments_count === 0 ? '' : file_attachments_count}
        </p>
        <p className={classes.timeCounter}>
          {calcTimeDifference(created_at)}
        </p>
        <p className={classes.discussCounter}>
          {total === 0 ? '' : numberRoundOff(total)}
        </p>
        <p className={classes.shareCounter}></p>
      </div>
    </div>
  </>);
}

export default Interaction;
