import React from 'react';
import classes from './TermsAndConditionsPage.module.css';
import { Helmet } from 'react-helmet-async';

export default function TermsAndConditionsPage() {
  return (
    <div>
      <Helmet>
        <title>Terms & Conditions | Srijcon</title>
        <link rel="canonical" href="/termsandconditions" />
        <meta
          name="description"
          // content="Srijcon - Connect with innovators, inventors, makers, creators and share your projects, innovations, creative works or STEM creations with the world."
          content="Srijcon connects creative and Innovative minds to come together and share inspiring creations and ideas to impact the world."
        />
      </Helmet>
      <div
        className={classes.termsAndConditionsMainBox}
        onContextMenu={(e) => e.preventDefault()}
      >
        <h1 className="termsAndConditionsH1Heading">
          Terms of Use and Privacy Policy
        </h1>
        <hr />
        <br />
        <h4 className="termsAndConditionsH4Heading">
          This Agreement is effective from 21st December 2022.
        </h4>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          Srijcon’s vision is to build an ecosystem and a one-stop Platform for
          all the makers, creators, innovators and inventors to come together
          and develop ingenious ideas of the future. Through our social
          networking and media sharing Platform we want to create an impact by
          enabling all the creative minds to share their amazing creations and
          ideas with the world.
        </p>
        <br />
        <h2 className="termsAndConditionsH2Heading">1. Introduction</h2>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          Please read this “Terms of Use” agreement before using the Services
          offered by Srijcon Innovations Pvt. Ltd. (hereinafter referred to as
          “Srijcon”, “Company”, “us” or “we”). These terms of use set forth the
          legally binding terms and conditions for your use of the Website at{' '}
          <strong>srijcon.com</strong> (hereinafter referred to as the
          “Platform”, “Site” or “Website”) and the services, features, content,
          products and applications offered by the Company and/or third parties
          (collectively with the Site, hereinafter referred to as “Service” or
          “Services”). These terms and conditions of use (hereinafter referred
          to as the “Contract”, “Terms”, “User Agreement” or “Agreement”) govern
          our Site or Services that belong to, or are managed by Srijcon
          Innovations Pvt. Ltd.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">1.1 Agreement</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          You agree that by Registering an account on our Platform, or by using
          any of our Services (even on behalf of an organization), you are
          entering into a legally binding contract with Srijcon Innovations Pvt.
          Ltd. If you do not agree with the Contract or do not wish to enter
          into this Agreement, then do not register your account or use any of
          our Services. If you wish to terminate this Agreement at any time, you
          can do so by deleting your account and no longer using any Services
          provided or managed by Srijcon.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">1.2 Members</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          This Agreement is applicable to your use and access of any of our
          Services such as websites, apps and/or communications and other
          services that are offered under this Agreement, including the offsite
          collection of data for those Services, such as our ads. Registered
          users of our Services are “Members” or “Users” and unregistered users
          are “Visitors”. To register a User account, user must provide all
          required data or information in a complete and truthful manner.
          Failure to do so will cause unavailability of the Service.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">
          1.3 Agreement Modifications
        </h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          Srijcon may modify and revise these Terms of use at any time without
          notice. These Terms of use may be updated any time and/or each time We
          update our Terms of use. By continuing to use these Services you are
          agreeing to be bound by the then current version of these Terms of
          use. Any modifications to these Terms shall be effective as of the
          date posted. We encourage you to periodically review these Terms. If
          Users do not agree to be bound by these changes, they must stop using
          the Service. Failure to accept and act upon the revised Terms, may
          entitle either party to terminate the Agreement.
        </p>
        <br />
        <br />
        <h2 className="termsAndConditionsH2Heading">2. Obligations</h2>
        <br />
        <h3 className="termsAndConditionsH3Heading">2.1 Eligibility</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          To be eligible to use our Platform you must be at least 13 years of
          age or older. Also you can have only one Srijcon account which will be
          on your real name. You must not be restricted by Srijcon to use our
          Services. Using false information to create an account is a violation
          of our Terms including but not limited to accounts registered on
          behalf of others or persons under the age of 13.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">2.2 Guidelines</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          To use our Services, Users must adhere to following guidelines as
          failing to do so can result in potential damage/loss to User or other
          Users on the Platform and/or will result in suspension or permanent
          deletion of your account.
        </p>
        <ol>
          <li className={classes.termsAndConditionsParagraph}>
            Users are responsible for keeping their login/Sign-in credential
            confidential and safe. For this reason, Users are required to choose
            passwords that meet the highest standards of strength permitted by
            the Platform.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Users must provide accurate information to us and keep it updated on
            the Platform. Users are also required to use the Platform in the
            professional manner.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            You agree that you will not create a false identity on Srijcon,
            misrepresent your identity, create a Member profile for anyone other
            than yourself (a real person), or use or attempt to use other’s
            account or transfer any part of your account.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            By registering, Users agree to be fully responsible for all the
            activities that occur under their username and password. Users are
            required to immediately and unambiguously inform Srijcon Innovations
            Pvt. Ltd., if they think their personal information including but
            not limited to User accounts, access credentials or persona; data,
            have been violated, unduly disclosed or stolen.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Srijcon allows User to upload/share their own content on the
            Platform. By providing content on the Platform you agree that you
            are legally allowed to do so, and you are not violating any
            statutory provisions and/or any third-party rights.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            You agree that your activities on our Platform will not violate any
            applicable law including, without limitation, privacy laws,
            intellectual property laws, anti-spam laws, tax laws, and regulatory
            requirements or terms of any existing agreements you may have with
            Srijcon.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Our Platform may only be used for personal purposes. Users may not
            copy, download, share (beyond the limits set forth below),
            reproduce, duplicate, modify, translate, transform, publish,
            transmit, rent, lease, loan, sell, resell, sublicense, edit,
            transfer/assign to third parties, create derivative works or exploit
            for any commercial purposes, any content and/or any portion of our
            Platform or access to our Platform in particular, but without
            limitation, available on this Platform, nor allow any third party to
            do so through the User or their device, even without the User's
            knowledge. Srijcon holds and reserves all intellectual property
            rights for any such content.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            You agree that you are responsible for any content that you submit
            to our Sites.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Stay relevant- stay on topic with relevant information that others
            may find useful.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not post advertisements other than allowed on the Platform.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Be respectful- maintain a friendly environment towards all Users,
            creators and other entities on the Platform.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not post any material that is inappropriate or that could
            reasonably be deemed defamatory, offensive or obscene.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not use the Platform for illegal purposes or to breach any laws.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not harass, cause distress or inconvenience to, or infringe the
            rights of, another person.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not try to access administrator areas, features or functions of
            our Platform without authorization.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not submit anything to our Platform that will or may violate the
            rights of a third party, including but not limited to copyright,
            trademark, and/or privacy rights.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not disclose information that you do not have the consent to
            disclose (such as confidential information of others).
          </li>

          <li className={classes.termsAndConditionsParagraph}>
            You agree to not use/misuse other User’s intellectual property,
            ideas and work in a way that can potentially cause damage, financial
            loss or inconvenience to the content owner.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            You also agree that Srijcon is not responsible for and can’t
            guarantee protection of your Intellectual property and ideas. If you
            share your ideas/work on our Platform you acknowledge that you are
            well aware about potential use/misuse of your idea/work for personal
            purpose or commercial purpose by other Users, Visitors or people
            outside the Platform.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            You agree not to copy, use, disclose or distribute any information
            obtained from the Services, whether directly or through third
            parties (such as search engines), without the consent of Srijcon.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not violate the intellectual property or other rights of Srijcon,
            including, without limitation, (i) copying or distributing our
            materials or (ii) copying or distributing our technology, unless it
            is released under open source licenses; (iii) using the word
            “Srijcon” or our logos in any business name, email, or URL without
            written consent from our authorized representative;
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not use our Platform or content on them in any way not permitted
            by these terms.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            You agree that you shall not place false or misleading information
            on our Platform or upload any computer programs- including but not
            limited to viruses, Trojan horses, worms and/or time bombs- that may
            interfere with the proper operation of the Platform by us or the
            users.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not reverse engineer, decompile, disassemble, decipher or
            otherwise attempt to derive the source code for the Services or any
            related technology that is not open source.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not engage in “framing,” “mirroring,” or otherwise simulating the
            appearance or function of the Services.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            You agree not to overlay or otherwise modify the Services or their
            appearance (such as by inserting elements into the Services or
            removing, covering, or obscuring an advertisement included on the
            Services).
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            You agree that you will not develop, support or use software,
            devices, scripts, robots or any other means or processes (including
            without limitation, crawlers, browser plugins and add-ons or any
            other technology) to scrape the Services or otherwise copy profiles
            and other data from the Services.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not use bots or other automated methods to access the Services,
            add or download contacts or redirect messages.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            You agree to not monitor the Services’ availability, performance or
            functionality for any competitive purpose.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not override any security feature or bypass or circumvent any
            access controls or use limits of the Service.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            You agree to not interfere with the operation of, or place an
            unreasonable load on, the Services (e.g., spam, denial of service
            attack, viruses, gaming algorithms) and/or Violate any additional
            terms concerning a specific service that are provided when you sign
            up for or start using such Service.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            We reserve the right to remove any content including but not limited
            to discussions, comments, posts, media, advertisements and/or
            events. We also reserve the right to restrict Users who don’t follow
            these guidelines, have content containing offensive material, share
            content that does not follow Platform’s intent/objective, or for any
            other reason.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Srijcon reserves the right at its sole discretion to suspend or
            delete at any time and without notice User accounts which it deems
            inappropriate, offensive or in violation of these terms.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Do not imply or state that you are affiliated with or endorsed by
            Srijcon without our express consent.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            The suspension or deletion of User account shall not entitle Users
            to any claims for compensation, damages or reimbursement.The
            suspension or deletion of accounts due to causes attributable to the
            User does not exempt the User from paying any applicable fees or
            prices.
          </li>
        </ol>
        <br />
        <h3 className="termsAndConditionsH3Heading">2.3 Fees and Payment</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          If you purchase any of our paid Services, you agree to pay us the
          applicable fees along with the taxes and to additional terms specific
          to the paid Services. Failure to pay these fees will result in
          termination of your paid Services. Also you agree that your purchase
          may be subjected to foreign exchange fees or differences in prices
          based on the location (e.g. exchange rates). We may also calculate
          taxes payable by you based on the billing information that you provide
          us at the time of purchase.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">2.4 Notice and Messages</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          You agree to receive notifications, notices and messages from us
          either through the Service or sent to the contact information provided
          to us by you such as email, phone number and/or physical address. You
          agree to keep your contact information up to date.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">2.5 Content Publishing</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          Srijcon allows User to message or share information in various modes
          such as User profile, posts, media, discussions, advertisement,
          comments and events. Information and content that you share may be
          seen by other Members, Visitors or others (including off the
          Services). Srijcon is not obliged to publish any of your information
          or content on our Service and can remove it anytime with or without
          notice. Srijcon is also not obliged to monitor every content and its
          correctness on the Platform.
        </p>
        <br />
        <h2 className={classes.termsAndConditionsH2Heading}>
          3. Rights and Limits
        </h2>
        <br />
        <h3 className="termsAndConditionsH3Heading">
          3.1 Your License to Srijcon
        </h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          You own the content and information that you share on the Platform. By
          sharing content and information through our Services and the service
          of others you are granting Srijcon and our affiliates a worldwide,
          non-exclusive, fully paid-up, transferable, sub-licensable and
          royalty-free license to use, display, copy, modify, distribute,
          publish, edit, create derivative works from and process, information
          and content, without any further consent, notice and/or compensation
          to you or others. To the extent permitted by applicable law, Users
          waive any moral rights in connection with content they provide to the
          Services. These rights are limited in the following ways:
        </p>
        <ol>
          <li className={classes.termsAndConditionsParagraph}>
            You can end this license for specific content by deleting such
            content from our Platform or Services, or by deleting your account,
            except to the extent you shared it with others as part of the
            Service and they copied, re-shared it or stored it and for the
            reasonable time it takes to remove from backup and other systems.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            By submitting suggestions or other feedback regarding our Services
            to Srijcon, you agree that Srijcon can use and share (but does not
            have to) such feedback for any purpose without compensation to you.
          </li>
        </ol>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          You agree to only provide content or information that does not violate
          the law nor anyone’s rights. Srijcon may be required by law to remove
          certain information or content from the Platform.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">
          3.2 Service Availability
        </h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          To ensure the best possible service level, Srijcon reserves the right
          to interrupt the Service for maintenance, system updates or any other
          changes with or without any notice. We may change, suspend or
          discontinue any/all of our Services. Additionally, the Service might
          not be available due to reasons outside Srijcon’s reasonable control,
          such as “force majeure” (e.g. labor actions, infrastructural
          breakdowns or blackouts etc.). We may also modify our prices to the
          extent allowed under the law. Srijcon is not a storage service. You
          agree that we have no obligation to store, keep showing, maintain or
          provide you a copy of any content or information that you or others
          provide, except to the extent required by applicable law.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">3.3 Content on Sites</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          We cannot and do not, in any way endorse or guarantee the accuracy of
          content posted on our Sites by us, others or third parties. You may
          encounter content that might be inaccurate, delayed, incomplete,
          misleading, offensive, illegal or otherwise harmful. We are not
          legally responsible for others’ (including other Members) content and
          such misuse of the Platform.
          <br />
          <br />
          Srijcon may help connect Members offering their products or services
          with Members seeking services. You acknowledge that Srijcon is not
          responsible for the performance, quality or procurement of these
          products or services and nothing shall create an employment, agency or
          joint venture relationship between Srijcon and any Member offering
          services. If you are a Member offering services, you warrant that you
          have all the required licenses for it. Also, We do not endorse
          clicking on links posted by other Users, as these links may pose risks
          to your computer or take you to inappropriate sites.
          <br />
          <br />
          Similarly, Srijcon may help you register for and/or attend events
          organized by Members and connect with other Members who are attendees
          at such events. You agree that Srijcon is not responsible for the
          conduct of any of the Members or other attendees at such events,
          Srijcon does not review and/or vet any of these events, and that you
          will adhere to these Terms and conditions that apply to such events.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">3.4 Limits</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          Srijcon reserves the right to restrict, suspend, or terminate your
          account if you breach this Agreement or the law or are misusing the
          Services (e.g., violating the Guidelines).
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">
          3.5 Intellectual Property Rights
        </h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          The trademarks, trade names, Service marks, word marks, illustrations,
          images, and logos (“Trademarks”) appearing on our Websites, including
          without limitation SRIJCON® and/or its distinctive logo. All other
          content on this Website (“Copyrights”), including all page headers,
          custom graphics, text, design, button icons, and scripts are
          copyrighted works of Srijcon Innovations Pvt. Ltd., and may not be
          copied, imitated or used, in whole or in part, without the prior
          written permission of Srijcon Innovations Pvt. Ltd. From time to time,
          the Website will legally utilize intellectual property owned by third
          parties related to our Services. The rights in any third party
          trademarks or copyrighted works on this Website are retained by their
          respective owners. All other trademarks displayed on the Site are the
          trademarks of their respective owners, and constitute neither an
          endorsement nor a recommendation of their products and services.
          Nothing in this Agreement shall confer any right of ownership of any
          of the Trademarks or Copyrights to you. Further, nothing in this
          Agreement shall be construed as granting, by implication or otherwise,
          any license or right to use any Trademark or Copyright without the
          express written permission of Srijcon Innovations Pvt. Ltd. The misuse
          of the Trademark or Copyrighted works displayed in this Site, or any
          other content on the Platform, is strictly prohibited and may expose
          you to liability.
        </p>
        <br />
        <h2 className={classes.termsAndConditionsH2Heading}>
          4. Disclaimer and Limit of Liability
        </h2>
        <br />
        <h3 className="termsAndConditionsH3Heading">4.1 No Warranty</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          SRIJCON AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT
          THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE
          UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING
          CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO
          THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, SRIJCON AND ITS
          AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY
          IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT,
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">
          4.2 Exclusion of Liability
        </h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS SRIJCON HAS ENTERED
          INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT),
          SRIJCON, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION
          WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES,
          REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA
          (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR
          CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR
          PUNITIVE DAMAGES.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">4.3 Exclusions</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          The limitations of liability between you and Srijcon and shall apply
          to all claims of liability (e.g., warranty, tort, negligence, contract
          and law) even if Srijcon or its affiliates has been told of the
          possibility of any such damage, and even if these remedies fail their
          essential purpose.
        </p>
        <br />
        <h2 className={classes.termsAndConditionsH2Heading}>
          5. Privacy Policy
        </h2>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          At Srijcon, your privacy is important to us. We collect and use your
          information only to provide and improve our platform. By using
          Srijcon, you agree to the collection and use of information in
          accordance with this policy.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">
          5.1 Information We Collect
        </h3>
        <br />
        <ol>
          <li className={classes.termsAndConditionsParagraph}>
            When you register, we may collect your name, email address, and
            other contact details.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            We collect the content you share including but not limited to media,
            discussions, user profile details to enhance your experience on our
            platform.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            We may collect data on how you use the platform, including
            interactions, preferences, and activity patterns.
          </li>
        </ol>
        <br />
        <h3 className="termsAndConditionsH3Heading">
          5.2 How We Use Your Information
        </h3>
        <br />
        <ol>
          <li className={classes.termsAndConditionsParagraph}>
            To personalize your experience and provide you with tailored
            content.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            To improve our platform, services, and user support.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            To communicate with you about updates, promotions, and news related
            to Srijcon.
          </li>
        </ol>
        <br />
        <h3 className="termsAndConditionsH3Heading">
          5.3 Sharing Your Information
        </h3>
        <br />
        <ol>
          <li className={classes.termsAndConditionsParagraph}>
            We do not sell your personal information. We may share your
            information with third-party service providers to operate our
            platform and services effectively.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            We may also disclose your information if required by law or in
            response to valid requests by public authorities.
          </li>
        </ol>
        <br />
        <h3 className="termsAndConditionsH3Heading">5.4 Data Security</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          We implement reasonable security measures to protect your data.
          However, no method of transmission over the internet or electronic
          storage is 100% secure.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">5.5 Your Rights:</h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          You can update or delete your personal information and Srijcon account
          at any time through your account settings. You can access your account
          settings through{' '}
          <a target="_blank" style={{ color: '#b6193a' }} href="/settings">
            https://www.srijcon.com/settings
          </a>
        </p>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          If you wish to request the deletion of your account and associated
          data, please contact us at{' '}
          <a href="mailto:contact@srijcon.com" style={{ color: '#b6193a' }}>
            contact@srijcon.com
          </a>
          . We will process your request in accordance with applicable laws.
        </p>
        <br />
        <h3 className="termsAndConditionsH3Heading">
          5.6 Changes to This Policy:
        </h3>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy.
        </p>
        <br />
        <h2 className={classes.termsAndConditionsH2Heading}>6. Monitoring</h2>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          Srijcon has no obligation to monitor the use of our Sites by Users.
          You acknowledge and agree that Srijcon reserves the right to, and may
          from time to time, monitor any and all information transmitted or
          received through our Sites for operational and other purposes. During
          monitoring, information may be examined, recorded, copied, and used
          for authorized purposes. Use of our Sites constitutes consent to such
          monitoring. Furthermore, Srijcon reserves the right at all times to
          disclose any information posted on any portion of our Sites as
          necessary to satisfy any law, regulation or governmental request, or
          to refuse to post, or to remove, any information or materials, in
          whole or in part, that in Srijcon’s sole and absolute discretion are
          objectionable or in violation of this Agreement.
        </p>
        <br />
        <h2 className={classes.termsAndConditionsH2Heading}>7. Termination</h2>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          Both you and Srijcon may terminate this Contract at any time
          with/without notice to the other. On termination, you lose the right
          to access or use the Services. Our rights to use and disclose your
          feedback shall survive termination. All the sections in this Agreement
          except those related to Platform use guidelines shall also survive
          termination. Any amounts owed by either party prior to termination
          remain owed after termination.
        </p>
        <br />
        <h2 className={classes.termsAndConditionsH2Heading}>
          8. Governing Law and Dispute Resolution
        </h2>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          This Agreement shall be construed and enforced in accordance with the
          laws of India. This Agreement is a personal agreement between you and
          Srijcon, and no one shall be a third party beneficiary to this
          Agreement. Although you acknowledge that Srijcon will have the ability
          to enforce its rights in any court of competent jurisdiction, you
          hereby acknowledge and agree that the courts of the Kanpur, Uttar
          Pradesh shall have exclusive jurisdiction over any dispute relating to
          these Terms and your use of our Sites, that you shall submit to that
          jurisdiction and those courts, and that you expressly waive any and
          all rights you may have, or that may hereafter arise, to contest the
          propriety of such choice of jurisdiction and venue.
        </p>
        <br />
        <h2 className={classes.termsAndConditionsH2Heading}>
          9. Miscellaneous
        </h2>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          You acknowledge and agree that you may not bring a claim against
          Srijcon relating to your use of our Sites after more than one year has
          passed since the event occurred that gave rise to the claim, while
          there is no such limitation on Srijcon’s right to bring claims against
          you. You acknowledge and agree that the disclaimers, including the
          warranty disclaimers, and the liability and remedy limitations in this
          Agreement are material terms of this Agreement and that they have been
          taken into account in the decision by Srijcon to provide our Sites
          hereunder. You waive your right to trial by jury of any or all issues
          arising in any action or proceeding between you, or your successors,
          and Srijcon or its successors, under or connected with this Agreement
          or any of its provision.
          <br />
          <br />
          If any provision of this Agreement shall be unlawful, void, or for any
          reason unenforceable, you and We agree that the court should modify
          the Terms to make that part enforceable while still achieving its
          intent, else that provision shall be deemed severable from this
          Agreement and shall not affect the validity and enforceability of any
          remaining provisions. This Agreement (including additional terms that
          may be provided by us when you engage with a feature of the Services)
          is the only agreement of the parties with respect to the subject
          matter hereof, and supersede all prior or contemporaneous
          communications and proposals, whether oral or written, between the
          parties with respect to such subject matter. No waiver of any
          provision or any right granted hereunder will be effective unless set
          forth in a written instrument signed by the waiving party. No waiver
          by either party of any breach or default hereunder shall be deemed a
          waiver of any subsequent breach or default. If we don't act to enforce
          a breach of this Contract, that does not mean that Srijcon has waived
          its right to enforce this Contract. You may not assign or transfer
          this Contract (or your membership or use of Services) to anyone
          without our consent. However, you agree that Srijcon may assign or
          transfer these Terms, in our sole discretion, to a third party in the
          event of a merger or sale of Company or its assets without your
          consent. The titles and subtitles used in this Agreement are used for
          convenience only and are not to be considered in construing or
          interpreting this Agreement. You agree that the only way to provide us
          legal notice is at the address provided in the section 10.
        </p>
        <br />
        <h2 className={classes.termsAndConditionsH2Heading}>
          10. Complaints Regarding Content
        </h2>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          We respect the intellectual property rights of others. We require that
          information posted by Members be accurate and not in violation of the
          intellectual property rights or other rights of third parties. Srijcon
          may remove content that appears in its sole discretion to infringe the
          intellectual property rights of others. Srijcon also has a policy of
          terminating the accounts of repeat infringers in appropriate
          circumstances. If you believe that content available on or through the
          Sites infringes one or more of your intellectual property rights,
          please immediately notify Srijcon (“Notification”) providing the
          information described below. A copy of your Notification will be sent
          to the person who posted or stored the material addressed in the
          Notification. Please be advised that you may be held liable for
          damages if you make material misrepresentations in a Notification.
          Thus, if you are not sure that content located on or linked to by the
          Sites infringes your rights, you should consider first contacting an
          attorney.
        </p>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          All Notifications should include the following:
        </p>
        <ul>
          <li className={classes.termsAndConditionsParagraph}>
            A physical or electronic signature of a person authorized to act on
            behalf of the owner of the intellectual property right that is
            allegedly infringed.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Identification of the copyrighted work claimed to have been
            infringed.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Identification of the material that you claim is infringing and that
            is to be removed or access to which is to be disabled, and
            information reasonably sufficient to permit Srijcon to locate the
            material, including the URL.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Your address, telephone number and email address.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            A statement made under penalty of perjury that the information in
            the Notification is accurate, and that you are authorized to act on
            behalf of the owner of the intellectual property right that is
            allegedly infringed.
          </li>
          <li className={classes.termsAndConditionsParagraph}>
            Notifications should be sent to Srijcon on the address mentioned in
            the section 10.
          </li>
        </ul>
        <br />
        <h2 className={classes.termsAndConditionsH2Heading}>10. Contact us</h2>
        <br />
        <p className={classes.termsAndConditionsParagraph}>
          For general inquiries, you may contact us via email at{' '}
          <u>contact@srijcon.com</u> and for legal notices or service of
          process, you may write us at the following address.
          <br />
          <br />
          To-
          <br />
          Srijcon Innovations Pvt. Ltd.
          <br />
          C-1/355, Indiranagar, Kanpur
          <br />
          Uttar Pradesh – 208026
          <br />
          India.
        </p>
      </div>
      {/* <br />
      <br /> */}
    </div>
  );
}
