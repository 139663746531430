import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// Modal
import Modal from '@mui/material/Modal';

//import icons
import { FiArrowLeft, FiArrowUpCircle, FiX } from 'react-icons/fi';
import { IoIosAddCircle } from 'react-icons/io';

//import css
import classes from './MediaModal.module.css';

//Carousel
import ThumbnailCarousel from './MediaModalComponents/ThumbnailCarousel/ThumbnailCarousel';

//getImageSize
import { getImageSize } from 'react-image-size';

// Error modals
import UnsupportedFileModal from '../../ErrorModals/UnsupportedFileModal/UnsupportedFileModal';
import MaxSelectFileErrorModal from '../../ErrorModals/MaxSelectFileErrorModal/MaxSelectFileErrorModal';
import CloseWarningModal from '../../ErrorModals/CloseWarningModal/CloseWarningModal';

// import components
import { getVideoThumbnail } from './MediaModalComponents/RenderThumbnail';
import Dropdown from './MediaModalComponents/Dropdown/Dropdown';
import EditFiles from './MediaModalComponents/EditFiles/EditFiles';

// redux reducers
import {
  handleMedia,
  handleModalType,
} from '../../../../../../../Store/uploadContentSlice';
import { useDispatch, useSelector } from 'react-redux';

// Loader
import CircularProgress from '@mui/material/CircularProgress';

// helper function
import { isValidImage } from '../../../../../../../HelperFunctions/checkValidity/isValidImage';

// Context
import UploadContentContext from '../../../../../../../Context/UploadContent/uploadContentContext';
import { isValidMedia } from '../../../../../../../HelperFunctions/checkValidity/isValidMedia';
// import { Grow } from '@mui/material';

const MediaModal = (props) => {
  const dispatch = useDispatch();
  const contentType = useSelector(
    (state) => state.uploadContentSlice.contentType
  );
  const uploadContentData = JSON.parse(
    JSON.stringify(useSelector((state) => state.uploadContentSlice))
  );
  const { videoBlobToFile, setVideoBlobToFile , attachments} =
    useContext(UploadContentContext);
  // Modal Open/Close States
  const [contentUploaded, setContentUploaded] = useState(false);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 650);
  const [unsupportedFile, setUnsupportedFile] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const [closeWarning, setCloseWarning] = useState(false);
  const [errorType, setErrorType] = useState(null);
  // All files
  const [thumbnail, setThumbnail] = useState({}); //json blob: blob
  const [mediaBlob, setMediaBlob] = useState([]); //blob for image and file for video
  const [mediaState, setMediaState] = useState({}); //json
  const [visitedMedia, setVisitedMedia] = useState([]); //blob for image and file for video
  const [extension, setExtension] = useState({}); // extensions for files
  const [initialize, setInitialize] = useState(false); // if video is uploaded
  const [initialVideo, setIntialVideo] = useState(mediaBlob[0]); //initial openedMedia
  const [videoBlobs, setVideoBlobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  // Cropper
  const [aspectRatio, setAspectRatio] = useState(2 / 3);
  const [openedMedia, setOpenedMedia] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropSize, setCropSize] = useState({ height: 300, width: 300 });

  // Zoom states
  const [minZoom, setMinZoom] = useState(1);
  const [maxZoom, setMaxZoom] = useState(3);
  const [zoom, setZoom] = useState(minZoom);
  const [refHeight, setRefHeight] = useState(1);

  const inputFileRef = useRef(null);

  useEffect(() => {
    try {
      setContentUploaded(true);
      setLoading(true);

      let promise = new Promise((resolve, reject) => {
        setTimeout(() => {
          if (uploadContentData !== undefined) {
            if (uploadContentData.mediaBlob.length > 0) {
              setMediaBlob(uploadContentData.mediaBlob);
              setMediaState(uploadContentData.mediaState);
              setAspectRatio(uploadContentData.aspectRatio);
              setExtension(uploadContentData.mediaExtension);
              setThumbnail(uploadContentData.thumbnail);
              setVisitedMedia(uploadContentData.visitedMedia);
              setVideoBlobs(uploadContentData.videoBlobs);
              setOpenedMedia(uploadContentData.openedMedia);
              setInitialize(uploadContentData.initialize);
              setIntialVideo(uploadContentData.initialVideo);

              const openedMediaStateSetting = new Promise((res, rej) => {
                // setTimeout(() => {
                // const tempOpenedMediaState = JSON.parse(JSON.stringify(uploadContentData.mediaState[uploadContentData.openedMedia]));
                const tempOpenedMediaState = uploadContentData.openedMediaState;
                setZoom(tempOpenedMediaState['zoom']);
                setRotation(tempOpenedMediaState['rotation']);
                setCrop(tempOpenedMediaState['crop']);
                setCroppedAreaPixels(tempOpenedMediaState['croppedAreaPixels']);
                res('SUCESS');
                // }, 100)
              });
              openedMediaStateSetting.then((response) => {
                setLoading(false);
              });
            } else {
              setLoading(false);
              setContentUploaded(false);
            }
            resolve('SUCCESS');
          }
        }, 100);
      });
    } catch (e) {
      //console.log('error in updating values using redux', e);
    }
  }, []);

  // small/large screens
  useEffect(() => {
    const handleWindowResize = () => {
      setSmallScreen(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  // setCropSize wrt to small/large screen
  useEffect(() => {
    if (smallScreen) {
      setCropSize({
        width: (window.innerWidth * 0.5) / aspectRatio,
        height: window.innerWidth * 0.5,
      });
    } else {
      setCropSize({
        width: (window.innerHeight*.32) / aspectRatio,
        height: (window.innerHeight*.32),
      });
    }
  }, [smallScreen, aspectRatio, setCropSize]);

  const handleDisplay = (media) => {
    try {

        setZoom(mediaState[media]['zoom']);
        setRotation(mediaState[media]['rotation']);
        setCrop(mediaState[media]['crop']);
        setOpenedMedia(media);
        setInitialize(false);
        setIntialVideo(null);

    } catch (e) {
      //console.log('error in handle display', e);
    }
  };

  // on uploading files
  const handleUpload = async (files) => {
    const allowedExtensionsVideo = /(\.mkv|\.mp4|\.mov)$/i; // regex for allowed video extensions

    if (files.length + mediaBlob.length > 10) {
      setLimitError(true);
      return;
    }

    let proceed = true;

    if (files) {
      Array.from(files).forEach((file) => {
        const validity = isValidMedia(file);
        if (validity !== null) {
          setUnsupportedFile(true);
          setErrorType(validity);
          proceed = false;
        }
      });
    }

    try {
      if (files && proceed) {
        setContentUploaded(true);
        let tempMediaBlob = [...mediaBlob];
        let tempMediaState = mediaState;
        let tempThumbnail = thumbnail;
        let tempExtension = extension;
        let tempVideoBlobs = videoBlobs;
        const len = tempMediaBlob.length;
        const uploaded = [];
        let blobToVideo = videoBlobToFile;

        files.some((file) => {
          const objectURL = URL.createObjectURL(file);
          uploaded.push(objectURL);
          const format = file.name.split('.').pop();
          tempExtension[objectURL] = format;
          tempMediaBlob.push(objectURL);

          if (allowedExtensionsVideo.exec(file.name)) {
            blobToVideo[objectURL] = file;
            tempVideoBlobs.push(objectURL);
          }
        });
        await Promise.all(
          uploaded.map(async (file) => {
            if (tempVideoBlobs.indexOf(file) === -1) {
              const mediaSize = await getImageSize(file);
              const tempZoom = Math.max(
                cropSize.width / (mediaSize.width * refHeight),
                cropSize.height / (mediaSize.height * refHeight)
              );
              tempMediaState[file] = {
                crop: { x: 0, y: 0 },
                zoom: tempZoom,
                rotation: 0,
                croppedAreaPixels: {
                  x: 0,
                  y: 0,
                  height: mediaSize.height,
                  width: mediaSize.width,
                },
              };
            } else {
              const videoImage = await getVideoThumbnail(blobToVideo[file], 0);
              tempThumbnail[file] = videoImage;
              tempMediaState[file] = {
                crop: { x: 0, y: 0 },
                zoom: 1,
                rotation: 0,
                croppedAreaPixels: { x: 0, y: 0, height: 0, width: 0 },
              };
            }
          })
        );
        delete tempMediaState[null];
        setVideoBlobToFile(blobToVideo); // context
        setMediaBlob(tempMediaBlob);
        setMediaState(tempMediaState);
        setThumbnail(tempThumbnail);
        setExtension(tempExtension);
        setVideoBlobs(tempVideoBlobs);
        if (openedMedia === null || len === 0) {
          setOpenedMedia(tempMediaBlob[0]);
        }

        if (len === 0 && allowedExtensionsVideo.exec(files[0].name)) {
          setInitialize(true);
          setIntialVideo(tempMediaBlob[0]);
          setOpenedMedia(tempMediaBlob[0]);
        }
      }
    } catch (e) {
      //console.log('error in uploading', e);
    }
  };

  const handleBack = () => {
    try {
      let tempProjectData = {
        mediaBlob: mediaBlob,
        mediaState: mediaState,
        openedMedia: openedMedia,
        initialize: initialize,
        initialVideo: initialVideo,
        aspectRatio: aspectRatio,
        visitedMedia: visitedMedia,
        mediaExtension: extension,
        videoBlobs: videoBlobs,
        thumbnail: thumbnail,
        openedMediaState: mediaState[openedMedia],
      };
      // //console.log(tempProjectData);
      dispatch(handleMedia(JSON.stringify(tempProjectData)));
      dispatch(handleModalType('title'));
    } catch (e) {
      //console.log('error in going back', e);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    try {
      let tempMediaState = mediaState;
      tempMediaState[openedMedia] = {
        crop: crop,
        zoom: zoom,
        rotation: rotation,
        croppedAreaPixels: croppedAreaPixels,
      };
      setMediaState(tempMediaState);
    } catch (e) {
      //console.log('error on changing zoom,...', e);
    }
  }, [zoom, rotation, crop, croppedAreaPixels, openedMedia, mediaState, setMediaState]);

  const aspectRatioList = [
    {
      value: 9 / 16,
      label: '16:9',
    },
    {
      value: 2 / 3,
      label: '3:2',
    },
    {
      value: 3 / 4,
      label: '4:3',
    },
  ];

  const handleDelete = (media) => {
    if (mediaBlob.length === 1) {
      setMediaState({});
      setMediaBlob([]);
      setVisitedMedia([]);
      setVideoBlobs([]);
      setThumbnail({});
      setOpenedMedia(null);
      setExtension({});
      setInitialize(false);
      setIntialVideo(null);
      setAspectRatio(2 / 3);
      setZoom(1);
      setRotation(0);
      setCroppedAreaPixels(null);
      setCrop({ x: 0, y: 0 });
      setContentUploaded(false);
    }
    else {
      try {
        let tempMediaState = mediaState;
        let index = mediaBlob.indexOf(media);
        let display = mediaBlob[Math.max(0, index - 1)];
        let tempExtension = extension;
        if (index === 0) {
          display = mediaBlob[1];
        }
        setMediaBlob((files) =>
          files.filter((file) => {
            return file !== media;
          })
        );
        delete tempMediaState[media];
        delete tempExtension[media];
        setMediaState(tempMediaState);
        setExtension(tempExtension);
        if (videoBlobs.indexOf(media) !== -1) {
          let tempThumbnail = thumbnail;
          delete tempThumbnail[media];
          setThumbnail(tempThumbnail);
          setVideoBlobs((files) =>
          files.filter((file) => {
            return file !== media;
          })
        );
        }

        if (openedMedia === media) {
          setZoom(mediaState[display]['zoom']);
          setRotation(mediaState[display]['rotation']);
          setCrop(mediaState[display]['crop']);
          setTimeout(() => {
            setOpenedMedia(display);
          }, 1);
        } else {
          setTimeout(() => {
            setOpenedMedia(openedMedia);
            handleDisplay(openedMedia);
          }, 0.1);
        }
      } catch (e) {
        //console.log('error in deleting', e);
      }
    }
  };

  const handleAspectRatio = async (newAspectRatio) => {
    setAspectRatio(newAspectRatio);
    let tempCropSize = {
      width: 230 / newAspectRatio,
      height: 230,
    };
    if (smallScreen) {
      tempCropSize = {
        width: (window.innerWidth * 0.5) / newAspectRatio,
        height: window.innerWidth * 0.5,
      };
    }

    try {
      if (videoBlobs.indexOf(openedMedia) === -1) {
        const mediaSize = await getImageSize(openedMedia);
        let tempZoom = Math.max(
          tempCropSize.width / (mediaSize.width * refHeight),
          tempCropSize.height / (mediaSize.height * refHeight)
        );
        setMinZoom(tempZoom);
        setMaxZoom(tempZoom + 4);
        setZoom(tempZoom);
      }

      let tempMediaState = {};
      Array.from(mediaBlob).forEach(async (file) => {
        if (videoBlobs.indexOf(file) === -1) {
          const mediaSize = await getImageSize(file);
          const tempZoom = Math.max(
            tempCropSize.width / (mediaSize.width * refHeight),
            tempCropSize.height / (mediaSize.height * refHeight)
          );
          tempMediaState[file] = {
            crop: { x: 0, y: 0 },
            zoom: tempZoom,
            rotation: mediaState[file]['rotation'],
            croppedAreaPixels: {
              x: 0,
              y: 0,
              height: mediaSize.height,
              width: mediaSize.width,
            },
          };
        } else {
          tempMediaState[file] = {
            crop: { x: 0, y: 0 },
            zoom: 1,
            rotation: 0,
            croppedAreaPixels: { x: 0, y: 0, height: 0, width: 0 },
          };
        }
      });
      setVisitedMedia([openedMedia]);
      setMediaState(tempMediaState);
    } catch (e) {
      //console.log('error on aspect ratio', e);
    }
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      let tempProjectData = {
        mediaBlob: mediaBlob,
        mediaState: mediaState,
        openedMedia: openedMedia,
        initialize: initialize,
        initialVideo: initialVideo,
        aspectRatio: aspectRatio,
        visitedMedia: visitedMedia,
        mediaExtension: extension,
        videoBlobs: videoBlobs,
        thumbnail: thumbnail,
        openedMediaState: mediaState[openedMedia],
      };

      dispatch(handleMedia(JSON.stringify(tempProjectData)));
      dispatch(handleModalType('details'));
      setLoading(false);
    } catch (e) {
      //console.log('error', e);
    }
  };

  const handleModalClose = () => {
    const deleteSafe = mediaBlob.length > 0 || attachments.length > 0;
    if (deleteSafe) {
      setCloseWarning(true);
    }
    else {
      props.onClose();
    }
  };

  return (
    <div>
      {limitError && (
        <MaxSelectFileErrorModal setMaxSelectFileErrorModal={setLimitError} />
      )}
      {unsupportedFile && (
        <UnsupportedFileModal
          setUnsupportedFileModal={setUnsupportedFile}
          errorType={errorType}
        />
      )}
      {closeWarning && (
        <CloseWarningModal
          setCloseWarning={setCloseWarning}
          onClose={props.onClose}
        />
      )}
      <Modal
        open={true}
        // onClose={() => handleModalClose()}
        className={classes.modal}
      >
          <div className={classes.wholeModal}>
            <div className={classes.modalHeading}>
              {contentType === 'PROJECT' ?(
                <FiArrowLeft
                  onClick={handleBack}
                  style={{ margin: 0 }}
                  className="cross-button"
                />
              ): <></>}
              <p className="component-heading">{contentType}</p>
              <FiX
                className="cross-button"
                style={{ margin: 0 }}
                onClick={() => handleModalClose()}
              />
            </div>

            {!contentUploaded ? (
              <div className={classes.insideUploadModal}>
                <div className={classes.UploadMediaContainer}>
                  <input
                    ref={inputFileRef}
                    hidden
                    multiple
                    accept=".jpg, .jpeg, .png, .webp, .mkv, .mp4, .mov"
                    id="image-upload"
                    type="file"
                    onChange={async (e) => {
                      setLoading(true);
                      const chosenFiles = Array.prototype.slice.call(
                        e.target.files
                      );
                      e.target.value = null;
                      await handleUpload(chosenFiles);
                      setLoading(false);
                    }}
                  />
                  <button onClick={() => inputFileRef.current.click()}>
                    <FiArrowUpCircle className={classes.uploadIcon} />
                    <h2 className={classes.descriptionText}>Upload Media</h2>
                  </button>
                </div>
              </div>
            ) : (
              <div className={classes.innerModal}>
                {loading ? (
                  <div className={classes.loader}>
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <div className={classes.insideEditModal}>
                      <div className={classes.aspectBox}>
                        <Dropdown
                          onChange={handleAspectRatio}
                          options={aspectRatioList}
                          chosen={aspectRatio}
                          disabled={videoBlobs.includes(openedMedia)}
                        />
                      </div>
                      <div className={classes.allThumbnail}>
                        {uploadLoader ? (
                          <div
                            className={`${classes.uploadBtn} ${classes.uploadBtnLoader}`}
                          >
                            <CircularProgress size="2rem" />
                          </div>
                        ) : (
                          <button
                            onClick={() => inputFileRef.current.click()}
                            className={
                              mediaBlob.length >= 10
                                ? `${classes.uploadBtnDisabled}`
                                : `${classes.uploadBtn}`
                            }
                            disabled={mediaBlob.length >= 10}
                          >
                            <IoIosAddCircle
                              className={
                                mediaBlob.length >= 10
                                  ? `${classes.uploadBtnSvgDisabled}`
                                  : `${classes.uploadBtnSvg}`
                              }
                            />
                          </button>
                        )}
                        <input
                          ref={inputFileRef}
                          hidden
                          multiple
                          accept=".jpg, .jpeg, .png, .webp, .mkv, .mp4, .mov"
                          id="image-upload"
                          type="file"
                          onChange={async (e) => {
                            const chosenFiles = Array.prototype.slice.call(
                              e.target.files
                            );
                            e.target.value = null;
                            setUploadLoader(true);
                            await handleUpload(chosenFiles);
                            setUploadLoader(false);
                          }}
                        />
                        <div className={classes.carousel}>
                          <ThumbnailCarousel
                            videoBlobs={videoBlobs}
                            mediaBlob={mediaBlob}
                            thumbnail={thumbnail}
                            handleDelete={handleDelete}
                            handleDisplay={handleDisplay}
                          />
                        </div>
                      </div>
                      <EditFiles
                        initialize={initialize}
                        initialVideo={initialVideo}
                        zoom={zoom}
                        setZoom={setZoom}
                        rotation={rotation}
                        setRotation={setRotation}
                        crop={crop}
                        setCrop={setCrop}
                        cropSize={cropSize}
                        maxZoom={maxZoom}
                        setMaxZoom={setMaxZoom}
                        minZoom={minZoom}
                        setMinZoom={setMinZoom}
                        refHeight={refHeight}
                        setRefHeight={setRefHeight}
                        openedMedia={openedMedia}
                        visitedMedia={visitedMedia}
                        setVisitedMedia={setVisitedMedia}
                        onCropComplete={onCropComplete}
                        videoBlobs={videoBlobs}
                      />
                    </div>
                    <div className={classes.nextButtonContainer}>
                      <button
                        className={loading | uploadLoader ? `${classes.nextButton} ${classes.btnSaveDisabled}` :`${classes.nextButton} ${classes.btnSave}`}
                        onClick={handleNext}
                        disabled={loading | uploadLoader}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
      </Modal>
    </div>
  );
};

export default MediaModal;
