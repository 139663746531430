import React, { useState } from 'react';

// TV Card header import
import TVCardHeader from '../TVCard/TVCardHeader/TVCardHeader';

// CSS import
import classes from './TVCard.module.css';

// Redux dispatch to dispatch user credentials
import Collapse from '@mui/material/Collapse';
import TVCardImage from './TVCardImage/TVCardImage';
import TVCardVideo from './TVCardVideo/TVCardVideo';
import TVCardDescription from './TVCardDescription/TVCardDescription';
import DiscussForm from './Discussion/DiscussForm/DiscussForm';
import DiscussionPanel from './Discussion/DiscussionPanel/DiscussionPanel';
import TVCardInteraction from './TVCardInteraction/TVCardInteraction';
import TVCardMediaCarousel from "./TVCardMedia/TVCardMediaCarousel";
import VisibilitySensor from 'react-visibility-sensor';

function TVCard({postData, postType, index, visiblePostIndex, setVisiblePostIndex=()=>null}) {
  // Destructuring postData props
  const {comment} = postData;

const allowedVideoExtensions = ['.mkv', '.mp4', '.mov'];
const {image_field: {images}, aspect_ratio} = postData;
const onVisibilityChange = (isVisible, index) => {
  isVisible && !document.fullscreenElement && setVisiblePostIndex(index);
};

  return (
    <div className={classes.tvCard}>
      <TVCardHeader postData={postData}/>
        <VisibilitySensor onChange={(isVisible) => onVisibilityChange(isVisible, index)} key={postData.id}>
      { postData.image_field.image_count <= 1 ?
       allowedVideoExtensions.some( extension => images[0].includes(extension)) ?
        <TVCardVideo src={images} aspectRatio={aspect_ratio} isVisible={index === visiblePostIndex}/>
        :
        <TVCardImage postData={postData}/>
      :
      <TVCardMediaCarousel postData={postData} isVisible={index === visiblePostIndex}/>
      }
        </VisibilitySensor>
      <TVCardInteraction postData={postData} postType={postType}/>
      <TVCardDescription postData={postData}/>
      <Collapse in={comment.isOpenDiscussPanel} mountOnEnter unmountOnExit>
        <DiscussionPanel postData={postData} postType={postType}/>
      </Collapse>
      <DiscussForm postType={postType} postData={postData}/>
    </div>
  );
}

export default TVCard;
