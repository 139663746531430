export const apiErrorCodes = Object.freeze({
  ACCOUNT_NOT_EXIST: 'account__not__exist',
  ACCOUNT_TYPE_SLT_google: 'Password reset is not permitted as your account is registered through Google.',
  ACCOUNT_TYPE_SLT_facebook: 'Password reset is not permitted as your account is registered through Facebook.',
  ACCOUNT_VERIFIED: 'account__verified',
  ACCOUNT_VERIFIED_GO_TO_SIGNIN: 'account__verified__go__to__signin',
  ANONYMOUS_USER: 'anonymous__user',
  CODE: 'code',
  CONTENT: 'content',
  CREATIVE_INTERESTS: 'creative_interests',
  ACCOUNT_ALREADY_VERIFIED: 'account__already__verified',
  CURRENT_NEW_PASSWORD_ARE_REQUIRED: 'current__new_password__required',
  CURRENT_NEW_PASSWORD_CANNOT_BE_SAME:
    'current__new_password__cannot__be__same',
  DISPOSABLE_EMAIL: 'disposable__email',
  EMAIL: 'email',
  EMAIL_ADDRESS: 'xxxx@xxx.com',
  EMAIL_AND_PASSWORD_ARE_REQUIRED: 'email__password__required',
  EMAIL_ERRORS:
    'account with this email already exists | This field is required | Invalid Email | Disposable Email',
  EMAIL_NOT_FOUND: 'email__not__found',
  EMAIL_IS_REQUIRED: 'email__field__required',
  EMAIL_OTP_REQUIRED: 'email__otp__required',
  EMAIL_SUCCESS: 'An Email has been sent to the entered email id',
  ERROR: 'error',
  FAILED_TO_SEND_EMAIL: 'email__send__failed',
  INVALID_EMAIL: 'invalid__email',
  INVALID_PASSWORD: 'invalid__password',
  INVALID_TOKEN: 'invalid__token',
  AUTHENTICATION_FAILED: 'authentication_failed',
  NOT_AUTHENTICATED: 'not_authenticated',
  LINK_EXPIRED: 'link__expired',
  LOGIN_SUCCESS: 'Login success',
  LOGOUT_FAILED: 'logout__failed',
  MESSAGE: 'message',
  NOT_FOUND: 'not_found',
  OTP_MISMATCHED: 'otp__mismatched',
  OTP_REQUIRED: 'otp__required',
  PAGE_NOT_FOUND: 'page__not__found',
  PASSWORD: 'password',
  PASSWORD_ERRORS:
    'This field is required | Must be (a-z), (0, 9) and minimum 8 characters',
  PASSWORD_IS_REQUIRED: 'password__required',
  PASSWORD_REQUIREMENT_FAILED: 'password__requirement__failed',
  PROFILE: 'true|false',
  POST_CATEGORY_REQUIRED: 'post__category__required',
  POST_TITLE_REQUIRED: 'post__title__required',
  QUERY_MISSING: 'query__missing',
  REASON_MISSING: 'reason__missing',
  SERIALIZATION_FAILED: 'serialization__failed',
  STATUS: 'status',
  SUCCESS: 'success',
  TIME: '2023-03-11T15:19:49.140281+05:30',
  TOKEN: '353d49aa882d5c97438ba9cc82f193f40b35cf40',
  TOKEN_NOT_FOUND: 'token__not__found',
  TOKEN_REQUIRED: 'token__required',
  TRUE_FALSE: 'true|false',
  USERNAME: 'username',
  USERNAME_ALREADY_TAKEN: 'username__already__taken',
  USERNAME_AND_PASSWORD_ARE_REQUIRED: 'username__password__required',
  USERNAME_IS_REQUIRED: 'username__required',
  USERNAME_NOT_FOUND: 'username__not__found',
  USER_CREATIVE_INTEREST_ALREADY_EXIST:
    'user__creative__interest__already__exist',
  USER_CREATIVE_INTEREST_NOT_FOUND: 'user__creative__interest__not__found',
  UNAUTHORIZED_USER: 'unauthorized__user',
  UNSUPPORTED_FILE: 'unsupported__file',
  VERIFY_ACCOUNT: 'verify__account',
  VERIFICATION_LINK_EXPIRED: 'The link is expired. Please signup again.',
  WRONG_CURRENT_PASSWORD: 'current__password__is__wrong',
  WRONG_OTP: 'wrong__otp',
});
