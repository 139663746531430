import React, { useCallback, useEffect, useRef, useState } from 'react';

import { countCharacters } from '../../HelperFunctions/countCharacters';
import UnsupportedFileModal from '../Navbar/NavbarRight/ContentTypeModal/UploadContentModal/ErrorModals/UnsupportedFileModal/UnsupportedFileModal';

// Modal
import Modal from '@mui/material/Modal';

// React Cropper
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import getCroppedImg from './cropImage';

//import cross icon
import { FiX } from 'react-icons/fi';

//import css
import classes from './ModalCropper.module.css';

// Material UI Slider
import { styled } from '@mui/material/styles';
import { isValidImage } from '../../HelperFunctions/checkValidity/isValidImage';

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-root': {
    padding: '10px 0px',
  },
  '& .MuiSlider-thumb': {
    color: 'red',
    width: '10px',
    height: '10px',
  },
  '& .MuiSlider-rail': {
    color: 'black',
    boxShadow: 'none',
    height: '0.15rem',
  },
  '& .MuiSlider-track': {
    color: 'black',
    height: '.15rem',
    boxShadow: 'none',
  },
}));

const ModalCropper = (props) => {
  const aspectRatio = props.aspect;
  const [extension, setExtension] = useState(props.extension);
  const [image, setImage] = useState(props.image);
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropSize, setCropSize] = useState({ height: 300, width: 300 });
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 650);
  // Zoom states
  const [minZoom, setMinZoom] = useState(1);
  const [maxZoom, setMaxZoom] = useState(3);
  const [zoom, setZoom] = useState(minZoom);

  // Error
  const [unsupportedFile, setUnsupportedFile] = useState(false);
  const [unsupportedFileModal, setUnsupportedFileModal] = useState(false);
  const [errorType, setErrorType] = useState(null);

  const inputFileRef = useRef(null);

  useEffect(() => {
    const handleWindowResize = () => {
      setSmallScreen(window.innerWidth <= 650);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if (smallScreen) {
      setCropSize({
        width: Math.min(
          window.innerWidth * 0.94,
          window.innerWidth * 0.47 * aspectRatio
        ),
        height: Math.min(
          (window.innerWidth * 0.94) / aspectRatio,
          window.innerWidth * 0.47
        ),
      });
    } else {
      setCropSize({
        width: Math.min(400 * aspectRatio, 800),
        height: Math.min(800 / aspectRatio, 400),
      });
    }
  }, [smallScreen, aspectRatio]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    event.target.value = null;
    const validity = isValidImage(file);
    if (validity === null) {
      const objectUrl = URL.createObjectURL(file);
      setImage(objectUrl);  
      setExtension(file.name.split('.').pop());
    }
    else {
      setUnsupportedFile(true);
      setErrorType(validity);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    try {
      setLoading(true);
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
        extension
      );
      fetch(croppedImage)
        .then((response) => response.blob())
        .then((blob) => {
          const filename = 'profile.jpg';
          const fileType = extension;
          const file = new File([blob], filename, { type: fileType });
          props.handleApply(croppedImage, file);
        });
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    
    <div>
      {unsupportedFileModal && (
        <UnsupportedFileModal
          setUnsupportedFileModal={setUnsupportedFileModal}
          errorType={errorType}
        />
      )}
      <Modal
        open={true}
        onClose={() => props.onClose()}
        className={classes.modal}
      >
        <div className={classes.wholeModal}>
          <div className={classes.modalHeading}>
            <p>Edit Image</p>
            <FiX
              className="cross-button"
              onClick={() => props.onClose()}
              style={{ marginTop: '0rem' }}
            />
          </div>
          <div className={classes.cropContainer}>
            <Cropper
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              onCropChange={setCrop}
              cropShape={props.shape}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              maxZoom={maxZoom}
              minZoom={minZoom}
              onMediaLoaded={(mediaSize) => {
                const tempZoom = Math.max(
                  cropSize.width / mediaSize.width,
                  cropSize.height / mediaSize.height
                );
                setZoom(tempZoom);
                setMinZoom(tempZoom);
                setMaxZoom(tempZoom + 4);
                setCrop({ x: 0, y: 0 });
              }}
              cropSize={cropSize}
            />
          </div>
          <div className={classes.sliderBox}>
            <div className={classes.sliders}>
              <p className={classes.typo}>Zoom</p>
              <StyledSlider
                value={zoom}
                min={minZoom}
                max={maxZoom}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div className={classes.sliders}>
              <p className={classes.typo}>Rotation</p>
              <StyledSlider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
          </div>
          <div className={classes.buttonBox}>
            <button
              style={{ backgroundColor: '#a3a3a3' }}
              className={
                loading ? `${classes.btnSaveDisabled}` : `${classes.btnSave}`
              }
              onClick={() => inputFileRef.current.click()}
              disabled={loading}
            >
              New Photo
            </button>
            <input
              ref={inputFileRef}
              hidden
              accept=".jpg, .jpeg, .png"
              id="image-upload"
              type="file"
              onChange={handleFileChange}
            />
            <button
              className={
                loading ? `${classes.btnSaveDisabled}` : `${classes.btnSave}`
              }
              // onClick={handleImageModalChange}
               onClick={handleCrop}
              disabled={loading}
            >
              {!loading ? 'Apply Changes' : 'Applying..'}
            </button>
          </div>
        </div>
      </Modal>

      </div>
  );
};

export default ModalCropper;
