import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classes from './EventCard.module.css';
import { closeEventPosts, openEventPost } from '../../Store/eventsSlice';
import { eventPostsGet, TVPagePostsGet } from '../../Store/TVPagePostsSlice';

export default function EventCard({ eventData }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (eventData.status === 'Live') {
      if (eventData.clicked) {
        dispatch(closeEventPosts(eventData.eventID));
        dispatch(TVPagePostsGet());
      } else {
        dispatch(openEventPost(eventData.eventID));
      }
    }
  };
  useEffect(() => {
    if (eventData.clicked && eventData.status === 'Live') {
      dispatch(eventPostsGet(eventData.eventID));
    }
  }, [eventData]);

  return (
    <div
      className={`${
        eventData.status === 'Live'
          ? classes.eventCardWrapperActive
          : classes.eventCardWrapper
      }`}
      onClick={handleClick}
      style={{
        opacity: `${
          eventData.clicked && eventData.status === 'Live' ? '0.75' : ''
        }`,
        cursor: `${eventData.status === 'Live' ? 'pointer' : 'not-allowed'}`,
      }}
    >
      <div className={classes.imgContainer}>
        <img
          draggable={false}
          style={{
            filter: `${eventData.status === 'Closed' ? 'blur(0.3rem)' : ''}`,
            opacity: `${
              eventData.clicked && eventData.status === 'Live' ? '0.75' : ''
            }`,
          }}
          src={eventData.eventImg}
          alt="event_img"
        />
      </div>
      <div className={classes.textContainer}>
        <div
          className={classes.liveText}
          style={{
            opacity: `${
              eventData.clicked && eventData.status === 'Live' ? '0.7' : ''
            }`,
          }}
        >
          {eventData.status === 'Live' ? (
            <div className={classes.dot}></div>
          ) : (
            ''
          )}
          <p
            style={{
              color: `${
                eventData.status === 'Live'
                  ? '#d11414'
                  : eventData.status === 'Closed'
                  ? '#a3a3a3'
                  : '#333'
              }`,
            }}
          >
            {eventData.status}
          </p>
        </div>
        <div
          className={classes.heroText}
          style={{
            opacity: `${
              eventData.clicked && eventData.status === 'Live' ? '0.7' : ''
            }`,
            boxShadow: `${
              eventData.clicked ? '' : ' 0 0.8rem 1.6rem rgba(0, 0, 0, 0.1)'
            }`,
            color: `${eventData.status === 'Closed' ? '#a3a3a3' : '#333'}`,
          }}
        >
          {eventData.eventFirstName} <br /> {eventData.eventLastName}
        </div>
      </div>
    </div>
  );
}
