import React, { useEffect, useState } from 'react';

// Stylesheet import
import classes from './TVPageMain.module.css';

// TV Card Component import
import TVCard from '../TVCard/TVCard';
import EventCard from '../../EventCard/EventCard';
import Grid from '@mui/material/Grid';
// import API from '../../../Axios/axios';
import { useDispatch, useSelector } from 'react-redux';
import { newPagePostsAdd } from '../../../Store/TVPagePostsSlice';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { apiErrorCodes } from '../../../apiErrorCodes';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';

function TVPageMain() {
  const dispatch = useDispatch();
  const { postsData } = useSelector((state) => state.TVPagePostsSlice);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visiblePostIndex, setVisiblePostIndex] = useState(0);
  const { eventsData } = useSelector((state) => state.eventsSlice);

  const getPostData = async () => {
    if (postsData?.urls?.next !== null && page > 1) {
      try {
        setLoading(true);
        const response = await axios.get(`${postsData.urls.next}`, {
          headers: { Authorization: `token ${localStorage.getItem('token')}` },
        });
        dispatch(newPagePostsAdd(response.data.message));
      } catch (error) {
        if (error.response.data.code === apiErrorCodes.NOT_FOUND) {
          setLastPage(true);
        } else if (
          error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
        ) {
          localStorage.removeItem('token');
        }
      }
      finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getPostData();
  }, [page]);

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handelInfiniteScroll);
    return () => window.removeEventListener('scroll', handelInfiniteScroll);
  }, []);
  return (
    <main className={classes.main}>
      <Grid container spacing={1} className={classes.eventCardSection}>
        {eventsData.map((option) => (
          <Grid item xs className={classes.eventCard} key={option.eventID}>
            <EventCard eventData={option} />
          </Grid>
        ))}
      </Grid>

      <TransitionGroup>
      {postsData?.results?.map((postData, index) => (
        <Collapse key={postData.id}>
            <TVCard postData={postData} index={index} setVisiblePostIndex={setVisiblePostIndex} visiblePostIndex={visiblePostIndex}/>
            <div style={{ height: '1rem' }}></div>
        </Collapse>
      ))}
      </TransitionGroup>

      <div className={classes.lastDiv}></div>
      {loading && postsData?.urls?.next !== null && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color={'primary'} />
        </div>
      )}
    </main>
  );
}

export default TVPageMain;
