import React, {useMemo} from 'react'

// import CSS 
import classes from './EditFiles.module.css'

// Image Cropper
import Cropper from 'react-easy-crop';

// Material UI SLider
import Slider from '@material-ui/core/Slider';
import { styled } from '@mui/material/styles';

const StyledSlider = styled(Slider)(({ theme }) => ({
    "& .MuiSlider-root": {
        padding: '10px 0px',
        height: '10px !important',
    },
    "& .MuiSlider-thumb": {
        color: "red",
        width: '10px',
        height: '10px',
    },
    "& .MuiSlider-rail": {
        color: "black",
        boxShadow: 'none',
        height: '.1rem !important',
    },
    "& .MuiSlider-track": {
        color : "black",
        height: '.1rem !important',
        boxShadow: 'none',
    },
}));

const VideoDisplay = ({video}) => {
    return (
        <>
            <div className={classes.cropContainer}>
                <video src={video} controls className={classes.videoBox}></video>
            </div>
            <div className={classes.sliderBoxDisabled} >
            <div className={classes.sliders}>
                <p className={classes.typo}>Zoom</p>
                <StyledSlider
                    value={1}
                    min={1}
                    max={4}
                    step={0.1}
                    aria-labelledby="Zoom"
                    className={classes.individualSlider}
                    disabled
                />
            </div>
            <div className={classes.sliders}>
                <p className={classes.typo}>Rotation</p>
                <StyledSlider
                    value={0}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    className={classes.individualSlider}
                    disabled                        
                />
            </div>
            </div>   
        </>
    )
}
const VideoDisplayMemo = React.memo(VideoDisplay);

const EditFilesSection = (props) => {
    const initialVideo = useMemo(() => (props.initialVideo));
    const openedMedia = useMemo(() => (props.openedMedia));   


  return (
      <div className={classes.editContainer}>
          
      {props.initialize ?
                <VideoDisplayMemo video={initialVideo} />
                :
                    <>
                    {
                        props.videoBlobs.indexOf(openedMedia) === -1 ?
                            
                    <>
                        <div className={classes.cropContainer}>
                            <Cropper
                                image={props.openedMedia}
                                crop={props.crop}
                                rotation={props.rotation}
                                zoom={props.zoom}
                                onCropChange={props.setCrop}
                                cropShape={'rect'}
                                onRotationChange={props.setRotation}
                                onCropComplete={props.onCropComplete}
                                onZoomChange={props.setZoom}
                                maxZoom={props.maxZoom}
                                minZoom={props.minZoom}
                                onMediaLoaded={(mediaSize) => {
                                    props.setRefHeight(mediaSize.height / mediaSize.naturalHeight);
                                    const tempZoom = Math.max(
                                        props.cropSize.width / mediaSize.width,
                                        props.cropSize.height / mediaSize.height
                                    );
                                    props.setMinZoom(tempZoom);
                                    props.setMaxZoom(tempZoom + 4);

                                    if (props.visitedMedia.indexOf(props.openedMedia) === -1) {
                                        props.setZoom(tempZoom); 
                                        props.setVisitedMedia(current => [...current, props.openedMedia]);
                                        props.setCrop({ x: 0, y: 0 });
                                    }
                                    
                                    else
                                        props.setZoom(Math.max(props.zoom, tempZoom));
                                }}
                                cropSize={props.cropSize}    
                                classes={{
                                    containerClassName: classes.outerCropContainer
                                }}                            
                            />
                        </div>
                        <div className={classes.sliderBox}>
                            <div className={classes.sliders}>
                                <p className={classes.typo}>Zoom</p>
                                <StyledSlider
                                    value={props.zoom}
                                    min={props.minZoom}
                                    max={props.maxZoom}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => props.setZoom(zoom)}
                                    className={classes.individualSlider}
                                />
                            </div>
                            <div className={classes.sliders}>
                                <p className={classes.typo}>Rotation</p>
                                <StyledSlider
                                    value={props.rotation}
                                    min={0}
                                    max={360}
                                    step={1}
                                    aria-labelledby="Rotation"
                                    onChange={(e, rotation) => props.setRotation(rotation)}
                                    className={classes.individualSlider}
                                />
                            </div>
                        </div>
                    </> :
                        <VideoDisplayMemo video={openedMedia} />
                  }
              </>
            }
    </div>
  )
}

export default EditFilesSection
