import React, {useState} from 'react';
import {Backdrop, Box, Fade, Modal} from '@mui/material';
import classes from './CopyLinkModal.module.css';

export default function CopyLinkModal(props) {
  const [open, setOpen] = useState(true);
  setTimeout(() => {
    handleClose();
  }, 1500);
  const handleClose = () => {
    props.setIsCopyLinkModal(false);
    setOpen(false);
  };

  return (<Modal
    open={open}
    onClose={handleClose}
    closeAfterTransition
    slots={{backdrop: Backdrop}}
    slotProps={{
      backdrop: {
        timeout: 500,
      },
    }}
  >
    <Fade in={open}>
      <div>
        <Box className={classes.copyLinkModal}>
          <p className={classes.copyLinkModalText}>{props.text}</p>
        </Box>
      </div>
    </Fade>
  </Modal>);
}
