import React, { useState, useRef, useEffect } from 'react';

import { useViewport } from '../../../../CustomHooks/useViewport';

// Stylesheet import
import classes from './TVCardDescription2.css';

// Icon import from Feather icon
import { MoreHorizontal, ChevronUp } from 'react-feather';

// Collapse transition import from Material UI
import { Collapse } from '@mui/material';

function TVCardDescription2(props) {
  // custom hook to get viewport width
  const { width } = useViewport();
  const [isDescription2Active, setDescription2Active] = useState(false);
  const [height, setHeight] = useState(0);
  const [num, setNum] = useState(null);
  const observedDiv = useRef(null);
  const handleElementResized = () => {
    if (observedDiv.current.offsetHeight !== height) {
      setHeight(observedDiv.current.offsetHeight);
    }
  };
  const resizeObserver = new ResizeObserver(handleElementResized);
  useEffect(() => {
    resizeObserver.observe(observedDiv.current);
   
    return function cleanup() {
      resizeObserver.disconnect();
    };
  });

  const activateDescription2 = () => {
    setDescription2Active(!isDescription2Active);
  };

  const deactivateDescription2 = () => {
    setDescription2Active(!isDescription2Active);
  };

  useEffect(() => {
    if (window.innerWidth <= 600 && window.innerWidth >= 541) {
      setNum(30);
    } else {
      setNum(26);
    }
  }, [window.innerWidth]);

  return (
    <div className={classes.description2Container}>
      <Collapse
        in={isDescription2Active}
        collapsedSize={width > 600 ? '2rem' : '5vw'}
      >
        <div>
          <p ref={observedDiv} className="description2text">
            {props.impact}
          </p>
        </div>
      </Collapse>

      {Math.ceil(height / num) > 1 ? (
        isDescription2Active ? (
          <ChevronUp
            style={{ cursor: 'pointer' }}
            className={`${classes.icon} ${classes.closeDescription2Icon}`}
            onClick={deactivateDescription2}
          />
        ) : (
          <MoreHorizontal
            style={{ cursor: 'pointer' }}
            className={classes.icon}
            onClick={activateDescription2}
          />
        )
      ) : null}
    </div>
  );
}

export default TVCardDescription2;
