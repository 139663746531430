import React, { useEffect, useState } from 'react';

// Stylesheet import
import classes from '../TVPage/TVPage.module.css';

// TV Page left, main and right component import
import TVPageLeft from '../TVPage/TVPageLeft/TVPageLeft';
import TVPageMain from '../TVPage/TVPageMain/TVPageMain';
import TVPageRight from '../TVPage/TVPageRight/TVPageRight';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import API from '../../Axios/axios';

function TVPage() {

  const navigate = useNavigate();
  const checkProfileStatus = async () => {
    try {
      const response = await API.get('/accounts/profile/status', {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      if (response.data.message.profile === false)
        navigate('/entry/userdetails');
      else if (response.data.message.creative_interests === false)
        navigate('/entry/creativeinterest');
    }
    catch {
      throw new Error('Error in validating profile');
    }
  };

  useEffect(() => {
    checkProfileStatus();
  }, []);

  return (
    <div className={classes.tvPage}>
      <Helmet>
        <title>Srijcon</title>
        <link rel="canonical" href="/" />
        <meta
          name="description"
          // content="Srijcon - Connect with innovators, inventors, makers, creators and share your projects, innovations, creative works or STEM creations with the world."
          content="Srijcon connects creative and Innovative minds to come together and share inspiring creations and ideas to impact the world."
        />
      </Helmet>
          <TVPageLeft />
          <TVPageMain />
          <TVPageRight />
    </div>
  );
}

export default TVPage;
