import React, {useContext, useEffect, useState} from 'react';
// Modal
import Modal from '@mui/material/Modal';
import {Box} from '@mui/material';
//import icons
import {FiX} from 'react-icons/fi';

//import css
import classes from './TitleModal.module.css';

// Error modals
import CloseWarningModal from '../../ErrorModals/CloseWarningModal/CloseWarningModal';

// redux reducers
import {handleModalType, handleTitle,} from '../../../../../../../Store/uploadContentSlice';
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@mui/material/TextField';
// import {createFilterOptions} from '@mui/material/Autocomplete';
import UploadContentContext from '../../../../../../../Context/UploadContent/uploadContentContext';
import styled from "styled-components";
import {useForm} from "react-hook-form";

// const filter = createFilterOptions();
const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;
const TitleModal = (props) => {
    const dispatch = useDispatch();
    const uploadContentData = useSelector((state) => state.uploadContentSlice);
    const {attachments} = useContext(UploadContentContext);
    // Modal Open/Close States
    const [closeWarning, setCloseWarning] = useState(false);
    // const [project, setProject] = useState({title: '', id:''});
    
    const [project, setProject] = useState('');
    // boolean value to disable and enable buttons
    // const [buttonEnabled, setButtonEnabled] = useState(true);
    // react-hook-form stuff
    const {
        // register,
        handleSubmit,
        // setError,
        formState: {errors},
    } = useForm({mode: 'onChange'});
    
    useEffect(() => {
        setProject(uploadContentData.project);
    }, []);

    const handleModalClose = () => {
        const deleteSafe = uploadContentData.mediaBlob.length > 0 || attachments.length > 0;
        if (deleteSafe) {
            setCloseWarning(true);
        } else {
            props.onClose();
        }
    }
    const handleChange = (e) => {
        setProject(e.target.value);
    }
    const onSubmit = () => {
        dispatch(handleModalType('media'));
        dispatch(handleTitle(project));
        // if (typeof project === 'string') {
        //     let tempProject = {title: project, id: Math.random().toString()};
        //     dispatch(handleTitle(JSON.stringify(tempProject)));
        // } else {
        //     dispatch(handleTitle(JSON.stringify(project)));
        // }
    };
    // useEffect(() => {
    //     if (project === null) {
    //         setDisableNext(true);
    //     } else {
    //         if (typeof project === 'string') {
    //             if (project.length === 0) {
    //                 setDisableNext(true);
    //             } else {
    //                 setDisableNext(false);
    //             }
    //         } else if (project.title.length === 0) {
    //             setDisableNext(true);
    //         } else {
    //             setDisableNext(false);
    //         }
    //     }
    // }, [project, setDisableNext]);
    return (
        <div>
            {closeWarning && (
                <CloseWarningModal
                    setCloseWarning={setCloseWarning}
                    onClose={props.onClose}
                />
            )}

            <Modal open={true}>
                <div>
                    <Box className={classes.editPasswordModal}>
                        <div className={classes.header}>
                            <div></div>
                            <h4 className="component-heading" style={{margin: 0}}>Project Title</h4>
                            <FiX className='cross-button' style={{margin: 0}} onClick={handleModalClose}/>
                        </div>
                        <Box className={classes.box}>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                autoComplete="off"
                                className={classes.formEditPassword}
                            >
                                <StyledTextField
                                    label="Project Title"
                                    variant="outlined"
                                    value={project}
                                    sx={{width: '80%', margin: '1rem 0'}}
                                    InputLabelProps={{
                                        style: {fontSize: 14, color: '#6e6e6e'},
                                    }}
                                    InputProps={{
                                        style: {fontSize: 14, fontWeight: 400},
                                    }}
                                    inputProps={{ maxLength: 50 }}
                                    // {...register('projectTitle', {
                                    //     required: 'Required field',
                                    // })}
                                    helperText={
                                        errors?.projectTitle ? errors.projectTitle.message : null
                                    }
                                    onChange={handleChange}
                                    
                                />
                                <Box className={classes.buttonUpdate}>
                                    <button
                                        type="submit"
                                        className={project.length === 0 ? classes.updateButtonDisabled : classes.updateButtonEnabled}
                                        disabled={project.length === 0}
                                    >
                                        Next
                                    </button>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </div>
            </Modal>


            {/*<div className='container'>*/}
            {/*    <Modal open={true} className={classes.modal}>*/}
            {/*        <Grow in={true}>*/}
            {/*            <div className={classes.wholeModal}>*/}
            {/*                <div className={classes.modalHeading}>*/}
            {/*                    <div></div>*/}
            {/*                    <p className="component-heading">{contentType}</p>*/}
            {/*                    <FiX*/}
            {/*                        className="cross-button"*/}
            {/*                        style={{margin: 0}}*/}
            {/*                        onClick={handleModalClose}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div className={classes.innerModal}>*/}
            {/*                    <div className={classes.insideEditModal}>*/}

            {/*                        <Autocomplete*/}
            {/*                            classes={{option: classes.dropdownOption}}*/}
            {/*                            value={*/}
            {/*                                typeof project === 'string'*/}
            {/*                                    ? project*/}
            {/*                                    : `${project === null ? '' : project.title}`*/}
            {/*                            }*/}
            {/*                            onChange={(event, newValue) => {*/}
            {/*                                if (typeof newValue === 'string') {*/}
            {/*                                    setProject(newValue);*/}
            {/*                                } else if (newValue && newValue.inputValue) {*/}
            {/*                                    // Create a new value from the user input*/}
            {/*                                    setProject(newValue.inputValue);*/}
            {/*                                } else {*/}
            {/*                                    setProject(newValue);*/}
            {/*                                }*/}
            {/*                            }}*/}
            {/*                            filterOptions={(options, params) => {*/}
            {/*                                const filtered = filter(options, params);*/}

            {/*                                const {inputValue} = params;*/}
            {/*                                // Suggest the creation of a new value*/}
            {/*                                // const isExisting = options.some((option) => inputValue === option.title);*/}
            {/*                                // if (inputValue !== '' && !isExisting) {*/}
            {/*                                if (inputValue.length > 0) {*/}
            {/*                                    let temp = [*/}
            {/*                                        {*/}
            {/*                                            inputValue,*/}
            {/*                                            title: `Create "${inputValue}"`,*/}
            {/*                                            id: Math.random().toString,*/}
            {/*                                        },*/}
            {/*                                        ...filtered,*/}
            {/*                                    ];*/}
            {/*                                    return temp;*/}
            {/*                                }*/}
            {/*                                return filtered;*/}

            {/*                                // filtered.push({*/}
            {/*                                //     inputValue,*/}
            {/*                                //     title: `Create "${inputValue}"`,*/}
            {/*                                // });*/}
            {/*                                // }*/}
            {/*                            }}*/}
            {/*                            id=""*/}
            {/*                            options={projects}*/}
            {/*                            getOptionLabel={(option) => {*/}
            {/*                                // Value selected with enter, right from the input*/}
            {/*                                if (typeof option === 'string') {*/}
            {/*                                    return option;*/}
            {/*                                }*/}
            {/*                                // Add "xxx" option created dynamically*/}
            {/*                                if (option.inputValue) {*/}
            {/*                                    return option.inputValue;*/}
            {/*                                }*/}
            {/*                                // Regular option*/}
            {/*                                return option.title;*/}
            {/*                            }}*/}
            {/*                            renderOption={(props, option) => (*/}
            {/*                                <li {...props}>{option.title}</li>*/}
            {/*                            )}*/}
            {/*                            sx={{width: '100%'}}*/}
            {/*                            freeSolo*/}
            {/*                            renderInput={(params) => (*/}
            {/*                                <TextField*/}
            {/*                                    {...params}*/}
            {/*                                    placeholder="Search or create a new title"*/}
            {/*                                    color="darkGrey"*/}
            {/*                                    sx={{*/}
            {/*                                        '& legend': {display: 'none'},*/}
            {/*                                        '& fieldset': {top: 0},*/}
            {/*                                        width: '100%',*/}
            {/*                                        padding: '1rem 0',*/}
            {/*                                        display: 'flex',*/}
            {/*                                    }}*/}
            {/*                                    InputLabelProps={{*/}
            {/*                                        style: {*/}
            {/*                                            fontSize: '1.4rem',*/}
            {/*                                            color: '#6e6e6e',*/}
            {/*                                            padding: '0 0rem',*/}
            {/*                                        },*/}
            {/*                                    }}*/}
            {/*                                    InputProps={{*/}
            {/*                                        ...params.InputProps,*/}
            {/*                                        style: {*/}
            {/*                                            fontSize: '1.4rem',*/}
            {/*                                            fontWeight: 400,*/}
            {/*                                            padding: '0.5rem 1.4rem',*/}
            {/*                                        },*/}
            {/*                                    }}*/}
            {/*                                />*/}
            {/*                            )}*/}
            {/*                        />*/}
            {/*                    </div>*/}
            {/*                    <div className={classes.nextButtonContainer}>*/}
            {/*                        <button*/}
            {/*                            className={*/}
            {/*                                disableNext*/}
            {/*                                    ? `${classes.nextButton} ${classes.btnSaveDisabled}`*/}
            {/*                                    : `${classes.nextButton} ${classes.btnSave}`*/}
            {/*                            }*/}
            {/*                            onClick={handleNext}*/}
            {/*                            disabled={disableNext}*/}
            {/*                        >*/}
            {/*                            Next*/}
            {/*                        </button>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Grow>*/}
            {/*    </Modal>*/}
            {/*</div>*/}
        </div>
    );
};

export default TitleModal;

const projects = [
    {title: 'The Shawshank Redemption', id: 1994},
    {title: 'The Godfather', id: 1972},
    {title: 'The Godfather: Part II', id: 1974},
    {title: 'The Dark Knight', id: 2008},
    {title: '12 Angry Men', id: 1957},
    {title: "Schindler's List", id: 1993},
    {title: 'Pulp Fiction', id: 1994},
    {
        title: 'The Lord of the Rings: The Return of the King',
        id: 2003,
    },
    {title: 'The Good, the Bad and the Ugly', id: 1966},
    {title: 'Fight Club', id: 1999},
    {
        title: 'The Lord of the Rings: The Fellowship of the Ring',
        id: 2001,
    },
    {
        title: 'Star Wars: Episode V - The Empire Strikes Back',
        id: 1980,
    },
    {title: 'Forrest Gump', id: 1994},
    {title: 'Inception', id: 2010},
    {
        title: 'The Lord of the Rings: The Two Towers',
        id: 2002,
    },
    {title: "One Flew Over the Cuckoo's Nest", id: 1975},
    {title: 'Goodfellas', id: 1990},
    {title: 'The Matrix', id: 1999},
    {title: 'Seven Samurai', id: 1954},
    {
        title: 'Star Wars: Episode IV - A New Hope',
        id: 1977,
    },
    {title: 'City of God', id: 2002},
    {title: 'Se7en', id: 1995},
    {title: 'The Silence of the Lambs', id: 1991},
    {title: "It's a Wonderful Life", id: 1946},
    {title: 'Life Is Beautiful', id: 1997},
    {title: 'The Usual Suspects', id: 1995},
    {title: 'Léon: The Professional', id: 1994},
    {title: 'Spirited Away', id: 2001},
    {title: 'Saving Private Ryan', id: 1998},
    {title: 'Once Upon a Time in the West', id: 1968},
    {title: 'American History X', id: 1998},
    {title: 'Interstellar', id: 2014},
    {title: 'Casablanca', id: 1942},
    {title: 'City Lights', id: 1931},
    {title: 'Psycho', id: 1960},
    {title: 'The Green Mile', id: 1999},
    {title: 'The Intouchables', id: 2011},
    {title: 'Modern Times', id: 1936},
    {title: 'Raiders of the Lost Ark', id: 1981},
    {title: 'Rear Window', id: 1954},
    {title: 'The Pianist', id: 2002},
    {title: 'The Departed', id: 2006},
    {title: 'Terminator 2: Judgment Day', id: 1991},
    {title: 'Back to the Future', id: 1985},
    {title: 'Whiplash', id: 2014},
    {title: 'Gladiator', id: 2000},
    {title: 'Memento', id: 2000},
    {title: 'The Prestige', id: 2006},
    {title: 'The Lion King', id: 1994},
    {title: 'Apocalypse Now', id: 1979},
    {title: 'Alien', id: 1979},
    {title: 'Sunset Bouleletd', id: 1950},
    {
        title:
            'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
        id: 1964,
    },
    {title: 'The Great Dictator', id: 1940},
    {title: 'Cinema Paradiso', id: 1988},
    {title: 'The Lives of Others', id: 2006},
    {title: 'Grave of the Fireflies', id: 1988},
    {title: 'Paths of Glory', id: 1957},
    {title: 'Django Unchained', id: 2012},
    {title: 'The Shining', id: 1980},
    {title: 'WALL·E', id: 2008},
    {title: 'American Beauty', id: 1999},
    {title: 'The Dark Knight Rises', id: 2012},
    {title: 'Princess Mononoke', id: 1997},
    {title: 'Aliens', id: 1986},
    {title: 'Oldboy', id: 2003},
    {title: 'Once Upon a Time in America', id: 1984},
    {title: 'Witness for the Prosecution', id: 1957},
    {title: 'Das Boot', id: 1981},
    {title: 'Citizen Kane', id: 1941},
    {title: 'North by Northwest', id: 1959},
    {title: 'Vertigo', id: 1958},
    {
        title: 'Star Wars: Episode VI - Return of the Jedi',
        id: 1983,
    },
    {title: 'Reservoir Dogs', id: 1992},
    {title: 'Braveheart', id: 1995},
    {title: 'M', id: 1931},
    {title: 'Requiem for a Dream', id: 2000},
    {title: 'Amélie', id: 2001},
    {title: 'A Clockwork Orange', id: 1971},
    {title: 'Like Stars on Earth', id: 2007},
    {title: 'Taxi Driver', id: 1976},
    {title: 'Lawrence of Arabia', id: 1962},
    {title: 'Double Indemnity', id: 1944},
    {
        title: 'Eternal Sunshine of the Spotless Mind',
        id: 2004,
    },
    {title: 'Amadeus', id: 1984},
    {title: 'To Kill a Mockingbird', id: 1962},
    {title: 'Toy Story 3', id: 2010},
    {title: 'Logan', id: 2017},
    {title: 'Full Metal Jacket', id: 1987},
    {title: 'Dangal', id: 2016},
    {title: 'The Sting', id: 1973},
    {title: '2001: A Space Odyssey', id: 1968},
    {title: "Singin' in the Rain", id: 1952},
    {title: 'Toy Story', id: 1995},
    {title: 'Bicycle Thieves', id: 1948},
    {title: 'The Kid', id: 1921},
    {title: 'Inglourious Basterds', id: 2009},
    {title: 'Snatch', id: 2000},
    {title: '3 Idiots', id: 2009},
    {title: 'Monty Python and the Holy Grail', id: 1975},
];
