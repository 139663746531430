const shortenFileName = (filename, maxLength) => {
  // Get the file extension
  const lastDotIndex = filename.lastIndexOf('.');
  const hasExtension = lastDotIndex !== -1;
  const ext = hasExtension ? filename.substring(lastDotIndex + 1) : '';

  // Get the file name without the extension
  let name = hasExtension ? filename.substring(0, lastDotIndex) : filename;

  // Shorten the file name if it is longer than maxLength characters
  if (name.length > maxLength) {
    const truncatedName = name.substring(0, maxLength - (hasExtension ? 6 : 5)); // Account for '...x.ext' or '...x'
    const lastChar = hasExtension ? name.charAt(name.length - 1) : '';
    name = `${truncatedName}...${lastChar}`;
  }

  // Return the shortened file name with the extension (if present)
  return hasExtension ? `${name}.${ext}` : name;
};

export default shortenFileName;