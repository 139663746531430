import React, { useState } from 'react';

// Stylesheet import
import classes from './Dropdown.module.css';

// Icons imported from Feather icon
import { Flag, Trash } from 'react-feather';

// Grow animation from Material UI
import { Grow } from '@mui/material';
import { useDispatch } from 'react-redux';

// API base URL
import API from '../../../../Axios/axios';

// reducer to delete the selected discussion from store
import { removeDiscussion } from '../../../../Store/TVPagePostsSlice';
import { singlePageRemoveDiscussion } from '../../../../Store/singlePostSlice';

function Dropdown({ data, isAuthor, setDropdownActive, isFlagged, postType }) {
  const dispatch = useDispatch();
  const [flagged, setFlagged] = useState(isFlagged);

  const deleteDiscussion = async () => {
    try {
      const response = await API.delete(`${data.urls.delete}`, {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      if (response.data.message) {
        setDropdownActive(false);
        if (postType === 'single')
          dispatch(singlePageRemoveDiscussion(response.data));
        else dispatch(removeDiscussion(response.data));
      }
    } catch (e) {
      if (e.response.data.message === 'Invalid token.') {
        localStorage.removeItem('token');
      }
    }
  };
  const flagDiscussion = async () => {
    try {
      const response = await API.post(
        `${data.urls.flag}`,
        {},
        {
          headers: { Authorization: `token ${localStorage.getItem('token')}` },
        }
      );
      if (response.data.message) {
        setFlagged(true);
      }
    } catch (e) {
      if (e.response.data.message === 'Invalid token.') {
        localStorage.removeItem('token');
      }
    }
  };

  return (
    <Grow in={true}>
      <div className={classes.dropdown}>
        {isAuthor ? (
          <div className={classes.element} onClick={deleteDiscussion}>
            <Trash className={classes.icon} />
            <h4 className="element-heading">Delete</h4>
          </div>
        ) : (
          <div className={classes.element} onClick={flagDiscussion}>
            {flagged ? (
              <Flag className={classes.flaggedIcon} />
            ) : (
              <Flag className={classes.icon} />
            )}
            <h4 className="element-heading">{flagged ? 'Flagged' : 'Flag'}</h4>
          </div>
        )}
      </div>
    </Grow>
  );
}

export default Dropdown;
