import { IoIosBrowsers } from "react-icons/io";
import { HiPlay } from "react-icons/hi2";

import classes from './UserProfile.module.css';

const allowedVideoExtensions = ['.mkv', '.mp4', '.mov'];

export const Thumbnail = ({
    alt,
    src,
    multiple,
}) => {
    const isVideo = allowedVideoExtensions.some(extension => src.includes(extension));
    return (
        <>
            {isVideo
                ?
                <video
                    src={src}
                    alt={alt}
                    style={{ width: '100%', height: '100%', borderRadius: '0.5rem', objectFit: 'cover', backgroundColor: 'gainsboro'}}
                />
                :
                <img
                    src={src}
                    alt={alt}
                    style={{ width: '100%', height: '100%', borderRadius: '0.5rem', objectFit: 'cover' }}
                    draggable="false"
                />
            }
            {multiple ?
                <IoIosBrowsers className={classes.multipleIcon} />
                : isVideo ?
                    <HiPlay className={classes.videoIcon} />
                    :
                    null
            }
        </>
    )
}