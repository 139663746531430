import React, { useEffect, useState } from 'react';
// Stylesheet import
import classes from '../FansAndFavourites/FansAndFavourites.module.css';

// React icons
import { FiUserX, FiX } from 'react-icons/fi';

// tooltip imported
import MyToolTip from '../../CommonComponents/MyToolTip';

// Material UI Stuff imported
import { Avatar, Box, Collapse, Grow, Modal, Slide } from '@mui/material';

// Transition group component imported for delete the user
import { TransitionGroup } from 'react-transition-group';

// API Base URL imported
import API from '../../../Axios/axios';

// use-navigate imported
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// To update fans count in user profile
import { updateFansCount } from '../../../Store/userProfileSlice';
import { apiErrorCodes } from '../../../apiErrorCodes';

export default function Fans(props) {
  const [open, setOpen] = useState(false);
  const [fans, setFans] = useState('');
  const [status, setStatus] = useState('loading');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userProfileData } = useSelector((state) => state.userProfileSlice);

  // Function to redirect to the profile of creator
  const creatorNameClickHandler = (profileId) => {
    navigate(`/accounts/${profileId}/profile/`);
  };

  // Slider function that will show yes no buttons
  const confirmationHandler = (id) => {
    const result = fans.map((option) => {
      option.user.status = option.user.id !== id;
      return option;
    });
    setFans(result);
  };

  // This will remove the user from the users fan list
  const yesButtonHandler = async (id, url) => {
    try {
      const response = await API.post(
        `${url}`,
        {},
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      setFans(fans.filter((option) => option.user.id !== id));
      dispatch(updateFansCount(response.data.message.total_fans));
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST ||
        error.response.data.code === apiErrorCodes.USERNAME_NOT_FOUND
      ) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };

  // This will close the confirm dialog
  const noButtonHandler = (id) => {
    const result = fans.map((option) => {
      if (option.user.id === id) {
        option.user.status = true;
      } else {
        option.user.status = true;
      }
      return option;
    });
    setFans(result);
  };

  useEffect(() => {
    // To get the list of fans
    (async () => {
      try {
        const response = await API.get(
          `accounts/creator/${userProfileData.id}/fans_list/`,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        if (response.data.code === apiErrorCodes.SUCCESS) {
          setFans(response.data.message);
          setStatus('Done');
          if (response.data.message.length > 0) {
            // This will only open the modal if the user have at least one fan
            setOpen(true);
          }
        }
      } catch (error) {
        if (
          error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    })();
  }, []);

  useEffect(() => {
    // This will close the modal if the user does not have any fans
    if (fans.length < 1) {
      setOpen(false);
    }
  }, [fans]);

  if (status === 'loading') {
    return '';
  }

  return (
    <>
      <Modal open={open} onClose={props.handleSubmit}>
        <div>
          <Grow in={true}>
            <Box className={classes.favouritesModal}>
              <Box className={classes.favouritesModalHeader}>
                <FiX className="cross-button" onClick={props.handleSubmit} />
                <h4 className={classes.favouritesModalHeading}>FANS</h4>
              </Box>

              <Box className={classes.favouritesModalBody}>
                <TransitionGroup>
                  {fans?.map((element) => (
                    <Collapse key={element.user.id}>
                      <div className={classes.element} key={element.id}>
                        <Avatar
                          src={element.user.profile_pic}
                          className={classes.avatar}
                          onClick={() =>
                            creatorNameClickHandler(element.user.id)
                          }
                          alt="avatar_img"
                        />
                        <div className={classes.description}>
                          <h4
                            className={`element-heading ${classes.profileName}`}
                            onClick={() =>
                              creatorNameClickHandler(element.user.id)
                            }
                          >
                            {element.user.name}
                          </h4>
                          <p className="element-description">
                            {element.user.top_interest}
                          </p>
                        </div>
                        {element.user.status ? (
                          <MyToolTip title="Remove" placement="left">
                            <div>
                              <FiUserX
                                onClick={() =>
                                  confirmationHandler(
                                    element.user.id,
                                    element.user.remove_url
                                  )
                                }
                                className={classes.follow}
                              />
                            </div>
                          </MyToolTip>
                        ) : (
                          <>
                            <Slide
                              direction="left"
                              in={true}
                              mountOnEnter
                              unmountOnExit
                            >
                              <div className={classes.confirmationButtonDiv}>
                                <p
                                  className={classes.yesButton}
                                  onClick={() =>
                                    yesButtonHandler(
                                      element.user.id,
                                      element.user.remove_url
                                    )
                                  }
                                >
                                  Yes
                                </p>
                                <p
                                  className={classes.noButton}
                                  onClick={() =>
                                    noButtonHandler(element.user.id)
                                  }
                                >
                                  No
                                </p>
                              </div>
                            </Slide>
                          </>
                        )}
                      </div>
                    </Collapse>
                  ))}
                </TransitionGroup>
              </Box>
            </Box>
          </Grow>
        </div>
      </Modal>
    </>
  );
}
