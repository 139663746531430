import { countCharacters } from "../countCharacters";

export const isValidMedia = (mediaFile) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp|\.mkv|\.mp4|\.mov)$/i; // regex for allowed extensions
    // For single image upload
    if (!allowedExtensions.exec(mediaFile.name)) {
      return 'UNSUPPORTED_FILE_TYPE';
    }
    else {
      if (countCharacters(mediaFile.name) > 1000) {
        return 'UNSUPPORTED_FILE_NAME';
      }
      else {
          return null;  
      }
    }
}