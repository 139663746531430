import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../Axios/axios';
import { apiErrorCodes } from '../apiErrorCodes';

export const FEATURED_CREATOR_GET_STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});
export const featuredCreatorSlice = createSlice({
  name: 'featuredCreator',
  initialState: {
    featuredCreator: [],
    featuredCreatorGetStatus: FEATURED_CREATOR_GET_STATUSES.IDLE,
  },
  reducers: {
    // Reducer to add that creator to your favourites list
    addToFavourites: (state, action) => {
      state.featuredCreator.map((option) => {
        if (option.user.id === action.payload) {
          option.user.status = true;
        }
      });
      // This code will work when you want to remove the card of that particular creator whom you added to your favourites
      // state.featuredCreator = state.featuredCreator.filter(option => option.user.id !== action.payload)
    },
    // Reducer to remove that creator from your favourites list
    removeFromFavourites: (state, action) => {
      state.featuredCreator.map((option) => {
        if (option.user.id === action.payload) {
          option.user.status = false;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(featuredCreatorGet.pending, (state, action) => {
        state.featuredCreatorGetStatus = FEATURED_CREATOR_GET_STATUSES.LOADING;
      })
      .addCase(featuredCreatorGet.fulfilled, (state, action) => {
        state.featuredCreator = action.payload;
        state.featuredCreatorGetStatus = FEATURED_CREATOR_GET_STATUSES.IDLE;
      })
      .addCase(featuredCreatorGet.rejected, (state, action) => {
        state.featuredCreatorGetStatus = FEATURED_CREATOR_GET_STATUSES.ERROR;
      });
  },
});

export const { addToFavourites, removeFromFavourites } =
  featuredCreatorSlice.actions;
export default featuredCreatorSlice.reducer;

// Thunk for featured Creator Data Fetching
export const featuredCreatorGet = createAsyncThunk(
  'featuredCreatorGet',
  async (thunkAPI) => {
    try {
      if (localStorage.getItem('token')) {
        const response = await API.get('/core/featured_creator/', {
          headers: { Authorization: `token ${localStorage.getItem('token')}` },
        });
        return response.data.message;
      } else {
        const response = await API.get('/core/featured_creator/');
        return response.data;
      }
    } catch (error) {
      if (error.response.data.code === apiErrorCodes.INVALID_TOKEN) {
        localStorage.removeItem('token');
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
