import React, { useEffect, useState } from 'react';
import classes from './UploadContent.module.css';
// import Cropper from 'react-easy-crop';
import { Autocomplete, Chip, TextField } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import { FiArrowUpCircle } from 'react-icons/fi';
import styled from 'styled-components';
import MaxSelectFileErrorModal from './ErrorModals/MaxSelectFileErrorModal/MaxSelectFileErrorModal';
import { useViewport } from '../../../../../CustomHooks/useViewport';
import { useForm } from 'react-hook-form';
import AcknowledgementModal from './Utilities/AcknowledgementModal/AcknowledgementModal';
import { useNavigate, useParams } from 'react-router-dom';
import { TiCamera } from 'react-icons/ti';
import API from '../../../../../Axios/axios';
import { Helmet } from 'react-helmet-async';
import firstLetterCapitalizer from '../../../../../HelperFunctions/firstLetterCapitalizer';
import { useDispatch, useSelector } from 'react-redux';
import { postUpload } from '../../../../../Store/TVPagePostsSlice';
import UnsupportedFileModal from './ErrorModals/UnsupportedFileModal/UnsupportedFileModal';
import { apiErrorCodes } from '../../../../../apiErrorCodes';
import { addMyContentPost } from '../../../../../Store/userProfileSlice';
import { countCharacters } from '../../../../../HelperFunctions/countCharacters';
import ModalCropper from '../../../../ImageCropper/ModalCropper';

const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;

export default function UploadContent() {
  const token = localStorage.getItem('token');
  const [projectTitleMobileError, setProjectTitleMobileError] = useState(false);
  const [firstImage, setFirstImage] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [unsupportedFileModal, setUnsupportedFileModal] = useState(false);
  const [interests, setInterests] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { name } = params;
  const temp = [];
  // custom hook to get viewport width
  const { height, width } = useViewport();
  const breakpoint = 900; // this break point will toggle different view components

  // Add Categories box
  const [uploadContentCategories, setUploadContentCategories] = useState([]);
  const onDelete = (e) => () => {
    setUploadContentCategories((uploadContentCategories) =>
      uploadContentCategories.filter((v) => v !== e)
    );
  };

  // react-easy-crop
  // const [crop, setCrop] = useState({ x: 0, y: 0 });
  // const [zoom, setZoom] = useState(1);
  // const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  //   console.log(croppedArea, croppedAreaPixels)
  // }, []);

  // react-hook-form stuff
  const { handleSubmit } = useForm({ mode: 'onChange' });

  const onSubmit = async (data) => {
    if (uploadContentCategories.length < 4 || projectTitle.length !== 0) {
      if (uploadContentCategories.length < 4) {
        setAddMinimumCategoriesWarning(true);
      }
      if (projectTitle.length === 0) {
        setProjectTitleMobileError(true);
      }
    } else {
      setAddMinimumCategoriesWarning(false);
    }
    if (contentTypeHeading === 'project' && projectTitle.length < 1) {
      setProjectTitleMobileError(true);
      return false;
    } else if (temp.length < 4) {
      return false;
    } else {
      setButtonEnabled(false);
      const formData = new FormData();
      if (name === 'project') {
        formData.append('content_type', 'Project');
      }
      if (name === 'creativework') {
        formData.append('content_type', 'Creative Work');
      }
      if (name === 'innovation') {
        formData.append('content_type', 'Innovation');
      }
      if (name === 'stemcreations') {
        formData.append('content_type', 'STEM Creations');
      }
      formData.append('project_title', projectTitle);
      formData.append('text', contentDescription);
      formData.append('media', firstImage);
      formData.append('category', temp);
      // temp.forEach((option) => formData.append('category', option));

      try {
        const response = await API.post('/posts/', formData, {
          headers: {
            Authorization: `token ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.data.code === apiErrorCodes.SUCCESS) {
          setButtonEnabled(true);
          setAcknowledgementModal(true);
          dispatch(postUpload(response.data.message.post));
          dispatch(addMyContentPost(response.data.message.post));
        }
      } catch (error) {
        if (
          error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        } else if (
          error.response.data.code === apiErrorCodes.UNSUPPORTED_FILE
        ) {
          setUnsupportedFileModal(true);
          setErrorType('UNSUPPORTED_FILE_TYPE');
          setButtonEnabled(true);
        } else if (
          error.response.data.code === apiErrorCodes.POST_CATEGORY_REQUIRED
        ) {
          setAddMinimumCategoriesWarning(true);
          setButtonEnabled(true);
        } else if (
          error.response.data.code === apiErrorCodes.POST_TITLE_REQUIRED
        ) {
          setProjectTitleMobileError(true);
          setButtonEnabled(true);
        }
      }
    }
  };

  const [contentTypeHeading, setContentTypeHeading] = useState(name);
  // This array will contain all the media uploaded by user
  const [mediaContent, setMediaContent] = useState([]);
  // const [currentMedia, setCurrentMedia] = useState('');
  const [projectTitle, setProjectTitle] = useState('');

  // Boolean values to display the error of selecting more than 10 media
  const [maxSelectFileErrorModal, setMaxSelectFileErrorModal] = useState(false);

  // Boolean values to display the acknowledgement modal after uploading the media
  const [acknowledgementModal, setAcknowledgementModal] = useState(false);
  const [contentDescription, setContentDescription] = useState('');

  // Boolean values to display the error in mobile view is user does not add min 3 categories in mobile view
  const [addMinimumCategoriesWarning, setAddMinimumCategoriesWarning] =
    useState(false);

  // Setting UnSupportedFile errors
  const [errorType, setErrorType] = useState('');

  const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);
  const { userProfileData } = useSelector((state) => state.userProfileSlice);
  const [personData, setPersonData] = useState({
    userName: userProfileData.username,
    firstName: userProfileData.first_name,
    lastName: userProfileData.last_name,
    bannerImg: `${process.env.REACT_APP_API_PATH}${userProfileData.banner_pic}`,
    avatarImg: `${process.env.REACT_APP_API_PATH}${userProfileData.profile_pic}`,
    dob: userProfileData.dob,
    gender: userProfileData.gender,
    countryCode: userProfileData.country_code,
    phoneNumber: userProfileData.phone,
    address: userProfileData.address,
    city: userProfileData.city,
    state: userProfileData.state,
    country: userProfileData.country,
    zip: userProfileData.zip,
    impact: userProfileData.impact_question,
  });
  const [modalBanner, setModalBanner] = useState(personData['bannerImg']);
  const [extension, setExtension] = useState(null);

  //This effect will set the preview to the first image in the media content array
  // useEffect(() => {
  //   if (deleteMediaButtonClick === true) {
  //     setCurrentMedia(mediaContent[mediaContent.length - 1]);
  //     setDeleteMediaButtonClick(!deleteMediaButtonClick);
  //   }
  //   else {
  //     setCurrentMedia(mediaContent[0]);
  //   }
  // }, [uploadIconClicked])

  useEffect(() => {
    if (name === 'stemcreations') {
      setContentTypeHeading('STEM Creations');
    } else if (name === 'creativework') {
      setContentTypeHeading('creative work');
    } else {
      setContentTypeHeading(name);
    }
  }, [name]);

  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 900 },
  //     items: 6,
  //   },
  //   medium: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 900, min: 0 },
  //     items: 3,
  //   },
  // };

  // const addcategory = (nid, projectCategoryNew) => {
  //   projectCategoryNew =
  //     projectCategoryNew.length < 20
  //       ? projectCategoryNew
  //       : projectCategoryNew.substring(0, 19) + '..';
  //   console.log('working');
  //   if (projectCategoryNew.length > 1) {
  //     const ob = {
  //       id: nid,
  //       title: projectCategoryNew,
  //     };
  //     setProjectCategory((projectCategory) => [...projectCategory, ob]);
  //   }
  //   console.log(projectCategory);
  // };

  // const delcategory = (nid) => {
  //   console.log('nid');
  //   const newarray = projectCategory.filter((i) => i.id != nid);
  //   setProjectCategory(newarray);
  //   console.log(projectCategory);
  // };

  const handleImageUpload = (event) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp)$/i; // regex for allowed extensions
    // For single image upload
    const newMediaImage = event.target?.files?.[0];
    if (!allowedExtensions.exec(newMediaImage.name)) {
      setUnsupportedFileModal(true);
      setErrorType('UNSUPPORTED_FILE_TYPE');
      event.target.value = '';
    } else {
      console.log(newMediaImage.name);
      if (countCharacters(newMediaImage.name) > 100) {
        setUnsupportedFileModal(true);
        setErrorType('UNSUPPORTED_FILE_NAME');
      } else {
        setFirstImage(event.target?.files?.[0]);
        setModalBanner(URL.createObjectURL(newMediaImage));
        setExtension(event.target?.files?.[0].name.split('.').pop());
        setIsBannerModalOpen(true);
      }
    }
    event.target.value = null;

    // For multiple image upload

    // setUploadIconClicked(!uploadIconClicked);
    // if (e.target.files.length > 10 - mediaContent.length) {
    //   setTimeout(() => { setMaxSelectFileErrorModal(false) }, 3000);
    //   setMaxSelectFileErrorModal(true);
    // }
    // else {
    //   console.log(`Media Length else: ${mediaContent.length}`);
    //   setMaxSelectFileErrorModal(false);
    //   const filesArray = Array.from(e.target.files).map((file) =>
    //     URL.createObjectURL(file)
    //   );
    //   console.log('filesArray: ', filesArray);
    //   setMediaContent((prevImages) => prevImages.concat(filesArray));
    //   Array.from(e.target.files).map(
    //     (file) => URL.revokeObjectURL(file) // avoid memory leak
    //   );
    //   console.log('setMediaContent', setMediaContent);
    // }
  };

  // function for opening media in cropper
  // const handleOpenMedia = (url) => {
  //   setCurrentMedia(url);
  // };

  // function for deleting media in multiple image upload

  // const handleDeleteMedia = (url) => {
  //   setDeleteMediaButtonClick(true);
  //   const pindex = mediaContent.indexOf(url);
  //   console.log(mediaContent.length);
  //   setMediaContent((mediaContent) => {
  //     return mediaContent.filter((urll, i) => i !== pindex);
  //   });
  //   if (pindex === mediaContent.length - 1) {
  //     setCurrentMedia(mediaContent[pindex - 1]);
  //   } else {
  //     setCurrentMedia(mediaContent[pindex + 1]);
  //   }
  // };

  // const onImageError = () =>{imgRef.current.src=mediaContent[0];}

  // useEffect(() => {
  //   interests.map((option) => {
  //     categories.push(option.name);
  //   })
  //   console.log(categories);
  // }, [])
  // useEffect(() => {
  //   console.log(categories);
  // }, [categories])

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get('/core/interests/', {
          headers: {
            Authorization: `token ${localStorage.getItem('token')}`,
          },
        });
        if (response.data.code === apiErrorCodes.SUCCESS) {
          setInterests(response.data.message);
        }
      } catch (error) {
        if (
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
          error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    })();
  }, []);

  const handleBannerImageModalChange = (blob, file) => {
    try {
      setPersonData({
        ...personData,
        bannerImg: blob,
      });
      setFirstImage(file);
      setMediaContent(blob);
      setIsBannerModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleBannerClose = () => {
    setIsBannerModalOpen(false);
  };

  return (
    <div className={classes.body}>
      {maxSelectFileErrorModal && (
        <MaxSelectFileErrorModal
          setMaxSelectFileErrorModal={setMaxSelectFileErrorModal}
        />
      )}
      {acknowledgementModal && (
        <AcknowledgementModal
          message={'Content Published Successfully.'}
          setAcknowledgementModal={setAcknowledgementModal}
        />
      )}
      {unsupportedFileModal && (
        <UnsupportedFileModal
          setUnsupportedFileModal={setUnsupportedFileModal}
          errorType={errorType}
        />
      )}
      {isBannerModalOpen && (
        <ModalCropper
          image={modalBanner}
          onClose={handleBannerClose}
          handleApply={handleBannerImageModalChange}
          aspect={3 / 2}
          shape="rect"
          extension = {extension}
        />
      )}
      <Helmet>
        <title>
          Upload{' '}
          {contentTypeHeading === 'STEM Creations'
            ? contentTypeHeading
            : firstLetterCapitalizer(contentTypeHeading)}{' '}
          | Srijcon
        </title>
        <link rel="canonical" href={`/uploadcontent/${name}`} />
        <meta
          name="description"
          // content="Srijcon - Connect with innovators, inventors, makers, creators and share your projects, innovations, creative works or STEM creations with the world."
          content="Srijcon connects creative and Innovative minds to come together and share inspiring creations and ideas to impact the world."
        />
      </Helmet>
      <div className={classes.mainContainer}>
        {mediaContent.length === 0 ? (
          <>
            {/* This code will work if image is not uploaded*/}
            <div className={classes.leftContainer}>
              <h1 className="component-heading">{contentTypeHeading}</h1>
              <div className={classes.UploadMediaContainer}>
                <input
                  hidden
                  accept=".jpg, .jpeg, .png"
                  type="file"
                  id="file"
                  // multiple
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}
                />
                <label htmlFor="file" className={classes.labelHolder}>
                  <FiArrowUpCircle className={classes.uploadIcon} />
                  <h2 className={classes.descriptionText}>Upload Media</h2>
                </label>
              </div>
            </div>
            {/* This code will work on Desktop view if image is not uploaded*/}
            {width > breakpoint ? (
              <div
                style={{ overflow: 'hidden' }}
                className={classes.rightContainer}
              >
                {/* This code will work on Desktop view if content type heading is project*/}
                {contentTypeHeading == 'project' ? (
                  <>
                    <input
                      disabled
                      type="text"
                      className={classes.disabledProjectTitle}
                      placeholder="Project Title"
                    />
                    <div
                      style={{ cursor: 'not-allowed' }}
                      className={classes.disabledAddCategoryDiv}
                    >
                      <p
                        style={{
                          textAlign: 'left',
                          padding: '1.7rem 1.4rem 0 1.4rem',
                          fontSize: '1.4rem',
                          color: '#e5e5e5',
                          borderBottom: '0.1rem solid #a3a3a3',
                        }}
                      >
                        Add Minimum 4 Categories
                      </p>
                    </div>
                    {height < 700 ? (
                      <textarea
                        style={{
                          height: '21.5vh',
                          padding: '1.4rem',
                          fontSize: '1.4rem',
                        }}
                        disabled
                        className={classes.disabledDescribeYourContentBox}
                        placeholder="How it's made / How it works?"
                      />
                    ) : (
                      <textarea
                        style={{
                          height: '21.5vh',
                          padding: '1.4rem',
                          fontSize: '1.4rem',
                        }}
                        disabled
                        className={classes.disabledDescribeYourContentBox}
                        placeholder="How it's made / How it works?"
                      />
                    )}
                  </>
                ) : (
                  <>
                    {/* This code will work on Desktop view if content type heading is creative work or innovation or STEM Creations*/}
                    <div
                      style={{ cursor: 'not-allowed' }}
                      className={classes.disabledAddCategoryDiv}
                    >
                      <p
                        style={{
                          textAlign: 'left',
                          padding: '1.7rem 1.4rem 0 1.4rem',
                          fontSize: '1.4rem',
                          color: '#e5e5e5',
                          borderBottom: '0.1rem solid #a3a3a3',
                        }}
                      >
                        Add Minimum 4 Categories
                      </p>
                    </div>
                    {height < 700 ? (
                      <textarea
                        style={{
                          height: '31.2vh',
                          padding: '1.4rem',
                          fontSize: '1.4rem',
                        }}
                        disabled
                        className={classes.disabledDescribeYourContentBox}
                        placeholder="How it's made / How it works?"
                      />
                    ) : (
                      <textarea
                        style={{
                          height: '32.7vh',
                          padding: '1.4rem',
                          fontSize: '1.4rem',
                        }}
                        disabled
                        className={classes.disabledDescribeYourContentBox}
                        placeholder="How it's made / How it works?"
                      />
                    )}
                  </>
                )}
                <button className={classes.publishButtonDisabled}>
                  Publish
                </button>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            {/* This code will work if image is uploaded*/}
            <div className={classes.leftContainer}>
              <h4 className="component-heading">{contentTypeHeading}</h4>
              <div className={classes.previewMedia}>
                <img draggable="false" src={mediaContent} alt="mediaContent" />
                {/* <Cropper
                  style={{
                    containerStyle: {
                      position: "relative", overflow: "hidden", border: "1px solid black", borderRadius: '0.5rem', aspectRatio: '3/2', padding: '0', margin: '0',
                    },
                    mediaStyle: {
                      width: "100%", objectFit: 'contain', padding: '0', margin: '0',
                    },
                    cropAreaStyle: { width: "100%", height: "100%", borderRadius: '0.5rem' }
                  }
                  }
                  image={mediaContent}
                  // image={currentMedia}
                  crop={crop}
                  zoom={zoom}
                  aspect={3 / 2}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  // objectFit='contain'
                  objectFit='horizontal-cover'
                // objectFit='vertical-cover'
                // objectFit='auto-cover'
                // objectFit={currentMediaWidth > currentMediaWidth ? 'vertical-cover' : 'horizontal-cover'}
                /> */}
              </div>

              {/* only for single image upload */}
              <div className={classes.changeMediaDiv}>
                <div className={classes.changeMediaIconDiv}>
                  <input
                    hidden
                    accept=".jpg, .jpeg, .png"
                    type="file"
                    id="file"
                    // multiple
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                  />
                  <label htmlFor="file">
                    <h3>Change Media</h3>
                    <TiCamera className={classes.cameraIcon} />
                  </label>
                </div>
              </div>
              {/* Ribben for multiple image upload */}
              {/* <div className={classes.thumbnailSection}>
                {mediaContent.length < 10 ?
                  <label htmlFor="file" className={classes.labelHolder}>
                    <div className={classes.addMediaInput}>
                      <input
                        hidden
                        accept=".jpg, .jpeg, .png"
                        type="file"
                        id="file"
                        multiple
                        onChange={handleImageUpload}
                      />
                      <FiPlusCircle className={classes.addIcon} />
                    </div>
                  </label>
                  :
                  ''
                }

                <Carousel responsive={responsive} itemClass={classes.carouselItem} containerClass={classes.carouselMedia} removeArrowOnDeviceType='medium'>
                  {mediaContent.map((url) => {
                    return (
                      <div>
                        <img draggable="false"  src={url} alt="/" onClick={() => handleOpenMedia(url)} />
                        <BsFillXCircleFill
                          className={classes.crossIcon}
                          onClick={() => {
                            handleDeleteMedia(url);
                          }}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div> */}
            </div>
            <div className={classes.rightContainer}>
              {/* This code will work on desktop view if image is uploaded*/}
              {width > breakpoint ? (
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  {/* This code will work on desktop view if image is uploaded and content type heading is project*/}
                  {contentTypeHeading === 'project' ? (
                    <>
                      <StyledTextField
                        placeholder="Project Title"
                        value={projectTitle}
                        onChange={(e) =>
                          setProjectTitle(
                            e.target.value.replace(
                              /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@.] ]/gm,
                              ''
                            )
                          )
                        }
                        variant="outlined"
                        sx={{
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 },
                          width: '100%',
                          margin: '1rem 0',
                        }}
                        InputLabelProps={{
                          style: { fontSize: '1.4rem', color: '#6e6e6e' },
                        }}
                        InputProps={{
                          style: { fontSize: '1.4rem', fontWeight: 400 },
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                      <div className={classes.addCategoryDiv}>
                        <Autocomplete
                          disabled={
                            uploadContentCategories.length >= 20 ? true : false
                          }
                          multiple
                          autoComplete
                          disableClearable
                          disableCloseOnSelect
                          filterSelectedOptions
                          options={interests}
                          getOptionLabel={(option) => option.name}
                          value={uploadContentCategories}
                          classes={{ option: classes.dropdownOption }}
                          onChange={(e, newValue) =>
                            setUploadContentCategories(newValue)
                          }
                          renderTags={() => null}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              placeholder={
                                uploadContentCategories.length < 4
                                  ? 'Add Minimum 4 Categories'
                                  : 'Add Categories'
                              }
                              variant="standard"
                              color="darkGrey"
                              sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                width: '100%',
                                padding: '1rem 0',
                                display: 'flex',
                              }}
                              InputLabelProps={{
                                style: {
                                  fontSize: '1.4rem',
                                  color: '#6e6e6e',
                                  padding: '0 1.4rem',
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  fontSize: '1.4rem',
                                  fontWeight: 400,
                                  padding: ' 0 1.4rem',
                                },
                              }}
                            />
                          )}
                        />
                        <div className={classes.chipContainer}>
                          {uploadContentCategories.map((e) => (
                            <>
                              <Chip
                                className={classes.chip}
                                key={e.id}
                                label={e.name}
                                onDelete={onDelete(e)}
                              />
                              <div style={{ display: 'none' }}>
                                {temp.push(e.id)}
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                      <StyledTextField
                        placeholder="How it's made / How it works?"
                        variant="outlined"
                        value={contentDescription}
                        // setProjectTitle(e.target.value.replace(/[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@.] ]/gm, ''))
                        onChange={(e) =>
                          setContentDescription(
                            e.target.value.replace(
                              /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@. ]/gm,
                              ''
                            )
                          )
                        }
                        multiline
                        rows={height < 700 ? 5 : 6}
                        sx={{
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 },
                          width: '100%',
                          margin: '1rem 0',
                        }}
                        InputLabelProps={{
                          style: { fontSize: '1.4rem', color: '#6e6e6e' },
                        }}
                        InputProps={{
                          style: { fontSize: '1.4rem', fontWeight: 400 },
                        }}
                        inputProps={{ maxLength: 1000 }}
                      />

                      {projectTitle.length > 0 &&
                      uploadContentCategories.length > 3 &&
                      buttonEnabled ? (
                        <button
                          className={classes.publishButtonEnabled}
                          type="submit"
                        >
                          Publish
                        </button>
                      ) : (
                        <button
                          className={classes.publishButtonDisabled}
                          type="button"
                        >
                          Publish
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      {/* This code will work on desktop view if image is uploaded and content type heading is creative work or innovation or STEM creations*/}
                      <div className={classes.addCategoryDiv}>
                        <Autocomplete
                          // disabled={uploadContentCategories.length >= 20 ? true : false}
                          multiple
                          autoComplete
                          disableClearable
                          disableCloseOnSelect
                          filterSelectedOptions
                          options={interests}
                          getOptionLabel={(option) => option.name}
                          value={uploadContentCategories}
                          classes={{ option: classes.dropdownOption }}
                          onChange={(e, newValue) =>
                            setUploadContentCategories(newValue)
                          }
                          renderTags={() => null}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              placeholder={
                                uploadContentCategories.length < 4
                                  ? 'Add Minimum 4 Categories'
                                  : 'Add Categories'
                              }
                              variant="standard"
                              color="darkGrey"
                              sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                width: '100%',
                                margin: '1rem 0',
                                display: 'flex',
                              }}
                              InputLabelProps={{
                                style: { fontSize: '1.4rem', color: '#6e6e6e' },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  fontSize: '1.4rem',
                                  fontWeight: 400,
                                  padding: ' 0 1.4rem',
                                },
                              }}
                            />
                          )}
                        />
                        <div className={classes.chipContainer}>
                          {uploadContentCategories.map((e) => (
                            <>
                              <Chip
                                className={classes.chip}
                                key={e.id}
                                label={e.name}
                                onDelete={onDelete(e)}
                              />
                              <div style={{ display: 'none' }}>
                                {temp.push(e.id)}
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                      <StyledTextField
                        placeholder="How it's made / How it works?"
                        variant="outlined"
                        value={contentDescription}
                        onChange={(e) =>
                          setContentDescription(
                            e.target.value.replace(
                              /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@. ]/gm,
                              ''
                            )
                          )
                        }
                        multiline
                        rows={height < 700 ? 8 : 10}
                        sx={{
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 },
                          width: '100%',
                          margin: '1rem 0',
                        }}
                        InputLabelProps={{
                          style: { fontSize: '1.4rem', color: '#6e6e6e' },
                        }}
                        InputProps={{
                          style: { fontSize: '1.4rem', fontWeight: 400 },
                        }}
                        inputProps={{ maxLength: 1000 }}
                      />
                      {uploadContentCategories.length > 3 && buttonEnabled ? (
                        <button
                          className={classes.publishButtonEnabled}
                          type="submit"
                        >
                          Publish
                        </button>
                      ) : (
                        <button
                          className={classes.publishButtonDisabled}
                          type="button"
                        >
                          Publish
                        </button>
                      )}
                    </>
                  )}
                </form>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  {/* This code will work on Mobile view  if image is uploaded*/}

                  {contentTypeHeading === 'project' ? (
                    <StyledTextField
                      placeholder="Project Title"
                      variant="outlined"
                      defaultValue={projectTitle}
                      value={projectTitle}
                      onChange={(e) => {
                        setProjectTitle(
                          e.target.value.replace(
                            /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@.] ]/gm,
                            ''
                          )
                        );
                      }}
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        width: '100%',
                        margin: '1rem 0',
                      }}
                      InputLabelProps={{
                        style: { fontSize: '1.4rem', color: '#6e6e6e' },
                      }}
                      InputProps={{
                        style: { fontSize: '1.4rem', fontWeight: 400 },
                      }}
                      inputProps={{ maxLength: 200 }}
                      helperText={
                        projectTitleMobileError ? 'Required field' : ''
                      }
                    />
                  ) : (
                    ''
                  )}
                  <div
                    className={classes.addCategoryDiv}
                    style={{
                      height: contentTypeHeading !== 'project' ? '16.5vh' : '',
                    }}
                  >
                    <Autocomplete
                      disabled={
                        uploadContentCategories.length >= 20 ? true : false
                      }
                      multiple
                      autoComplete
                      disableClearable
                      disableCloseOnSelect
                      filterSelectedOptions
                      options={interests}
                      getOptionLabel={(option) => option.name}
                      value={uploadContentCategories}
                      classes={{ option: classes.dropdownOption }}
                      onChange={(e, newValue) =>
                        setUploadContentCategories(newValue)
                      }
                      renderTags={() => null}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          placeholder={
                            uploadContentCategories.length < 4
                              ? 'Add Minimum 4 Categories'
                              : 'Add Categories'
                          }
                          variant="standard"
                          color="darkGrey"
                          sx={{
                            '&::placeholder': {
                              color: 'blue',
                            },
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                            width: '100%',
                            margin: '1rem 0',
                            display: 'flex',
                          }}
                          InputLabelProps={{
                            style: { fontSize: '1.4rem', color: '#6e6e6e' },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontSize: '1.4rem',
                              fontWeight: 400,
                              padding: ' 0 1.4rem',
                            },
                          }}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          // {...register('projectCategories', {
                          //   required: 'Required field',
                          // })}
                          // error={!!errors?.projectCategories}
                          // helperText={
                          //   errors?.projectCategories
                          //     ? errors.projectCategories.message
                          //     : null
                          // }
                          helperText={
                            addMinimumCategoriesWarning ? 'Required field' : ''
                          }
                        />
                      )}
                    />
                    <div className={classes.chipContainer}>
                      {uploadContentCategories.map((e) => (
                        <>
                          <Chip
                            className={classes.chip}
                            key={e.id}
                            label={e.name}
                            onDelete={onDelete(e)}
                          />
                          <div style={{ display: 'none' }}>
                            {temp.push(e.id)}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  {/* {addMinimumCategoriesWarning ? <p className={classes.AddMinimumCategoriesWarningText}>    Add Minimum 4 Categories</p> : ''} */}
                  <StyledTextField
                    placeholder="How it's made / How it works?"
                    variant="outlined"
                    value={contentDescription}
                    onChange={(e) =>
                      setContentDescription(
                        e.target.value.replace(
                          /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@. ]/gm,
                          ''
                        )
                      )
                    }
                    multiline
                    rows={7}
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      width: '100%',
                      margin: '1rem 0',
                    }}
                    InputLabelProps={{
                      style: { fontSize: '1.4rem', color: '#6e6e6e' },
                    }}
                    InputProps={{
                      style: { fontSize: '1.4rem', fontWeight: 400 },
                    }}
                    inputProps={{ maxLength: 1000 }}
                  />
                  {buttonEnabled ? (
                    <button
                      className={classes.publishButtonEnabled}
                      type="submit"
                    >
                      Publish
                    </button>
                  ) : (
                    <button
                      className={classes.publishButtonDisabled}
                      type="button"
                    >
                      Publish
                    </button>
                  )}
                </form>
              )}
            </div>
          </>
        )}
        <div className={classes.emptyDiv}></div>
      </div>
    </div>
  );
}
