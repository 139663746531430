import React, { useState } from 'react';

// Stylesheet import
import classes from './TVCardDropdown.module.css';

// Icon import from Feather icon
import { Bookmark as Save } from 'react-feather';

// Zoom & Grow transition import form Material UI
import { Grow } from '@mui/material';

// Card Component import from UI section
import Card from '../../../CommonComponents/Card/Card';

// Delete Element import
import DeleteElement from './DeleteElement/DeleteElement';

// Flag Element import
import FlagElement from './Flag/FlagElement';

// Redux dispatch to dispatch user credentials
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../../Axios/axios';
import { useNavigate } from 'react-router-dom';
import { savePost, UnSavePost } from '../../../../Store/userProfileSlice';

function TVCardDropdown({
  postData,
  postSaved,
  setPostSaved,
  postFlagged,
  setPostFlagged,
}) {
  // Destructuring postData props
  const { id, creator } = postData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(postSaved);

  const { userProfileData } = useSelector((state) => state.userProfileSlice);

  const token = localStorage.getItem('token');

  // const toggleSaveHandler = () => {
  //   setIsSaved(!isSaved);
  // };

  const savePostClickHandler = async () => {
    if (token) {
      try {
        const response = await API.post(
          `/posts/${id}/saved`,
          {},
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        console.log(response.data);
        setIsSaved(!isSaved);
        if (isSaved) {
          dispatch(UnSavePost(response.data.message.post.id));
        } else {
          dispatch(savePost(response.data.message));
        }
      } catch (e) {
        if (e.response.data.message === 'Invalid token.') {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    } else {
      navigate('/entry/signin');
    }
  };

  return (
    <Grow in={true}>
      <div className={classes.dropdown}>
        <Card>
          {creator.id === userProfileData.id ? (
            <DeleteElement postId={id} />
          ) : (
            <>
              <div className={classes.element} onClick={savePostClickHandler}>
                {isSaved ? (
                  <Save className={classes.saved} />
                ) : (
                  <Save className={classes.icon} />
                )}
                <p style={{ display: 'contents' }} className="element-heading">
                  {isSaved ? 'Saved' : 'Save'}
                </p>
              </div>
              <FlagElement postId={id} postFlagged={postFlagged} />
            </>
          )}
        </Card>
      </div>
    </Grow>
  );
}

export default TVCardDropdown;
