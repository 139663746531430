import React, {useEffect, useState} from 'react';

// navigation added in React
import {useNavigate, useParams} from 'react-router-dom';

// Stylesheets imported
import classes from './UserProfile.module.css';
import './UserProfile.css';

// Images
import SocialImpact from '../../Assets/Images/ReactionImages/SocialImpact.svg';
import EcoImpact from '../../Assets/Images/ReactionImages/EcoImpactPadding.svg';
import LogoImg from '../../Assets/Logo/logo.svg';

// Components
import {HiPencil} from 'react-icons/hi';
import {FiGrid, FiUserCheck, FiUserPlus} from 'react-icons/fi';

// Tooltip Component imported
import MyToolTip from '../CommonComponents/MyToolTip';

// creators' Fans' Component imported
import CreatorFans from './FansAndFavourites/CreatorFans';

// TVCardDescription2 Component imported
import TVCardDescription2 from '../TVPage/TVCard/TVCardDescription/TVCardDescription2';

// Material UI stuff imported
// API base addresses imported
import API from '../../Axios/axios';

// Import to calculate time difference
import numberRoundOff from '../../HelperFunctions/numberRoundOff';

// import to capitalize the first letter of every word in a string
import firstLetterCapitalizer from '../../HelperFunctions/firstLetterCapitalizer';

// custom hook to get viewport dimensions of the screen
import {useViewport} from '../../CustomHooks/useViewport';

// Redux dispatch reducers
import {useDispatch} from 'react-redux';

// To update fans count in user profile
import {updateFavouritesCount} from '../../Store/userProfileSlice';
import {Helmet} from 'react-helmet-async';
import {apiErrorCodes} from '../../apiErrorCodes';
import { Thumbnail } from './Thumbnail';

function CreatorProfile() {
  const token = localStorage.getItem('token');
  const params = useParams();
  const {name} = params;
  const navigate = useNavigate();
  const breakpoint = 600;
  // custom hook to get viewport width
  const {width} = useViewport();
  const [showFans, setShowFans] = useState(false);
  const [show, setShow] = useState(true);
  const [active, setActive] = useState(true);
  const [showTVCard, setShowTVCard] = useState(false);
  const [creatorData, setCreatorData] = useState(null);
  const [status, setStatus] = useState('loading');
  const [postData, setPostData] = useState();
  const dispatch = useDispatch();

  const creatorFansClickHandler = () => {
    setShowFans(!showFans);
  };
  const updateFansCount = (fans) => {
    setCreatorData({
      ...creatorData, users: {...creatorData.users, fans_count: fans},
    });
  };

  const followAndUnfollowClickHandler = async () => {
    try {
      const response = await API.post(`${creatorData.follow_url}`, {}, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      if (response.data.code === apiErrorCodes.SUCCESS) {
        if (response.data.message.status === true) {
          setCreatorData({
            ...creatorData, follow_status: response.data.message.status, users: {
              ...creatorData.users, fans_count: creatorData.users.fans_count + 1,
            },
          });
          dispatch(updateFavouritesCount(response.data.message.total_favourites));
        } else {
          setCreatorData({
            ...creatorData, follow_status: response.data.message.status, users: {
              ...creatorData.users, fans_count: creatorData.users.fans_count - 1,
            },
          });
          dispatch(updateFavouritesCount(response.data.message.total_favourites));
        }
      }
    } catch (error) {
      if (error.response.data.code === apiErrorCodes.INVALID_TOKEN || error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED || error.response.data.code === apiErrorCodes.UNAUTHORIZED_USER || error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED || error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };

  useEffect(() => {
    if (name) {
      (async function () {
        try {
          const response = await API.get(`/accounts/${name}/profile/`, {
            headers: {
              Authorization: `token ${localStorage.getItem('token')}`,
            },
          });
          if (response.data.code === apiErrorCodes.SUCCESS) {
            setCreatorData(response.data.message);
            setStatus('done');
          }
        } catch (error) {
          if (error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED || error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED || error.response.data.code === apiErrorCodes.INVALID_TOKEN || error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST) {
            localStorage.removeItem('token');
            navigate('/entry/signin');
          }
        }
      })();
    } else {
      setStatus('done');
    }
  }, [name]);

  const myContentClickHandler = async (id) => {
    navigate(`/post/${id}/`);
    // try {
    //   const response = await API.get(`/posts/${id}/`);
    //   setPostData(response.data);
    //   setShowTVCard(true);
    // } catch (e) {}
  };

  if (status === 'loading') {
    return '';
  }

  return (<>
    {showFans && (<CreatorFans
      id={name}
      handleSubmit={creatorFansClickHandler}
      updateFansCount={updateFansCount}
    />)}
    <Helmet>
      <title>
        {creatorData.first_name} {creatorData.last_name} | Srijcon
      </title>
      <link rel="canonical" href={`/accounts/${name}/profile/`}/>
      <meta
        name="description"
        content={`${creatorData.impact_question}`}
        data-rh="true"
      />
    </Helmet>
    <div className={classes.main}>
      <div className={classes.UserProfile}>
        {/* For Banner At the top of the Above section */}
        <div className={classes.userBanner}>
          <img
            draggable="false"
            src={creatorData.banner_pic}
            alt="BannerImg"
          />
        </div>

        {/* Pencil - Edit Button */}
        <div
          className={classes.editBannerPencilDiv}
          style={{visibility: name ? 'hidden' : ''}}
        >
          <MyToolTip title="Edit My Profile">
            <div
              className={classes.editBannerPencil}
              onClick={() => {
                navigate('/editprofile');
              }}
            >
              <HiPencil className={classes.featherUser2}/>
            </div>
          </MyToolTip>
        </div>

        {/* Button if you're already Favourites that person/account */}
        <MyToolTip title="Favourite" placement="left">
          {/* <div className={classes.favouritesUserCheckIcon} > */}
          <div
            className={classes.favouritesUserCheckIcon}
            style={{
              visibility: `${creatorData.follow_status ? '' : 'hidden'}`,
            }}
            onClick={followAndUnfollowClickHandler}
          >
            <FiUserCheck className={classes.featherUserCheck}/>
          </div>
        </MyToolTip>

        {/* Button to become a fan */}
        <MyToolTip title="Be a Fan" placement="left">
          {/* <div className={classes.fansUserPlusIcon} > */}
          <div
            className={classes.fansUserPlusIcon}
            style={{
              visibility: `${creatorData.follow_status ? 'hidden' : ''}`,
            }}
            onClick={followAndUnfollowClickHandler}
          >
            <FiUserPlus className={classes.featherUser}/>
          </div>
        </MyToolTip>

        {/* User Profile Picture Display */}
        <div className={classes.userAvtar}>
          <div className={classes.imageAvt1}>
            <img
              draggable="false"
              src={creatorData.profile_pic}
              alt="AvatarImage"
            />
          </div>

          {/* Person's Username  */}
          <div className={classes.userName}>
            <div className={classes.userNameText}>
              <h2>
                {creatorData.first_name} {creatorData.last_name}
              </h2>
              <p>{firstLetterCapitalizer(creatorData.top_interest)}</p>
            </div>
          </div>
        </div>

        {/* Score Container for all the Scores */}
        <div className={classes.scoreContainer}>
          <div className={classes.srijconScoreContainer}>
            <div className={classes.scoreIconContainer}>
              <img
                draggable="false"
                className={classes.srijconScoreImg}
                src={LogoImg}
                alt="scoreImage"
              />
              <h2 className={classes.score}>
                {numberRoundOff(creatorData.meta.total_srijcon_score)}
              </h2>
            </div>
            <p className={classes.scoreContainerText}>Srijcon Score</p>
          </div>
          <div className={classes.ecoImpactContainer}>
            <div className={classes.scoreIconContainer}>
              <img
                draggable="false"
                className={classes.ecoImpactImg}
                src={EcoImpact}
                alt="scoreImage"
              />
              <h2 className={classes.score}>
                {numberRoundOff(creatorData.meta.total_eco_impact)}
              </h2>
            </div>
            <p className={classes.scoreContainerText}>Eco Impact</p>
          </div>
          <div className={classes.socialImpactContainer}>
            <div className={classes.scoreIconContainer}>
              <img
                draggable="false"
                className={classes.socialImpactImg}
                src={SocialImpact}
                alt="scoreImage"
              />
              <h2 className={classes.score}>
                {numberRoundOff(creatorData.meta.total_social_impact)}
              </h2>
            </div>
            <p className={classes.scoreContainerText}>Social Impact</p>
          </div>
          <div className={classes.vlContainer}>
            <hr
              style={{
                height: '100%', border: '0.1rem solid #cbcdda', color: '#cbcdda', backgroundColor: '#cbcdda',
              }}
            />
          </div>
          {/* <div className={classes.fansContainer} > */}
          <div
            className={classes.fansContainer}
            onClick={creatorFansClickHandler}
          >
            <div>
              <h2 className={classes.score}>
                {numberRoundOff(creatorData.users.fans_count)}
              </h2>
            </div>
            <p className={classes.fansAndFavouritesContainerText}>Fans</p>
          </div>
          <div
            style={{visibility: 'hidden'}}
            className={classes.favouritesContainer}
          >
            {/* <div className={classes.favouritesContainer} onClick={handleClick}> */}
            <div className={classes.scoreFirstContainer}>
              <h2 className={classes.score}>
                {numberRoundOff(creatorData.users.favourites_count)}
              </h2>
            </div>
            <p className={classes.fansAndFavouritesContainerText}>
              Favourites
            </p>
          </div>
        </div>
        {/* Container to display Impact description */}
        {creatorData.impact_question === 'null' || creatorData.impact_question === null || creatorData.impact_question === '' ? (
          <div style={{height: '0.1rem'}}></div>) : (<div className={classes.content}>
          <TVCardDescription2 impact={creatorData.impact_question}/>
        </div>)}
      </div>
      {/*  This code is for the other creator's profile*/}
      {creatorData.my_posts.length > 0 ? (<div className={classes.post}>
        <div className={classes.postSection}>
          <div
            className={classes.postHeader}
            style={{
              justifyContent: 'flex-start', padding: `${width < breakpoint ? '0 3.5vw' : ''}`,
            }}
          >
            <div
              className={classes.myContentBar}
              onClick={() => {
                setShow(true);
                setActive(false);
                if (active) {
                  document
                    .querySelector('.gridIconPost')
                    .setAttribute('class', 'gridIconPostActive');
                  document
                    .querySelector('.myContent')
                    .setAttribute('class', 'myContentActive');
                  document
                    .querySelector('.bookmarkIconPostActive')
                    .setAttribute('class', 'bookmarkIconPost');
                  document
                    .querySelector('.savedContentActive')
                    .setAttribute('class', 'savedContent');
                }
              }}
            >
              <FiGrid className="gridIconPostActive"/>
              <p className="myContentActive">Content</p>
            </div>
          </div>

          <div className={classes.postBody}>
            {show && (<div className={classes.images}>
              {creatorData.my_posts.map( option => 
                <div className={classes.modalImages} onClick={() => myContentClickHandler(option.id)}>
                  <Thumbnail
                    key={option.id}
                    alt="MyPostIcon"
                    src={option.image_field.images[0]}
                    multiple={option.image_field.image_count > 1}
                  />
                </div>
              )}
            </div>)}
          </div>
        </div>
      </div>) : ('')}
      <div className={classes.emptyDiv}></div>
    </div>
  </>);
}

export default CreatorProfile;
