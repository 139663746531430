import React, { useEffect, useState } from 'react';

// importing classes for css
import classes from './CreativeInterests.module.css';

// Thunk
import { updateTopInterest } from '../../../../Store/userProfileSlice';

import { useDispatch, useSelector } from 'react-redux';

// importing search bar component
import SearchInput from './SearchInput/SearchInputCI';

// importing tooltip
import MyToolTip from '../../../CommonComponents/MyToolTip';

// importing icons from react-icon
import { FiPlusCircle, FiXCircle, FiZap } from 'react-icons/fi';
import { BsFillCheckCircleFill } from 'react-icons/bs';

// importing custom hook to get viewport width
import { useViewport } from '../../../../CustomHooks/useViewport';

// Badge import from Material UI
import Badge from '@mui/material/Badge';

import { useLocation, useNavigate } from 'react-router-dom';

// importing API base URL
import API from '../../../../Axios/axios';

// Acknowledgment Modal for update of creative interests
import SuccessMessageModal from './SuccessMessageModal/SuccessMessageModal';
import { Helmet } from 'react-helmet-async';
import { apiErrorCodes } from '../../../../apiErrorCodes';
import shortenFileName from "../../../../HelperFunctions/shortenFileName";

export default function CreativeInterests() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Custom hook to get viewport height and width
  const { width } = useViewport();
  const location = useLocation();
  const breakpoint = 768; // this break point will toggle different view components
  const { userProfileData } = useSelector((state) => state.userProfileSlice);
  const [popularInterestsTab, setPopularInterestsTab] = useState(true);
  const [myInterestsTab, setMyInterestsTab] = useState(false);
  const [allInterests, setAllInterests] = useState();
  const [myInterests, setMyInterests] = useState([]);
  const [myTopInterest, setMyTopInterest] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [status, setStatus] = useState('loading');
  const [successMessageModal, setSuccessMessageModal] = useState(false);
  const [leftContainerHeading, setLeftContainerHeading] = useState(
    'Popular Creative Interests'
  );
  const [firstTimeLoading, setFirstTimeLoading] = useState(true);
  const [badge, setBadge] = useState(false);
  const [myInterestsTabClick, setMyInterestsTabClick] = useState(false);
  const [value, setValue] = useState('');

  const checkProfileStatus = async () => {
    try {
      const response = await API.get('/accounts/profile/status', {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      if (response.data.message.profile === false)
        navigate('/entry/userdetails');
      else if (response.data.message.creative_interests === false)
        navigate('/entry/creativeinterest');
    }
    catch {
      throw new Error('Error in validating profile');
    }
  };

  useEffect(() => {
    checkProfileStatus();
  }, []);

  const saveMyInterest = async () => {
    setButtonDisabled(true);
    let temp = [];
    myInterests.map((item) => {
      temp.push(item.id);
    });
    if (location.pathname === '/entry/creativeinterest') {
      try {
        const response = await API.post(
          '/accounts/mycreativeinterest/',
          {
            interests: temp,
            top_interest: myTopInterest.id,
          },
          {
            headers: { Authorization: `token ${token}` },
          }
        );
        if (response.data.code === apiErrorCodes.SUCCESS) {
          dispatch(updateTopInterest(myTopInterest.name));
          setButtonDisabled(false);
          setSuccessMessageModal(true);
        }
      } catch (error) {
        if (
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST ||
          error.response.data.code === apiErrorCodes.UNAUTHORIZED_USER
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    }
    if (location.pathname === '/creativeinterest') {
      try {
        const response = await API.patch(
          '/accounts/mycreativeinterest/',
          {
            interests: temp,
            top_interest: myTopInterest.id,
          },
          {
            headers: { Authorization: `token ${token}` },
          }
        );
        if (response.data.code === apiErrorCodes.SUCCESS) {
          dispatch(updateTopInterest(myTopInterest.name));
          setButtonDisabled(false);
          setSuccessMessageModal(true);
        }
      } catch (error) {
        if (
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST ||
          error.response.data.code === apiErrorCodes.UNAUTHORIZED_USER
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    }
  };
  const popularInterestsTabSwitchHandler = () => {
    setPopularInterestsTab(true);
    setMyInterestsTab(false);
  };
  const myInterestsTabSwitchHandler = () => {
    setMyInterestsTabClick(true);
    setBadge(false);
    setPopularInterestsTab(false);
    setMyInterestsTab(true);
  };
  const RemoveTopInterestHandler = () => {
    setMyTopInterest(myInterests[0]);
    setMyInterests(myInterests.filter((_, i) => i > 0));
  };
  const onSearchHandler = async (event) => {
    const search = event.target.value;
    setValue(search);
    if (search === '' || search == null) {
      setLeftContainerHeading('Popular Creative Interests');
    } else {
      setLeftContainerHeading('Search Results');
    }
    try {
      const response = await API.get(
        `core/creativeinterest/popular-and-search/`,
        {
          params: {
            search: search,
          },
          headers: { Authorization: `token ${token}` },
        }
      );
      setAllInterests(response.data.message);
      setStatus('Done');
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };

  // function to get all creative interests

  const popularInterestsGet = async () => {
    try {
      const response = await API.get(
        'core/creativeinterest/popular-and-search/',
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      if (response.data.code === apiErrorCodes.SUCCESS) {
        setAllInterests(response.data.message);
        setStatus('Done');
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };
  const myInterestsGet = async () => {
    try {
      const response = await API.get('/accounts/mycreativeinterest/', {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      if (response.data.code === apiErrorCodes.SUCCESS) {
        setMyInterests(response.data.message.interests);
        setMyTopInterest(response.data.message.top_interest);
        setFirstTimeLoading(false);
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED
      ) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      } else if (
        error.response.data.code ===
        apiErrorCodes.USER_CREATIVE_INTEREST_NOT_FOUND
      ) {
        navigate('/entry/creativeinterest');
      }
    }
  };
  useEffect(() => {
    popularInterestsGet();
    myInterestsGet();
  }, []);

  if (status === 'loading') {
    return '';
  }

  return (
    <>
      {successMessageModal && (
        <SuccessMessageModal setSuccessMessageModal={setSuccessMessageModal} />
      )}
      <Helmet>
        <title>Creative Interest | Srijcon</title>
        <link
          rel="canonical"
          href={
            firstTimeLoading ? '/entry/creativeinterest' : '/creativeinterest'
          }
        />
        <meta
          name="description"
          // content="Srijcon - Connect with innovators, inventors, makers, creators and share your projects, innovations, creative works or STEM creations with the world."
          content="Srijcon connects creative and Innovative minds to come together and share inspiring creations and ideas to impact the world."
        />
      </Helmet>
      {width > breakpoint ? (
        <>
          {/* This code will work above 768px media width i.e. for desktop view*/}
          <div
            className={
              firstTimeLoading ? classes.mainDivFirstTime : classes.mainDiv
            }
          >
            <div className={classes.leftContainer}>
              <div className={classes.searchContainer}>
                <SearchInput
                  data={{ type: 'text' }}
                  width="100%"
                  display="flex"
                  onChange={(event) => onSearchHandler(event)}
                  placeholder={
                    myInterests.length > 1
                      ? `Add creative interests`
                      : `Add min. 3 creative interests`
                  }
                  value={value}
                />
              </div>
              <h1 className={classes.creativeInterestHeading}>
                {leftContainerHeading}
              </h1>
              <hr />
              <div
                className={
                  firstTimeLoading
                    ? classes.popularCreativeInterestsContainerFirstTime
                    : classes.popularCreativeInterestsContainer
                }
              >
                {allInterests.map((index) => (
                  <div
                    className={classes.creativeInterestCardContainer}
                    key={index.id}
                    onClick={() => {
                      if (!myTopInterest) {
                        setMyTopInterest(index);
                      } else if (myTopInterest?.id === index.id) {
                        RemoveTopInterestHandler();
                      } else if (
                        myInterests.some((option) => option.id === index.id)
                      ) {
                        setMyInterests(
                          myInterests.filter((option) => option.id !== index.id)
                        );
                      } else {
                        setMyInterests([...myInterests, index]);
                      }
                    }}
                  >
                    <p className={classes.creativeInterestCardText}>
                      {index.name}
                    </p>
                    <MyToolTip
                      title={
                        myInterests.some((option) => option.id === index.id) ||
                        myTopInterest?.id === index.id
                          ? ''
                          : 'Add to my interests'
                      }
                      placement="left"
                    >
                      <div className={classes.addcategoryIconDiv}>
                        {myInterests.some((option) => option.id === index.id) ||
                        myTopInterest?.id === index.id ? (
                          <BsFillCheckCircleFill
                            style={{ color: '#14d619' }}
                            className={classes.creativeCursorPointer}
                          />
                        ) : (
                          <FiPlusCircle
                            className={classes.creativeCursorPointer}
                          />
                        )}
                      </div>
                    </MyToolTip>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.rightContainer}>
              <div className={classes.avatarContainer}>
                <img
                  draggable="false"
                  className={classes.avatar}
                  alt="Profile Pic"
                  src={userProfileData.profile_pic}
                />
                <div className={classes.nameContainer}>
                  <h1 className={classes.creativeUserName}>
                    {shortenFileName(userProfileData.full_name, 20)}
                  </h1>
                  <p className={classes.creativeUserTopInterest}>
                    {myTopInterest ? myTopInterest.name : `My Top Interest`}
                  </p>
                  {/* <p className={classes.creativeUserTopInterest}>{userProfileData.top_interest}</p> */}
                </div>
              </div>
              <div className={classes.myCreativeInterestsContainer}>
                <h1 className={classes.creativeInterestHeading}>
                  MY CREATIVE INTERESTS
                </h1>
                <hr />
                <div
                  className={
                    firstTimeLoading
                      ? classes.interestsCardContainerFirstTime
                      : classes.interestsCardContainer
                  }
                >
                  <div>
                    {/* For Top Interests in desktop view */}
                    {myTopInterest ? (
                      <div className={classes.creativeInterestCardContainer}>
                        <p className={classes.creativeInterestCardText}>
                          {myTopInterest?.name}
                        </p>
                        <span className={classes.creativeMyInterestIcons}>
                          <MyToolTip title={'Top Interest'} placement="bottom">
                            <span style={{ display: 'flex' }}>
                              <FiZap
                                style={{ fontSize: '2rem', border: 'none' }}
                                fill="#d11414"
                                stroke="#d11414"
                                className={classes.creativeMyInterestZap}
                              />
                            </span>
                          </MyToolTip>
                          <MyToolTip title="Remove interest" placement="bottom">
                            <span style={{ display: 'flex' }}>
                              <FiXCircle
                                style={{ fontSize: '2rem', marginLeft: '1rem' }}
                                className={classes.creativeCursorPointer}
                                onClick={RemoveTopInterestHandler}
                              />
                            </span>
                          </MyToolTip>
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                    {/* For My Creative Interests in desktop view */}
                    {myInterests.map((index) => (
                      <div
                        className={classes.creativeInterestCardContainer}
                        key={index.id}
                      >
                        <p className={classes.creativeInterestCardText}>
                          {index.name}
                        </p>
                        <span className={classes.creativeMyInterestIcons}>
                          <MyToolTip
                            title={'Make Top Interest'}
                            placement="bottom"
                          >
                            <span style={{ display: 'flex' }}>
                              <FiZap
                                style={{
                                  fontSize: '2rem',
                                  border: 'none',
                                }}
                                className={classes.creativeMyInterestZap}
                                onClick={() => {
                                  setMyInterests([
                                    myTopInterest,
                                    ...myInterests.filter(
                                      (option) => option.id !== index.id
                                    ),
                                  ]);
                                  setMyTopInterest(index);
                                }}
                              />
                            </span>
                          </MyToolTip>
                          <MyToolTip title="Remove interest" placement="bottom">
                            <span style={{ display: 'flex' }}>
                              <FiXCircle
                                style={{ fontSize: '2rem', marginLeft: '1rem' }}
                                className={classes.creativeCursorPointer}
                                onClick={() => {
                                  setMyInterests(
                                    myInterests.filter(
                                      (option) => option.id !== index.id
                                    )
                                  );
                                }}
                              />
                            </span>
                          </MyToolTip>
                        </span>
                      </div>
                    ))}
                  </div>
                  <div>
                    {myInterests.length > 1 ? (
                      ''
                    ) : (
                      <h4
                        className={classes.creativeRightContainerBottomHeading}
                      >
                        Add min. 3 creative interests.
                      </h4>
                    )}
                  </div>
                </div>
                <hr style={{ margin: '0.5rem', marginTop: '0' }} />
                {myInterests.length > 1 &&
                !buttonDisabled &&
                myInterests.length < 20 ? (
                  <button
                    className={classes.creativeHomeBtnEnabled}
                    onClick={saveMyInterest}
                  >
                    Save
                  </button>
                ) : (
                  <button className={classes.creativeHomeBtnDisabled}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* This code will work below 768px media width i.e. for mobile and tablet view*/}
          <div
            className={
              firstTimeLoading
                ? classes.mainMobileDivFirstTime
                : classes.mainMobileDiv
            }
          >
            <div className={classes.avatarContainer}>
              <img
                draggable="false"
                className={classes.avatar}
                alt="Profile Pic"
                src={userProfileData.profile_pic}
              />
              <div className={classes.nameContainer}>
                <h1 className={classes.creativeUserName}>
                  {shortenFileName(userProfileData.full_name, 30)}
                </h1>
                <p className={classes.creativeUserTopInterest}>
                  {myTopInterest ? myTopInterest.name : `My Top Interest`}
                </p>
                {/* <p className={classes.creativeUserTopInterest}>{userProfileData.top_interest}</p> */}
              </div>
            </div>
            <div className={classes.searchMobileContainer}>
              <SearchInput
                data={{ type: 'text' }}
                width="100%"
                display="flex"
                onChange={(event) => onSearchHandler(event)}
                placeholder={
                  myInterests.length > 1
                    ? `Add creative interests`
                    : `Add min. 3 creative interests`
                }
                value={value}
              />
            </div>
            <div className={classes.mobileTabs}>
              <h1
                className={
                  popularInterestsTab
                    ? `${classes.creativeInterestMobileHeadingEnabled}`
                    : `${classes.creativeInterestMobileHeadingDisabled}`
                }
                onClick={popularInterestsTabSwitchHandler}
              >
                {leftContainerHeading}
              </h1>

              <div className={classes.vl}></div>

              <div style={{ display: 'flex' }}>
                <h1
                  className={
                    myInterestsTab
                      ? `${classes.creativeInterestMobileHeadingEnabled}`
                      : `${classes.creativeInterestMobileHeadingDisabled}`
                  }
                  onClick={myInterestsTabSwitchHandler}
                >
                  My Creative Interests
                </h1>
                {badge && (
                  <Badge
                    className={classes.beat}
                    color="error"
                    overlap="circular"
                    variant="dot"
                    invisible={false}
                    // style={{ marginLeft: '1rem' }}
                  ></Badge>
                )}
              </div>
            </div>
            <div
              className={
                firstTimeLoading
                  ? classes.interestsMobileContainerFirstTime
                  : classes.interestsMobileContainer
              }
            >
              {popularInterestsTab === true && myInterestsTab === false ? (
                <div
                  className={
                    firstTimeLoading
                      ? classes.popularCreativeInterestsMobileContainerFirstTime
                      : classes.popularCreativeInterestsMobileContainer
                  }
                >
                  {allInterests.map((index) => (
                    <div
                      className={classes.creativeInterestCardContainer}
                      key={index.id}
                      onClick={() => {
                        if (!myTopInterest) {
                          setMyTopInterest(index);
                          if (myInterestsTabClick) {
                            setBadge(false);
                          } else {
                            setBadge(true);
                          }
                        } else if (myTopInterest?.id === index.id) {
                          RemoveTopInterestHandler();
                        } else if (
                          myInterests.some((option) => option.id === index.id)
                        ) {
                          setMyInterests(
                            myInterests.filter(
                              (option) => option.id !== index.id
                            )
                          );
                        } else {
                          setMyInterests([...myInterests, index]);
                        }
                      }}
                    >
                      <p className={classes.creativeInterestCardText}>
                        {index.name}
                      </p>
                      <MyToolTip
                        title={
                          myInterests.some(
                            (option) => option.id === index.id
                          ) || myTopInterest?.id === index.id
                            ? ''
                            : 'Add to my interests'
                        }
                        placement="left"
                      >
                        <div className={classes.addcategoryIconDiv}>
                          {myInterests.some(
                            (option) => option.id === index.id
                          ) || myTopInterest?.id === index.id ? (
                            <BsFillCheckCircleFill
                              style={{ color: '#14d619' }}
                              className={classes.creativeCursorPointer}
                            />
                          ) : (
                            <FiPlusCircle
                              className={classes.creativeCursorPointer}
                            />
                          )}
                        </div>
                      </MyToolTip>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className={
                    firstTimeLoading
                      ? classes.myCreativeInterestsMobileContainerFirstTime
                      : classes.myCreativeInterestsMobileContainer
                  }
                >
                  <div>
                    {myTopInterest ? (
                      <div className={classes.creativeInterestCardContainer}>
                        <p className={classes.creativeInterestCardText}>
                          {myTopInterest?.name}
                        </p>
                        <h1>{popularInterestsTab}</h1>
                        {/* For Top Interests in Mobile view */}

                        <div className={classes.creativeMyInterestIcons}>
                          <MyToolTip title={'Top Interest'} placement="bottom">
                            <span style={{ display: 'flex' }}>
                              <FiZap
                                style={{ fontSize: '2rem', border: 'none' }}
                                fill="#d11414"
                                stroke="#d11414"
                                className={classes.creativeMyInterestZap}
                              />
                            </span>
                          </MyToolTip>
                          <MyToolTip title="Remove interest" placement="bottom">
                            <span style={{ display: 'flex' }}>
                              <FiXCircle
                                style={{ fontSize: '2rem', marginLeft: '1rem' }}
                                className={classes.creativeCursorPointer}
                                onClick={RemoveTopInterestHandler}
                              />
                            </span>
                          </MyToolTip>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {/* For My Interests in Mobile view */}
                    {myInterests.map((index) => (
                      <div
                        className={classes.creativeInterestCardContainer}
                        key={index.id}
                      >
                        <p className={classes.creativeInterestCardText}>
                          {index.name}
                        </p>
                        <span className={classes.creativeMyInterestIcons}>
                          <MyToolTip
                            title={'Make Top Interest'}
                            placement="bottom"
                          >
                            <span style={{ display: 'flex' }}>
                              <FiZap
                                style={{ fontSize: '2rem', border: 'none' }}
                                className={classes.creativeMyInterestZap}
                                onClick={() => {
                                  setMyInterests([
                                    myTopInterest,
                                    ...myInterests.filter(
                                      (option) => option.id !== index.id
                                    ),
                                  ]);
                                  setMyTopInterest(index);
                                }}
                              />
                            </span>
                          </MyToolTip>
                          <MyToolTip title="Remove interest" placement="bottom">
                            <span style={{ display: 'flex' }}>
                              <FiXCircle
                                style={{ fontSize: '2rem', marginLeft: '1rem' }}
                                className={classes.creativeCursorPointer}
                                onClick={() => {
                                  setMyInterests(
                                    myInterests.filter(
                                      (option) => option.id !== index.id
                                    )
                                  );
                                }}
                              />
                            </span>
                          </MyToolTip>
                        </span>
                      </div>
                    ))}
                  </div>
                  <div>
                    {myInterests.length > 1 ? (
                      ''
                    ) : (
                      <h4
                        className={classes.creativeRightContainerBottomHeading}
                      >
                        Add min. 3 creative interests.
                      </h4>
                    )}
                    <hr style={{ margin: '0.5rem', marginTop: '0' }} />
                  </div>
                </div>
              )}
            </div>

            {myInterests.length > 1 &&
            !buttonDisabled &&
            myInterests.length < 20 ? (
              <button
                className={classes.creativeHomeBtnEnabled}
                onClick={saveMyInterest}
              >
                Save
              </button>
            ) : (
              <button className={classes.creativeHomeBtnDisabled}>Save</button>
            )}
          </div>
        </>
      )}
    </>
  );
}
