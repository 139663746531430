import React, { useEffect, useState } from 'react';

// Material UI Stuff imported
import { Box, Grow, Modal } from '@mui/material';

// Stylesheet import
import classes from './FansAndFavourites.module.css';

// React icons
import { FiUserCheck, FiUserPlus, FiX } from 'react-icons/fi';

// Avatar component import from Material UI
import Avatar from '@mui/material/Avatar';

//MyToolTip Component import
import MyToolTip from '../../CommonComponents/MyToolTip';

// API Base URL imported
import API from '../../../Axios/axios';

// use-navigate imported
import { useNavigate } from 'react-router-dom';

// Redux dispatch to dispatch user credentials
import { useDispatch } from 'react-redux';

// To update fans count in user profile
import { updateFavouritesCount } from '../../../Store/userProfileSlice';
import { apiErrorCodes } from '../../../apiErrorCodes';

export default function Favourites(props) {
  const [open, setOpen] = useState(false);
  const [Favourites, setFavourites] = useState('');
  const [status, setStatus] = useState('loading');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  // Function to redirect to the profile of creator
  const creatorNameClickHandler = (profileId) => {
    navigate(`/accounts/${profileId}/profile/`);
  };

  // Function to remove that creator form the favorite list of the login user
  const removeFavouritesHandler = async (element) => {
    try {
      const response = await API.post(
        `${element.follow_url}`,
        {},
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      if (response.data.code === apiErrorCodes.SUCCESS) {
        const result = Favourites.map((option) => {
          if (option.user.id === element.id) {
            option.user.status = false;
          }
          return option;
        });
        setFavourites(result);
        // This will update the favorites count in the user's profile
        dispatch(updateFavouritesCount(response.data.message.total_favourites));
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };

  // Function to add that creator to the favorite list of the login user
  const addFavouritesHandler = async (element) => {
    try {
      const response = await API.post(
        `${element.follow_url}`,
        {},
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      if (response.data.code === apiErrorCodes.SUCCESS) {
        const result = Favourites.map((option) => {
          if (option.user.id === element.id) {
            option.user.status = true;
          }
          return option;
        });
        setFavourites(result);
        // This will update the favorites count in the user's profile
        dispatch(updateFavouritesCount(response.data.message.total_favourites));
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };

  useEffect(() => {
    // To get the list of favorites
    (async () => {
      try {
        const response = await API.get('/accounts/creator/favourites/', {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.data.code === apiErrorCodes.SUCCESS) {
          setFavourites(response.data.message);
          setStatus('Done');
          if (response.data.message.length > 0) {
            // This will only open the modal if the user have at least one fan
            setOpen(true);
          }
        }
      } catch (error) {
        if (
          error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    })();
  }, []);

  useEffect(() => {
    // This will close the modal if the user does not have any fans
    if (Favourites.length < 1) {
      setOpen(false);
    }
  }, [Favourites]);

  if (status === 'loading') {
    return '';
  }

  return (
    <>
      <Modal open={open} onClose={props.handleSubmit}>
        <div>
          <Grow in={true}>
            <Box className={classes.favouritesModal}>
              <Box className={classes.favouritesModalHeader}>
                <FiX className="cross-button" onClick={props.handleSubmit} />
                <h4 className={classes.favouritesModalHeading}>FAVOURITES</h4>
              </Box>

              <Box className={classes.favouritesModalBody}>
                {Favourites?.map((element) => (
                  <div className={classes.element} key={element.id}>
                    <Avatar
                      src={element.user.profile_pic}
                      className={classes.avatar}
                      onClick={() => creatorNameClickHandler(element.user.id)}
                      alt="avatar_img"
                    />
                    <div className={classes.description}>
                      <h4
                        className={`element-heading ${classes.profileName}`}
                        onClick={() => creatorNameClickHandler(element.user.id)}
                      >
                        {element.user.name}
                      </h4>
                      <p className="element-description">
                        {element.user.top_interest}
                      </p>
                    </div>

                    {element.user.status ? (
                      <MyToolTip title="Favourite" placement="left">
                        <div>
                          <FiUserCheck
                            onClick={() => {
                              removeFavouritesHandler(element.user);
                            }}
                            className={classes.fiUserCheck}
                          />
                        </div>
                      </MyToolTip>
                    ) : (
                      <MyToolTip title="Be a Fan" placement="left">
                        <div>
                          <FiUserPlus
                            onClick={() => {
                              addFavouritesHandler(element.user);
                            }}
                            className={classes.follow}
                          />
                        </div>
                      </MyToolTip>
                    )}
                  </div>
                ))}
              </Box>
            </Box>
          </Grow>
        </div>
      </Modal>
    </>
  );
}
