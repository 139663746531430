import React from "react";
import classes from './CustomDots.module.css'
const CustomDots = ({ onClick, active, carouselState }) => {
    const { currentSlide } = carouselState;
    return (
            <li>
                {/* <li style={{ background: active ? "grey" : "initial" }}> */}
                <button
                    className={active ? classes.active : classes.inactive}
                    onClick={() => onClick()}
                />
            </li>
    );
};

export default CustomDots;