import React from 'react';

// Stylesheet import
import classes from './TVPageLeft.module.css';

// Featured Creator component import
import FeaturedCreators from '../TVPageLeft/FeaturedCreators/FeaturedCreators';
import ConnectCreateCard from "../ConnectCreateCard/ConnectCreateCard";

function TVPageLeft() {
    return (
        <aside className={classes.left}>
            {/*<ConnectCreateCard/>*/}
            <FeaturedCreators/>
        </aside>
    );
}

export default TVPageLeft;
