import React from 'react';

import classes from './TVCardImage.module.css';

export default function TVCardImage({postData}) {
  const {image_field: {images}, aspect_ratio} = postData;
  return (<div className={classes.mainDiv}>
    <img
      draggable="false"
      src={images}
      alt="PlaceholderImg"
      style={{aspectRatio: `${1 / aspect_ratio}`}}
    />
  </div>);
}
