import React, { useEffect, useState } from 'react';

// Stylesheet import
import classes from './DeleteElement.module.css';

// Icon import from Feather icon
import { Trash as Delete } from 'react-feather';

// Collapse transition from Material UI
import { Collapse } from '@mui/material';

// API base URL
import API from '../../../../../Axios/axios';

import { postDelete } from '../../../../../Store/TVPagePostsSlice';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiErrorCodes } from '../../../../../apiErrorCodes';
import AcknowledgementModal from '../../../../Navbar/NavbarRight/ContentTypeModal/UploadContentModal/Utilities/AcknowledgementModal/AcknowledgementModal';
import { deleteMyContentPost } from '../../../../../Store/userProfileSlice';

// import DeletePostAcknowledgementModal from '../DeletePostAcknowledgementModal/DeletePostAcknowledgementModal';

function DeleteElement(props) {
  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isConfirmationActive, setConfirmationActive] = useState(false);

  const [acknowledgementModal, setAcknowledgementModal] = useState(false);

  const location = useLocation();
  const activateConfirmation = () => {
    setConfirmationActive(!isConfirmationActive);
  };

  const deactivateConfirmation = () => {
    setConfirmationActive(false);
  };
  const deletePostHandler = async () => {
    try {
      const response = await API.delete(`/posts/${props.postId}`, {
        headers: { Authorization: `token ${token}` },
      });
      if (response.data.code === apiErrorCodes.SUCCESS) {
        dispatch(postDelete(props.postId));
        dispatch(deleteMyContentPost(props.postId));
        if (location.pathname !== '/') {
          setAcknowledgementModal(true);
        }
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.UNAUTHORIZED_USER ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };

  return (
    <>
      {acknowledgementModal && (
        <AcknowledgementModal
          setAcknowledgementModal={setAcknowledgementModal}
          message="Post deleted succesfully."
        />
      )}
      <div className={classes.container}>
        <div className={classes.element} onClick={activateConfirmation}>
          <Delete className={classes.icon} />
          <p style={{ display: 'contents' }} className="element-heading">
            Delete
          </p>
        </div>
        <Collapse component="div" in={isConfirmationActive}>
          <div className={classes.confirmationContainer}>
            <p
              className={classes.cancelDelete}
              onClick={deactivateConfirmation}
            >
              No
            </p>
            <p className={classes.confirmDelete} onClick={deletePostHandler}>
              Yes
            </p>
          </div>
        </Collapse>
      </div>
    </>
  );
}

export default DeleteElement;
