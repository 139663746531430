import React from 'react';
import { Box, Grow, Modal } from '@mui/material';
import classes from './SuccessMessageModal.module.css';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../../Store/userProfileSlice';

export default function SuccessMessageModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  setTimeout(() => {
    props.setSuccessMessageModal(false);
    props.handleClose();
    navigate('/entry/signup');
    dispatch(logout());
  }, 2000);

  const handleClose = () => {
    props.setSuccessMessageModal(false);
    props.handleClose();
    navigate('/entry/signup');
    dispatch(logout());
  };

  return (
    <Modal open={true} onClose={handleClose}>
      <div>
        <Grow in={true}>
          <Box className={classes.SuccessMessageModal}>
            <Box className={classes.SuccessMessageModalBox}>
              <CheckCircleRoundedIcon className={classes.checkCircle} />
              <p className={classes.SuccessMessageModalText}>
                Your account has been successfully deleted.
              </p>
            </Box>
          </Box>
        </Grow>
      </div>
    </Modal>
  );
}
