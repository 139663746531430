const numberRoundOff = (num) => {
    function intlFormat(num) {
        return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    }
    if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
    if (num >= 10000) return intlFormat(num / 1000) + 'K';
    return intlFormat(num);

};

export default numberRoundOff;
