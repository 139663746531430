import React, { useState } from 'react';
import { Box, Grow, Modal, TextField } from '@mui/material';
import { FiX } from 'react-icons/fi';
import ThirdModal from '../FinalDeleteModal/ThirdModal';
import styled from 'styled-components';
import API from '../../../../../Axios/axios';
import { useForm } from 'react-hook-form';
import classes from './SecondModal.module.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiErrorCodes } from '../../../../../apiErrorCodes';

const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;
const SecondModal = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [thirdModalOpen, setThirdModalOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { userProfileData } = useSelector((state) => state.userProfileSlice);
  const [reasonError, setReasonError] = useState(false);

  // Stuff related to react hook form
  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onChange' });

  const handleThirdModalOpen = () => {
    setThirdModalOpen(true);
  };
  const handleModalClose = () => {
    setThirdModalOpen(false);
    props.ModalClose();
  };
  const token = localStorage.getItem('token');

  const onSubmit = async (e) => {
    if (!e.reason?.trim()) {
      setReasonError(true);
    } else {
      setButtonDisabled(true);

      const formData = new FormData();
      formData.append('reason', e.reason?.trim());

      try {
        const response = await API.post(
          `/accounts/${userProfileData.id}/account_close_reason/`,
          formData,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        if (response.data.code === apiErrorCodes.SUCCESS) {
          handleThirdModalOpen();
          setButtonDisabled(false);
        }
      } catch (error) {
        if (error.response.data.code === apiErrorCodes.REASON_MISSING) {
          setReasonError(true);
          setButtonDisabled(false);
        } else if (
          error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST ||
          error.response.data.code === apiErrorCodes.UNAUTHORIZED_USER
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    }
  };

  return (
    <>
      {thirdModalOpen ? (
        ''
      ) : (
        <Modal open={open} onClose={props.ModalClose}>
          <div>
            <Grow in={true}>
              <Box className={classes.secondModal}>
                <FiX className="cross-button" onClick={props.ModalClose} />
                <Box className={classes.secondModalMain}>
                  <Box className={classes.secondModalHeading}>
                    <p className={classes.secondModalTitle}>
                      It's sad to see you leave.
                      <br />
                      Please tell us your reason for leaving.
                    </p>
                  </Box>

                  <Box className={classes.secondModalBody}>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                      className={classes.secondModalFormField}
                    >
                      <StyledTextField
                        variant="outlined"
                        multiline
                        rows={7}
                        sx={{
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 },
                          width: '100%',
                          margin: '1rem 0',
                        }}
                        InputLabelProps={{
                          style: { fontSize: '1.4rem', color: '#6e6e6e' },
                        }}
                        InputProps={{
                          style: { fontSize: '1.4rem', fontWeight: 400 },
                        }}
                        inputProps={{ maxLength: 500 }}
                        {...register('reason', {
                          required: 'Required field',
                          onChange: (e) => {
                            if (getValues('reason').length > 0) {
                              setReasonError(false);
                            }
                          },
                        })}
                        error={!!errors?.reason}
                        helperText={
                          errors?.reason
                            ? errors.reason.message
                            : reasonError === true
                            ? 'Required field'
                            : null
                        }
                      />
                      <Box className={classes.proceedBtnBox}>
                        {!buttonDisabled ? (
                          <button
                            className={classes.proceedBtnEnabled}
                            type="submit"
                          >
                            Proceed
                          </button>
                        ) : (
                          <button
                            className={classes.proceedBtnDisabled}
                            type="button"
                          >
                            Proceed
                          </button>
                        )}
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Grow>
          </div>
        </Modal>
      )}
      {thirdModalOpen && <ThirdModal handleModalClose={handleModalClose} />}
    </>
  );
};

export default SecondModal;
