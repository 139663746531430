import React from 'react'
import classes from "./CustomArrow.module.css";

// Icon import from Feather icon
import { ChevronLeft, ChevronRight } from 'react-feather';

function LeftArrow({ onClick }) {
    return (
        <button className={classes.leftArrow} onClick={() => onClick()}>
            <ChevronLeft className={classes.chevron} />
        </button>
    )
}
function RightArrow({ onClick }) {
    return (
        <button className={classes.rightArrow} onClick={() => onClick()}>
            <ChevronRight className={classes.chevron} />
        </button>
    )
}

export { LeftArrow, RightArrow };