import React from 'react';
import {Box, Grow, Modal} from '@mui/material';
import {FiX} from 'react-icons/fi';
import style from './UnsupportedFileModal.module.css';

const errorTypes = Object.freeze({
  UNSUPPORTED_ATTACHMENT_TYPE: 'Invalid file type. Only doc, docx, rtf, xls, xlsx, ppt, pptx, pdf and txt files are allowed.',
  UNSUPPORTED_FILE_TYPE: 'Invalid file type. Only JPG, JPEG, PNG, .WEBP, .MKV, .MP4 and .MOV files are allowed.',
  UNSUPPORTED_FILE_NAME: 'Media file names should be no longer than 1000 characters.',
});

export default function UnsupportedFileModal(props) {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     props.setUnsupportedFileModal(false);
  //   }, 2000);
  //
  //   return () => clearTimeout(timer);
  // }, [props.setUnsupportedFileModal]);

  let error = errorTypes[props.errorType] || '';

  return (<>
    <Modal open={true} onClose={() => props.setUnsupportedFileModal(false)}>
      <div>
        <Grow in={true}>
          <Box className={style.unsupportedFileModal}>
            <FiX
              className="cross-button"
              onClick={() => props.setUnsupportedFileModal(false)}
            />
            <Box className={style.unsupportedFileModalBox}>
              <p className={style.unsupportedFileModalErrorText}>{error}</p>
            </Box>
          </Box>
        </Grow>
      </div>
    </Modal>
  </>);
}