export const countCharacters = (fileName) => {
  if (fileName.length === 0) {
    return false;
  }
  let fileNameWithoutExtension = '',
    countCharactersInExtension = 0;
  for (let i = fileName.length - 1; i >= 0; i--) {
    countCharactersInExtension++;
    if (fileName[i] === '.') {
      break;
    }
  }
  fileNameWithoutExtension = fileName.substring(
    0,
    fileName.length - countCharactersInExtension
  );
  return fileNameWithoutExtension.length;
};
