import React from 'react';
import {Box, Grow, Modal} from '@mui/material';
import classes from './AcknowledgementModal.module.css';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {useNavigate} from 'react-router-dom';

export default function AcknowledgementModal(props) {
  const navigate = useNavigate();
  setTimeout(() => {
    props.setAcknowledgementModal(false);
    navigate('/');
    props.onClose(false);
  }, 2000);

  return (<Modal open={true} onClose={() => navigate('/')}>
    <div>
      <Grow in={true}>
        <Box className={classes.acknowledgementModal}>
          <Box className={classes.acknowledgementModalBox}>
            <CheckCircleRoundedIcon className={classes.checkCircle}/>
            <p className={classes.acknowledgementModalText}>
              {props.message}
            </p>
          </Box>
        </Box>
      </Grow>
    </div>
  </Modal>);
}
