// Formats a date object to "DD MMM YYYY" format

const formatDate = (actionTimestamp) => {
  const date = new Date(actionTimestamp);
  // An array of month abbreviations
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Extract the day, month, and year components of the date
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Combine the components into a formatted date string and return it
  return `${day} ${months[monthIndex]} ${year}`;
};

export default formatDate;
