import React, { useState } from 'react';
import { Collapse } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import DiscussElement from '../DiscussElement/DiscussElement';
import { useSelector } from 'react-redux';
import classes from './DiscussionPanel.module.css';

function DiscussionPanel({ postData, postType }) {
  const { id } = postData;
  const [isReplyActive, setIsReplyActive] = useState(false);
  const { postsData } = useSelector((state) => state.TVPagePostsSlice);
  const { singlePostData } = useSelector((state) => state.singlePostSlice);

  const toggleIsActiveReply = () => {
    setIsReplyActive(!isReplyActive);
  };

  // Determine the discussion array based on postType
  const discussions =
    postType === 'single'
      ? singlePostData.comment.list
      : postsData.results.filter((option) => option.id === id)[0]?.comment.list;

  return (
    <TransitionGroup>
      {/* Iterate over the discussions and render DiscussElement component */}
      {discussions.map((discuss) => (
        <Collapse key={discuss.id}>
          <div className={classes.discussContainer}>
            <DiscussElement
              postType={postType}
              data={discuss}
              toggleIsActiveReply={toggleIsActiveReply}
              isReplyActive={isReplyActive}
            />
          </div>
        </Collapse>
      ))}
    </TransitionGroup>
  );
}

export default DiscussionPanel;
