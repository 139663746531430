import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../Axios/axios';
import { apiErrorCodes } from '../apiErrorCodes';

export const TV_PAGE_POSTS_GET_STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});
export const SEARCH_POSTS_GET_STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

export const EVENT_POSTS_GET_STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});
export const DISCUSSION_DATA_GET_STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});
export const TVPagePostsSlice = createSlice({
  name: 'TVPagePosts',
  initialState: {
    postsData: [],
    TVPagePostsGetStatus: TV_PAGE_POSTS_GET_STATUSES.IDLE,
    searchPostsGetStatus: SEARCH_POSTS_GET_STATUSES.IDLE,
    eventPostsGetStatus: EVENT_POSTS_GET_STATUSES.IDLE,
    discussionDataGetStatus: DISCUSSION_DATA_GET_STATUSES.IDLE,
  },
  reducers: {
    // Delete the selected post from the list of posts in TV Page
    postDelete: (state, action) => {
      const idsToDelete = action.payload;
      state.postsData.results = state.postsData.results.filter(
        (post) => !idsToDelete.includes(post.id)
      );
    },
    postUpload: (state, action) => {
      const newPost = action.payload;
      const updatedResults = [newPost, ...state.postsData.results];
      const updatedPostsData = { ...state.postsData, results: updatedResults };
      return { ...state, postsData: updatedPostsData };
    },
    newPagePostsAdd: (state, action) => {
      const newPagePosts = action.payload.results;
      const newURL = action.payload.urls;
      const updatedResults = [...state.postsData.results, ...newPagePosts];
      const updatedPostsData = {
        ...state.postsData,
        urls: newURL,
        results: updatedResults,
      };
      return { ...state, postsData: updatedPostsData };
    },

    // This will open the discussion panel
    openDiscussionPanel: (state, action) => {
      state.postsData.results.map((option) => {
        if (option.id === action.payload) {
          option.comment.isOpenDiscussPanel = true;
        }
      });
    },

    // This will close the discussion panel
    closeDiscussionPanel: (state, action) => {
      state.postsData.results.map((option) => {
        if (option.id === action.payload) {
          option.comment.isOpenDiscussPanel = false;
        }
      });
      state.postsData.results.map((option) => {
        if (option.id === action.payload) {
          option.comment.list = [];
        }
      });
    },

    // It will update the discussion count on TV Card
    discussionCountUpdate: (state, action) => {
      const { postId, count } = action.payload;
      state.postsData.results.map((option) => {
        if (option.id === postId) {
          option.comment.total = count;
        }
      });
    },

    // This will add a new discussion at bottom and update the discussion count on TV Card
    addDiscussion: (state, action) => {
      const { post_id, total_comment, comment } = action.payload;
      state.postsData.results.map((option) => {
        if (option.id === post_id) {
          option.comment.list.push(comment);
          option.comment.total = total_comment;
        }
      });
    },
    // This will remove the discussion and update the discussion count on TV Card
    removeDiscussion: (state, action) => {
      const { id, post_id, total_comment } = action.payload;
      const postIndex = state.postsData.results.findIndex(
        (post) => post.id === post_id
      );
      if (postIndex !== -1) {
        const commentList = state.postsData.results[postIndex].comment.list;
        const commentIndex = commentList.findIndex(
          (comment) => comment.id === id
        );
        if (commentIndex !== -1) {
          commentList.splice(commentIndex, 1);
          state.postsData.results[postIndex].comment.list = commentList;
          state.postsData.results[postIndex].comment.total = total_comment;
        }
      }
    },

    // This will update the reactions on TV Card
    reactionUpdate: (state, action) => {
      const { post_id, reaction_score } = action.payload;
      state.postsData.results.map((option) => {
        if (option.id === post_id) {
          option.reaction_score = reaction_score;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      // For TV Post Data Fetching
      .addCase(TVPagePostsGet.pending, (state, action) => {
        state.TVPagePostsGetStatus = TV_PAGE_POSTS_GET_STATUSES.LOADING;
      })
      .addCase(TVPagePostsGet.fulfilled, (state, action) => {
        state.postsData = action.payload;
        state.TVPagePostsGetStatus = TV_PAGE_POSTS_GET_STATUSES.IDLE;
      })
      .addCase(TVPagePostsGet.rejected, (state, action) => {
        state.TVPagePostsGetStatus = TV_PAGE_POSTS_GET_STATUSES.ERROR;
      })
      // For search posts Fetching
      .addCase(searchPostsGet.pending, (state, action) => {
        state.searchPostsGetStatus = SEARCH_POSTS_GET_STATUSES.LOADING;
      })
      .addCase(searchPostsGet.fulfilled, (state, action) => {
        state.postsData = action.payload;
        state.searchPostsGetStatus = SEARCH_POSTS_GET_STATUSES.IDLE;
      })
      .addCase(searchPostsGet.rejected, (state, action) => {
        state.searchPostsGetStatus = SEARCH_POSTS_GET_STATUSES.ERROR;
      })

      // For event posts Fetching
      .addCase(eventPostsGet.pending, (state, action) => {
        state.eventPostsGetStatus = EVENT_POSTS_GET_STATUSES.LOADING;
      })
      .addCase(eventPostsGet.fulfilled, (state, action) => {
        state.postsData = action.payload;
        state.eventPostsGetStatus = EVENT_POSTS_GET_STATUSES.IDLE;
      })
      .addCase(eventPostsGet.rejected, (state, action) => {
        state.eventPostsGetStatus = EVENT_POSTS_GET_STATUSES.ERROR;
      })

      // For posts' discussion Fetching
      .addCase(discussionDataGet.pending, (state, action) => {
        state.eventPostsGetStatus = DISCUSSION_DATA_GET_STATUSES.LOADING;
      })
      .addCase(discussionDataGet.fulfilled, (state, action) => {
        const { post_id, list } = action.payload;
        state.postsData.results.map((option) => {
          if (option.id === post_id) {
            option.comment.list = list;
          }
        });
        state.eventPostsGetStatus = DISCUSSION_DATA_GET_STATUSES.IDLE;
      })
      .addCase(discussionDataGet.rejected, (state, action) => {
        state.eventPostsGetStatus = DISCUSSION_DATA_GET_STATUSES.ERROR;
      });
  },
});

export const {
  postDelete,
  postUpload,
  openDiscussionPanel,
  closeDiscussionPanel,
  discussionCountUpdate,
  newPagePostsAdd,
  addDiscussion,
  removeDiscussion,
  reactionUpdate,
} = TVPagePostsSlice.actions;
export default TVPagePostsSlice.reducer;

// Thunk for TV Card Data Fetching
export const TVPagePostsGet = createAsyncThunk(
  'TVPagePostsGet',
  async (thunkAPI) => {
    try {
      const response = await API.get('/posts/', {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      if (response.data.code === apiErrorCodes.SUCCESS) {
        return response.data.message;
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Thunk for TV Card Data Fetching using search bar
export const searchPostsGet = createAsyncThunk(
  'searchPostsGet',
  async (data, thunkAPI) => {
    try {
      const response = await API.get('/posts/post-search', {
        params: {
          search: data,
        },
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });

      if (response.data.code === apiErrorCodes.SUCCESS) {
        return response.data.message;
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
// Thunk for TV Card Data Fetching using search bar
export const eventPostsGet = createAsyncThunk(
  'eventPostsGet',
  async (data, thunkAPI) => {
    try {
      const response = await API.get('/posts/', {
        params: {
          event: data,
        },
      });
      if (response.data.code === apiErrorCodes.SUCCESS) {
        return response.data.message;
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const discussionDataGet = createAsyncThunk(
  'discussionDataGet',
  async (commentListURL, thunkAPI) => {
    try {
      const response = await API.get(`${commentListURL}`, {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      return response.data;
    } catch (e) {
      if (e.response.data.message === 'Invalid token.') {
        localStorage.removeItem('token');
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
