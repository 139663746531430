import React, { useState } from 'react';

// useDispatch hook import
import { useSelector } from 'react-redux';

// Stylesheet import
import classes from './DiscussElement.module.css';

// Icons import from Feather icon
import { MoreVertical } from 'react-feather';

// Click away lister import from Material UI
import { ClickAwayListener } from '@mui/material';

// Import discuss Dropdown
import Dropdown from '../Dropdown';

// calcTimeDifference helper function import
import calcTimeDifference from '../../../../../HelperFunctions/calcTimeDifference';

// imported for animation
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';

// API base URL imported
import API from '../../../../../Axios/axios';
import { useNavigate } from 'react-router-dom';
import shortenFileName from "../../../../../HelperFunctions/shortenFileName";

function Replies({ data, isReply, postType }) {
  const navigate = useNavigate();
  const { userProfileData } = useSelector((state) => state.userProfileSlice);
  const [isDropdownActive, setDropdownActive] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isFlagged, setIsFlagged] = useState(false);

  const toggleDropdown = async () => {
    if (!isDropdownActive) {
      try {
        const response = await API.get(`${data.urls.dropdown}`, {
          headers: { Authorization: `token ${localStorage.getItem('token')}` },
        });
        setDropdownActive(!isDropdownActive);
        if (response.data.is_author) {
          setIsAuthor(response.data.is_author);
        } else if (response.data.is_flagged) {
          setIsFlagged(response.data.is_flagged);
        }
      } catch (e) {
        if (e.response.data.message === 'Invalid token.') {
          localStorage.removeItem('token');
        }
      }
    } else {
      setDropdownActive(!isDropdownActive);
    }
  };

  const deactivateDropdown = () => {
    setDropdownActive(false);
  };

  const creatorNameClickHandler = (author_id, profileURL) => {
    if (author_id === userProfileData.id) {
      navigate(`/userprofile`);
    } else {
      navigate(profileURL);
    }
  };

  return (
    <TransitionGroup>
      <Collapse in={true}>
        <div key={data.id} className={`${isReply ? classes.reply : ''}`}>
          <div className={classes.header}>
            <h4
              style={{ cursor: 'pointer' }}
              className="element-heading"
              onClick={() =>
                creatorNameClickHandler(data.author_id, data.urls.profile)
              }
            >
              {shortenFileName(data.name, 35)}
            </h4>
            <p className={`element-description ${classes.time}`}>
              {calcTimeDifference(data.time)}
            </p>
            {/*<Reply className={classes.replyIcon} onClick={replyHandler} />*/}
            <ClickAwayListener onClickAway={deactivateDropdown}>
              <div className={classes.dropdownContainer}>
                <MoreVertical
                  className={classes.more}
                  onClick={toggleDropdown}
                />
                {isDropdownActive && (
                  <Dropdown
                    postType={postType}
                    data={data}
                    isAuthor={isAuthor}
                    isFlagged={isFlagged}
                    setDropdownActive={setDropdownActive}
                  />
                )}
              </div>
            </ClickAwayListener>
          </div>
          <p className={`description-text ${classes.discussText}`}>
            {data.repliedTo ? (
              <span className={classes.repliedTo}>{`@${data.repliedTo}`}</span>
            ) : (
              ''
            )}{' '}
            {data.discuss}
          </p>
        </div>
      </Collapse>
    </TransitionGroup>
  );
}

export default Replies;
