import React from 'react';

// Stylesheet import
import classes from './TVPageRight.module.css';
// Featured Creator component import
import Card from '../../CommonComponents/Card/Card';

function TVPageRight() {
  return (
    <aside className={classes.right}>
      <div className={classes.xyz}>
        <Card>
          <div className={classes.rightDiv}>
            <h1 className='component-heading'>Connect. Create. Impact</h1>
            <p style={{ marginTop: '1rem' }} className='element-description'> Share your inspiring creations, cool projects, innovations or crazy inventions with the world.</p>
            {/* <p style={{ marginTop: '1rem' }} className='element-description'>Every individual human being has an innate creative potential and it's our responsibility to ignite that creative spark in everyone.</p> */}
          </div>
        </Card>
      </div>
    </aside>
  );
}

export default TVPageRight;
