import React, { useState } from 'react';
import { Box, Grow, Modal, TextField } from '@mui/material';
import { FiX } from 'react-icons/fi';
import classes from './Helpdesk.module.css';
import API from '../../../../Axios/axios';
import SuccessMessageModal from './SuccessMessageModal/SuccessMessageModal';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { apiErrorCodes } from '../../../../apiErrorCodes';

const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;

export default function Helpdesk(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [queryError, setQueryError] = useState(false);
  const [successMessageModal, setSuccessMessageModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const token = localStorage.getItem('token');

  // Stuff related to react hook form
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const handleClose = () => {
    setOpen(false);
    props.handleModal();
  };
  const onSubmit = async (e) => {
    if (!e.query?.trim()) {
      setQueryError(true);
    } else {
      setButtonDisabled(true);

      const formData = new FormData();
      formData.append('query', e.query?.trim());

      try {
        const response = await API.post(
          `${
            props.typeOfModel === 'question'
              ? '/core/help_query/'
              : '/accounts/feedback/'
          }`,
          formData,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        if (response.data.code === apiErrorCodes.SUCCESS) {
          setOpen(false);
          setButtonDisabled(false);
          setSuccessMessageModal(true);
        }
      } catch (error) {
        if (error.response.data.code === apiErrorCodes.QUERY_MISSING) {
          setButtonDisabled(false);
          setQueryError(true);
        } else if (
          error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    }
  };

  return (
    <>
      {successMessageModal && (
        <SuccessMessageModal
          typeOfModel={props.typeOfModel}
          setSuccessMessageModal={setSuccessMessageModal}
          handleClose={handleClose}
        />
      )}
      {successMessageModal ? (
        ''
      ) : (
        <div>
          <Modal open={open} onClose={handleClose}>
            <div>
              <Grow in={true}>
                <Box className={classes.helpdeskModal}>
                  <FiX className="cross-button" onClick={handleClose} />
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                    className={classes.formContainer}
                  >
                    <p
                      className={classes.helpdeskModalTitle}
                    >{`Please enter your ${props.typeOfModel}...`}</p>
                    <StyledTextField
                      variant="outlined"
                      multiline
                      rows={7}
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        width: '100%',
                      }}
                      InputLabelProps={{
                        style: { fontSize: '1.4rem', color: '#6e6e6e' },
                      }}
                      InputProps={{
                        style: { fontSize: '1.4rem', fontWeight: 400 },
                      }}
                      inputProps={{ maxLength: 500 }}
                      {...register('query', {
                        required: 'Required field',
                        onChange: (e) => {
                          if (getValues('query').length > 0) {
                            setQueryError(false);
                          }
                        },
                      })}
                      error={!!errors?.query}
                      helperText={
                        errors?.query
                          ? errors.query.message
                          : queryError === true
                          ? 'Required field'
                          : null
                      }
                    />
                    <Box className={classes.helpdeskSendButtonBox}>
                      {!buttonDisabled ? (
                        <button
                          type="submit"
                          className={classes.helpdeskSendButtonEnabled}
                        >
                          Send
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={classes.helpdeskSendButtonDisabled}
                        >
                          Send
                        </button>
                      )}
                    </Box>
                  </form>
                </Box>
              </Grow>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}
