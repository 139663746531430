// Website for county data
// https://www.html-code-generator.com/javascript/json/country-names

export const CountryList = [
    { name: "Afghanistan", code: "AF", number: 93 },
    { name: "Aland Islands", code: "AX", number: 358 },
    { name: "Albania", code: "AL", number: 355 },
    { name: "Algeria", code: "DZ", number: 213 },
    { name: "American Samoa", code: "AS", number: 1684 },
    { name: "Andorra", code: "AD", number: 376 },
    { name: "Angola", code: "AO", number: 244 },
    { name: "Anguilla", code: "AI", number: 1264 },
    { name: "Antarctica", code: "AQ", number: 672 },
    { name: "Antigua and Barbuda", code: "AG", number: 1268 },
    { name: "Argentina", code: "AR", number: 54 },
    { name: "Armenia", code: "AM", number: 374 },
    { name: "Aruba", code: "AW", number: 297 },
    { name: "Australia", code: "AU", number: 61 },
    { name: "Austria", code: "AT", number: 43 },
    { name: "Azerbaijan", code: "AZ", number: 994 },
    { name: "Bahamas", code: "BS", number: 1242 },
    { name: "Bahrain", code: "BH", number: 973 },
    { name: "Bangladesh", code: "BD", number: 880 },
    { name: "Barbados", code: "BB", number: 1246 },
    { name: "Belarus", code: "BY", number: 375 },
    { name: "Belgium", code: "BE", number: 32 },
    { name: "Belize", code: "BZ", number: 501 },
    { name: "Benin", code: "BJ", number: 229 },
    { name: "Bermuda", code: "BM", number: 1441 },
    { name: "Bhutan", code: "BT", number: 975 },
    { name: "Bolivia", code: "BO", number: 591 },
    { name: "Bonaire, Sint Eustatius and Saba", code: "BQ", number: 599 },
    { name: "Bosnia and Herzegovina", code: "BA", number: 387 },
    { name: "Botswana", code: "BW", number: 267 },
    { name: "Bouvet Island", code: "BV", number: 55 },
    { name: "Brazil", code: "BR", number: 55 },
    { name: "British Indian Ocean Territory", code: "IO", number: 246 },
    { name: "Brunei Darussalam", code: "BN", number: 673 },
    { name: "Bulgaria", code: "BG", number: 359 },
    { name: "Burkina Faso", code: "BF", number: 226 },
    { name: "Burundi", code: "BI", number: 257 },
    { name: "Cambodia", code: "KH", number: 855 },
    { name: "Cameroon", code: "CM", number: 237 },
    { name: "Canada", code: "CA", number: 1 },
    { name: "Cape Verde", code: "CV", number: 238 },
    { name: "Cayman Islands", code: "KY", number: 1345 },
    { name: "Central African Republic", code: "CF", number: 236 },
    { name: "Chad", code: "TD", number: 235 },
    { name: "Chile", code: "CL", number: 56 },
    { name: "China", code: "CN", number: 86 },
    { name: "Christmas Island", code: "CX", number: 61 },
    { name: "Cocos (Keeling) Islands", code: "CC", number: 672 },
    { name: "Colombia", code: "CO", number: 57 },
    { name: "Comoros", code: "KM", number: 269 },
    { name: "Congo", code: "CG", number: 242 },
    { name: "Congo, Democratic Republic of the Congo", code: "CD", number: 242 },
    { name: "Cook Islands", code: "CK", number: 682 },
    { name: "Costa Rica", code: "CR", number: 506 },
    { name: "Cote D'Ivoire", code: "CI", number: 225 },
    { name: "Croatia", code: "HR", number: 385 },
    { name: "Cuba", code: "CU", number: 53 },
    { name: "Curacao", code: "CW", number: 599 },
    { name: "Cyprus", code: "CY", number: 357 },
    { name: "Czech Republic", code: "CZ", number: 420 },
    { name: "Denmark", code: "DK", number: 45 },
    { name: "Djibouti", code: "DJ", number: 253 },
    { name: "Dominica", code: "DM", number: 1767 },
    { name: "Dominican Republic", code: "DO", number: 1809 },
    { name: "Ecuador", code: "EC", number: 593 },
    { name: "Egypt", code: "EG", number: 20 },
    { name: "El Salvador", code: "SV", number: 503 },
    { name: "Equatorial Guinea", code: "GQ", number: 240 },
    { name: "Eritrea", code: "ER", number: 291 },
    { name: "Estonia", code: "EE", number: 372 },
    { name: "Ethiopia", code: "ET", number: 251 },
    { name: "Falkland Islands (Malvinas)", code: "FK", number: 500 },
    { name: "Faroe Islands", code: "FO", number: 298 },
    { name: "Fiji", code: "FJ", number: 679 },
    { name: "Finland", code: "FI", number: 358 },
    { name: "France", code: "FR", number: 33 },
    { name: "French Guiana", code: "GF", number: 594 },
    { name: "French Polynesia", code: "PF", number: 689 },
    { name: "French Southern Territories", code: "TF", number: 262 },
    { name: "Gabon", code: "GA", number: 241 },
    { name: "Gambia", code: "GM", number: 220 },
    { name: "Georgia", code: "GE", number: 995 },
    { name: "Germany", code: "DE", number: 49 },
    { name: "Ghana", code: "GH", number: 233 },
    { name: "Gibraltar", code: "GI", number: 350 },
    { name: "Greece", code: "GR", number: 30 },
    { name: "Greenland", code: "GL", number: 299 },
    { name: "Grenada", code: "GD", number: 1473 },
    { name: "Guadeloupe", code: "GP", number: 590 },
    { name: "Guam", code: "GU", number: 1671 },
    { name: "Guatemala", code: "GT", number: 502 },
    { name: "Guernsey", code: "GG", number: 44 },
    { name: "Guinea", code: "GN", number: 224 },
    { name: "Guinea-Bissau", code: "GW", number: 245 },
    { name: "Guyana", code: "GY", number: 592 },
    { name: "Haiti", code: "HT", number: 509 },
    { name: "Heard Island and Mcdonald Islands", code: "HM", number: 0 },
    { name: "Holy See (Vatican City State)", code: "VA", number: 39 },
    { name: "Honduras", code: "HN", number: 504 },
    { name: "Hong Kong", code: "HK", number: 852 },
    { name: "Hungary", code: "HU", number: 36 },
    { name: "Iceland", code: "IS", number: 354 },
    { name: "India", code: "IN", number: 91 },
    { name: "Indonesia", code: "ID", number: 62 },
    { name: "Iran, Islamic Republic of", code: "IR", number: 98 },
    { name: "Iraq", code: "IQ", number: 964 },
    { name: "Ireland", code: "IE", number: 353 },
    { name: "Isle of Man", code: "IM", number: 44 },
    { name: "Israel", code: "IL", number: 972 },
    { name: "Italy", code: "IT", number: 39 },
    { name: "Jamaica", code: "JM", number: 1876 },
    { name: "Japan", code: "JP", number: 81 },
    { name: "Jersey", code: "JE", number: 44 },
    { name: "Jordan", code: "JO", number: 962 },
    { name: "Kazakhstan", code: "KZ", number: 7 },
    { name: "Kenya", code: "KE", number: 254 },
    { name: "Kiribati", code: "KI", number: 686 },
    { name: "Korea, Democratic People's Republic of", code: "KP", number: 850 },
    { name: "Korea, Republic of", code: "KR", number: 82 },
    { name: "Kosovo", code: "XK", number: 381 },
    { name: "Kuwait", code: "KW", number: 965 },
    { name: "Kyrgyzstan", code: "KG", number: 996 },
    { name: "Lao People's Democratic Republic", code: "LA", number: 856 },
    { name: "Latvia", code: "LV", number: 371 },
    { name: "Lebanon", code: "LB", number: 961 },
    { name: "Lesotho", code: "LS", number: 266 },
    { name: "Liberia", code: "LR", number: 231 },
    { name: "Libyan Arab Jamahiriya", code: "LY", number: 218 },
    { name: "Liechtenstein", code: "LI", number: 423 },
    { name: "Lithuania", code: "LT", number: 370 },
    { name: "Luxembourg", code: "LU", number: 352 },
    { name: "Macao", code: "MO", number: 853 },
    { name: "Macedonia, the Former Yugoslav Republic of", code: "MK", number: 389 },
    { name: "Madagascar", code: "MG", number: 261 },
    { name: "Malawi", code: "MW", number: 265 },
    { name: "Malaysia", code: "MY", number: 60 },
    { name: "Maldives", code: "MV", number: 960 },
    { name: "Mali", code: "ML", number: 223 },
    { name: "Malta", code: "MT", number: 356 },
    { name: "Marshall Islands", code: "MH", number: 692 },
    { name: "Martinique", code: "MQ", number: 596 },
    { name: "Mauritania", code: "MR", number: 222 },
    { name: "Mauritius", code: "MU", number: 230 },
    { name: "Mayotte", code: "YT", number: 262 },
    { name: "Mexico", code: "MX", number: 52 },
    { name: "Micronesia, Federated States of", code: "FM", number: 691 },
    { name: "Moldova, Republic of", code: "MD", number: 373 },
    { name: "Monaco", code: "MC", number: 377 },
    { name: "Mongolia", code: "MN", number: 976 },
    { name: "Montenegro", code: "ME", number: 382 },
    { name: "Montserrat", code: "MS", number: 1664 },
    { name: "Morocco", code: "MA", number: 212 },
    { name: "Mozambique", code: "MZ", number: 258 },
    { name: "Myanmar", code: "MM", number: 95 },
    { name: "Namibia", code: "NA", number: 264 },
    { name: "Nauru", code: "NR", number: 674 },
    { name: "Nepal", code: "NP", number: 977 },
    { name: "Netherlands", code: "NL", number: 31 },
    { name: "Netherlands Antilles", code: "AN", number: 599 },
    { name: "New Caledonia", code: "NC", number: 687 },
    { name: "New Zealand", code: "NZ", number: 64 },
    { name: "Nicaragua", code: "NI", number: 505 },
    { name: "Niger", code: "NE", number: 227 },
    { name: "Nigeria", code: "NG", number: 234 },
    { name: "Niue", code: "NU", number: 683 },
    { name: "Norfolk Island", code: "NF", number: 672 },
    { name: "Northern Mariana Islands", code: "MP", number: 1670 },
    { name: "Norway", code: "NO", number: 47 },
    { name: "Oman", code: "OM", number: 968 },
    { name: "Pakistan", code: "PK", number: 92 },
    { name: "Palau", code: "PW", number: 680 },
    { name: "Palestinian Territory, Occupied", code: "PS", number: 970 },
    { name: "Panama", code: "PA", number: 507 },
    { name: "Papua New Guinea", code: "PG", number: 675 },
    { name: "Paraguay", code: "PY", number: 595 },
    { name: "Peru", code: "PE", number: 51 },
    { name: "Philippines", code: "PH", number: 63 },
    { name: "Pitcairn", code: "PN", number: 64 },
    { name: "Poland", code: "PL", number: 48 },
    { name: "Portugal", code: "PT", number: 351 },
    { name: "Puerto Rico", code: "PR", number: 1787 },
    { name: "Qatar", code: "QA", number: 974 },
    { name: "Reunion", code: "RE", number: 262 },
    { name: "Romania", code: "RO", number: 40 },
    { name: "Russian Federation", code: "RU", number: 70 },
    { name: "Rwanda", code: "RW", number: 250 },
    { name: "Saint Barthelemy", code: "BL", number: 590 },
    { name: "Saint Helena", code: "SH", number: 290 },
    { name: "Saint Kitts and Nevis", code: "KN", number: 1869 },
    { name: "Saint Lucia", code: "LC", number: 1758 },
    { name: "Saint Martin", code: "MF", number: 590 },
    { name: "Saint Pierre and Miquelon", code: "PM", number: 508 },
    { name: "Saint Vincent and the Grenadines", code: "VC", number: 1784 },
    { name: "Samoa", code: "WS", number: 684 },
    { name: "San Marino", code: "SM", number: 378 },
    { name: "Sao Tome and Principe", code: "ST", number: 239 },
    { name: "Saudi Arabia", code: "SA", number: 966 },
    { name: "Senegal", code: "SN", number: 221 },
    { name: "Serbia", code: "RS", number: 381 },
    { name: "Serbia and Montenegro", code: "CS", number: 381 },
    { name: "Seychelles", code: "SC", number: 248 },
    { name: "Sierra Leone", code: "SL", number: 232 },
    { name: "Singapore", code: "SG", number: 65 },
    { name: "Sint Maarten", code: "SX", number: 1 },
    { name: "Slovakia", code: "SK", number: 421 },
    { name: "Slovenia", code: "SI", number: 386 },
    { name: "Solomon Islands", code: "SB", number: 677 },
    { name: "Somalia", code: "SO", number: 252 },
    { name: "South Africa", code: "ZA", number: 27 },
    { name: "South Georgia and the South Sandwich Islands", code: "GS", number: 500 },
    { name: "South Sudan", code: "SS", number: 211 },
    { name: "Spain", code: "ES", number: 34 },
    { name: "Sri Lanka", code: "LK", number: 94 },
    { name: "Sudan", code: "SD", number: 249 },
    { name: "Suriname", code: "SR", number: 597 },
    { name: "Svalbard and Jan Mayen", code: "SJ", number: 47 },
    { name: "Swaziland", code: "SZ", number: 268 },
    { name: "Sweden", code: "SE", number: 46 },
    { name: "Switzerland", code: "CH", number: 41 },
    { name: "Syrian Arab Republic", code: "SY", number: 963 },
    { name: "Taiwan, Province of China", code: "TW", number: 886 },
    { name: "Tajikistan", code: "TJ", number: 992 },
    { name: "Tanzania, United Republic of", code: "TZ", number: 255 },
    { name: "Thailand", code: "TH", number: 66 },
    { name: "Timor-Leste", code: "TL", number: 670 },
    { name: "Togo", code: "TG", number: 228 },
    { name: "Tokelau", code: "TK", number: 690 },
    { name: "Tonga", code: "TO", number: 676 },
    { name: "Trinidad and Tobago", code: "TT", number: 1868 },
    { name: "Tunisia", code: "TN", number: 216 },
    { name: "Turkey", code: "TR", number: 90 },
    { name: "Turkmenistan", code: "TM", number: 7370 },
    { name: "Turks and Caicos Islands", code: "TC", number: 1649 },
    { name: "Tuvalu", code: "TV", number: 688 },
    { name: "Uganda", code: "UG", number: 256 },
    { name: "Ukraine", code: "UA", number: 380 },
    { name: "United Arab Emirates", code: "AE", number: 971 },
    { name: "United Kingdom", code: "GB", number: 44 },
    { name: "United States", code: "US", number: 1 },
    { name: "United States Minor Outlying Islands", code: "UM", number: 1 },
    { name: "Uruguay", code: "UY", number: 598 },
    { name: "Uzbekistan", code: "UZ", number: 998 },
    { name: "Vanuatu", code: "VU", number: 678 },
    { name: "Venezuela", code: "VE", number: 58 },
    { name: "Viet Nam", code: "VN", number: 84 },
    { name: "Virgin Islands, British", code: "VG", number: 1284 },
    { name: "Virgin Islands, U.s.", code: "VI", number: 1340 },
    { name: "Wallis and Futuna", code: "WF", number: 681 },
    { name: "Western Sahara", code: "EH", number: 212 },
    { name: "Yemen", code: "YE", number: 967 },
    { name: "Zambia", code: "ZM", number: 260 },
    { name: "Zimbabwe", code: "ZW", number: 263 }
];
