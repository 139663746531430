import React, { useState } from 'react';

// Stylesheet import
import classes from './TVCardHeader.module.css';

import { MoreVertical as MoreIcon } from 'react-feather';

// Avatar Component import from Material UI
import Avatar from '@mui/material/Avatar';

// Click Away Listener import from Material UI
import ClickAwayListener from '@mui/material/ClickAwayListener';

// Dropdown Element import
import TVCardDropdown from '../TVCardDropdown/TVCardDropdown';

import { useNavigate } from 'react-router-dom';

// Redux dispatch to dispatch user credentials
import { useSelector } from 'react-redux';

// API base URL
import API from '../../../../Axios/axios';

export default function TVCardHeader({ postData }) {
  // Destructuring postData props
  const { id, project_title, content_type, creator } = postData;
  const navigate = useNavigate();
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const token = localStorage.getItem('token');
  const { userProfileData } = useSelector((state) => state.userProfileSlice);
  const [postSaved, setPostSaved] = useState(false);
  const [postFlagged, setPostFlagged] = useState(false);

  const moreClickHandler = async () => {
    if (token) {
      try {
        const response = await API.get(`/posts/${id}/dropdown`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        if (response.data.saved === true) {
          setPostSaved(true);
        } else if (response.data.saved === false) {
          setPostSaved(false);
        }
        if (response.data.flagged === true) {
          setPostFlagged(true);
        } else if (response.data.flagged === false) {
          setPostFlagged(false);
        }
        setIsDropdownActive(!isDropdownActive);
      } catch (e) {
        if (e.response.data.message === 'Invalid token.') {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    } else {
      setIsDropdownActive(!isDropdownActive);
    }
  };

  const clickAwayHandler = () => {
    setIsDropdownActive(false);
  };
  const creatorNameClickHandler = (profileId) => {
    if (profileId === userProfileData.id) {
      navigate(`/userprofile`);
    } else {
      navigate(`/accounts/${profileId}/profile/`);
    }
  };

  const displayOutput = (data, characters) => {
    if (data.length <= characters) {
      return data;
    }
    return data.substring(0, characters) + '...';
  };

  return (
    <>
      <div className={classes.header}>
        <Avatar
          src={creator.profile_pic}
          className={classes.avatar}
          onClick={() => creatorNameClickHandler(creator.id)}
          alt="Profile_pic"
        />
        <div className={classes.about}>
          <h2
            className={classes.name}
            onClick={() => creatorNameClickHandler(creator.id)}
          >
            {displayOutput(creator.name, 30)}
          </h2>
          <p className={classes.contentType}>
            {content_type}
            {content_type === 'Project'
              ? `: ${displayOutput(project_title, 45)}`
              : ''}
          </p>
        </div>
        <ClickAwayListener onClickAway={clickAwayHandler}>
          <div className={classes.moreContainer}>
            <MoreIcon className={classes.moreIcon} onClick={moreClickHandler} />
            {isDropdownActive && (
              <TVCardDropdown
                postData={postData}
                postSaved={postSaved}
                setPostSaved={setPostSaved}
                postFlagged={postFlagged}
                setPostFlagged={setPostFlagged}
              />
            )}
          </div>
        </ClickAwayListener>
      </div>
    </>
  );
}
