import React, { useState } from 'react';

// MUI components imported
import {
  Box,
  Grow,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from '@mui/material';

// Icons imported
import { FiLock, FiUnlock, FiX } from 'react-icons/fi';

// Styled component imported
import styled from 'styled-components';

// react-hook-form imported for form validation
import { useForm } from 'react-hook-form';

// API base addresses imported
import API from '../../../../Axios/axios';

// Stylesheets imported
import classes from './EditPassword.module.css';

// Components
import SuccessMessageModal from './SuccessMessageModal/SuccessMessageModal';

import { useNavigate } from 'react-router-dom';
import { apiErrorCodes } from '../../../../apiErrorCodes';
import { useSelector } from 'react-redux';

// MUI text-field with styled components for customization
const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;

export default function EditPassword(props) {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  // Boolean value for opening current modal
  const [open, setOpen] = useState(true);

  // Boolean value for opening and closing the success message modal
  const [successMessageModal, setSuccessMessageModal] = useState(false);

  //  Boolean value to trigger the icon for showing and hiding the new password
  const [showNewPassword, setShowNewPassword] = useState(false);

  // boolean value to disable and enable buttons
  const [buttonEnabled, setButtonEnabled] = useState(true);

  // function to set the boolean value to show and hide the new password
  const handleClickShowPassword = () => setShowNewPassword(!showNewPassword);

  const { social_login_type } = useSelector((state) => state.userProfileSlice.userProfileData);

  // react-hook-form stuff
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  // form submission function
  const onSubmit = async (data) => {
    // this will disable the button until the response comes from API server
    setButtonEnabled(false);
    const formData = new FormData();
    formData.append('current_password', data.currentPassword);
    formData.append('new_password', data.newPassword);

    try {
      const response = await API.post('/accounts/changepassword', formData, {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      if (response.data.code === apiErrorCodes.SUCCESS) {
        // this will enable the button and open the acknowledgment modal
        setSuccessMessageModal(true);
        setButtonEnabled(true);
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        // this will remove the token from the localstorage and redirect to the sign-in page if token expired
        localStorage.removeItem('token');
        navigate('/entry/signin');
      } else if (
        error.response.data.code === apiErrorCodes.WRONG_CURRENT_PASSWORD
      ) {
        // this will show the error in current password and enables the button
        setError('currentPassword', {
          type: 'manual',
          message: 'Incorrect password.',
        });
        setButtonEnabled(true);
      } else if (
        error.response.data.code ===
        apiErrorCodes.CURRENT_NEW_PASSWORD_CANNOT_BE_SAME
      ) {
        // this will show the error in new password and enables the button
        setError('newPassword', {
          type: 'manual',
          message: 'New password cannot be same as current Password.',
        });
        setButtonEnabled(true);
      }
    }
  };

  // function for closing the current modal
  const handleClose = () => {
    setOpen(false);
    props.handleModal();
  };
  return (
    <>
      {successMessageModal && (
        <SuccessMessageModal
          setSuccessMessageModal={setSuccessMessageModal}
          handleClose={handleClose}
        />
      )}
      {successMessageModal ? (
        ''
      ) : (
        <div>
          <Modal open={open} onClose={handleClose}>
            <div>
              <Grow in={true}>
                {social_login_type === 'email' ?
                  <Box className={classes.editPasswordModal}>
                    <FiX onClick={handleClose} className="cross-button" />
                    <Box className={classes.box}>
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                        className={classes.formEditPassword}
                      >
                        <StyledTextField
                          label="Current password"
                          variant="outlined"
                          type="password"
                          sx={{ width: '80%', margin: '1rem 0' }}
                          InputLabelProps={{
                            style: { fontSize: 14, color: '#6e6e6e' },
                          }}
                          InputProps={{
                            style: { fontSize: 14, fontWeight: 400 },
                          }}
                          {...register('currentPassword', {
                            required: 'Required field',
                          })}
                          error={!!errors?.currentPassword}
                          helperText={
                            errors?.currentPassword
                              ? errors.currentPassword.message
                              : null
                          }
                        />

                        <StyledTextField
                          label="New password"
                          variant="outlined"
                          type={showNewPassword ? 'text' : 'password'} // <-- This is where the magic happens
                          sx={{ width: '80%', margin: '1rem 0' }}
                          InputLabelProps={{
                            style: { fontSize: 14, color: '#6e6e6e' },
                          }}
                          InputProps={{
                            style: { fontSize: 14, fontWeight: 400 },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                >
                                  {showNewPassword ? <FiUnlock /> : <FiLock />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...register('newPassword', {
                            required: 'Required field',
                            pattern: {
                              // Must have alphabets, Number and minimum 8 characters, but it can also contain special characters.
                              value:
                                /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9!#$%&'*+-/=?^_`{|@.]{8,}$/,

                              // Must have Special Character, UpperCase, LowerCase, Number and minimum 8 characters.
                              // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                              message:
                                'Must be (a - z), (0 - 9) and minimum 8 characters.', // 'Must have Special Character, UpperCase, LowerCase, Number and minimum 8 characters.'
                            },
                          })}
                          error={!!errors?.newPassword}
                          helperText={
                            errors?.newPassword
                              ? errors.newPassword.message
                              : null
                          }
                        />
                        <Box className={classes.buttonUpdate}>
                          {buttonEnabled ? (
                            <button
                              type="submit"
                              className={classes.updateButtonEnabled}
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              type="button"
                              className={classes.updateButtonDisabled}
                            >
                              Update
                            </button>
                          )}
                        </Box>
                      </form>
                    </Box>
                  </Box>
                  :
                  <Box className={classes.notAllowedModal}>
                    <FiX onClick={handleClose} className="cross-button" />
                    <Box className={classes.notAllowedText}>
                      <p>
                        Password reset is not permitted since you have signed in from {social_login_type}
                      </p>
                    </Box>
                  </Box>
                }
              </Grow>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}
