// ! This function takes actionTimestamp as a parameter and return difference from current date as (Just Now, $min, $hr, Yesterday, $d, $w, $mo, $y) where $ is a number on given conditions

//! Proper conditions are given in the code below

const calcTimeDifference = (actionTimestamp) => {
  // // making current timestamp and action time date objects
  const curTime = new Date();
  const actionTime = new Date(actionTimestamp);

  let diff = Math.abs(curTime - actionTime);

  let minutes = Math.floor(diff / (1000 * 60));
  let hours = Math.floor(diff / (1000 * 60 * 60));
  let days = Math.floor(diff / (1000 * 60 * 60 * 24));
  let weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
  let months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30.5));
  let years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

  // Difference is more than 1 year
  if (years !== 0) {
    return `${years}y`;

    // Difference is more than 1 month and below 1 year
  } else if (months !== 0) {
    return `${months}mo`;

    // Difference is more than 1 week and below 1 month
  } else if (weeks > 0) {
    return `${weeks}w`;

    // Difference is more than 2 days and below 1 week
  } else if (days > 1) {
    return `${days}d`;

    // Condition for Yesterday (difference for more than 1 day and below 2 days)
  } else if (days === 1) {
    return `Yesterday`;

    // Difference is more than 1 hour and below 24 hours
  } else if (hours > 0) {
    return `${hours}hr`;

    // Difference is more than 1 minute and below 1 hour
  } else if (minutes >= 1) {
    return `${minutes}min`;

    // Difference is less than 1 minute
  } else if (minutes < 1) {
    return 'Just Now';

    // Default return
  } else {
    return 'No match';
  }
};

export default calcTimeDifference;
