// Import React and useState hook
import React, { useState } from 'react';

// Import Speed Dial and Speed Dial Action from Material UI
import { SpeedDial, SpeedDialAction } from '@mui/material';

// Import Stylesheet
import classes from '../TVCardReactions/Reactions.module.css';

// Import custom viewport hook
import { useViewport } from '../../../../CustomHooks/useViewport';

// Import global stylesheet for Speed Dial styling
import '../TVCardReactions/TVCardReactions.global.css';

// Import helper function to round off numbers
import numberRoundOff from '../../../../HelperFunctions/numberRoundOff';

// Import reaction icons
import { ReactComponent as ReactionIcon } from '../../../../Assets/Images/ReactionImages/ReactionBadge.svg';
import { ReactComponent as InspiringIcon } from '../../../../Assets/Images/ReactionImages/Inspiring.svg';
import { ReactComponent as EcoImpactIcon } from '../../../../Assets/Images/ReactionImages/EcoImpact.svg';
import { ReactComponent as SocialImpactIcon } from '../../../../Assets/Images/ReactionImages/SocialImpact.svg';

// Import API utility for making requests
import API from '../../../../Axios/axios';

// Import react-router-dom hooks for navigation
import { useNavigate } from 'react-router-dom';

// Import redux hooks for state management
import { useDispatch } from 'react-redux';
import { reactionUpdate } from '../../../../Store/TVPagePostsSlice';

// Import component for sign-in modal
import GoToSignInModal from '../../../CommonComponents/GoToSignInModal/GoToSignInModal';
import { singlePageReactionUpdate } from '../../../../Store/singlePostSlice';

// React component for reactions
const Reactions = ({ postData, postType }) => {
  // Destructure postData props
  const {
    id,
    reaction_score: { reaction_type, inspiring_score, eco_score, social_score },
  } = postData;

  const breakPoint = 900;
  const token = localStorage.getItem('token');
  const { width } = useViewport();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Initialize state variables
  const [open, setOpen] = useState(false);
  const [goToSignInModal, setGoToSignInModal] = useState(false);

  // Function to handle opening the speed dial
  const handleOpen = () => setOpen(true);

  // Function to handle closing the speed dial
  const handleClose = () => setOpen(false);

  // Function to handle click on reaction icons
  const ReactionIconClickHandler = async (type) => {
    if (token) {
      try {
        const response = await API.post(
          `/posts/${id}/reactions`,
          {},
          {
            headers: { Authorization: `token ${token}` },
            params: { type: type },
          }
        );
        if (response.data.message) {
          if (postType === 'single') {
            dispatch(singlePageReactionUpdate(response.data));
          } else {
            dispatch(reactionUpdate(response.data));
          }
        }
      } catch (e) {
        if (e.response.data.message === 'Invalid token.') {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
        setOpen(false);
      }
    } else {
      setGoToSignInModal(true);
    }
    setOpen(false);
  };

  // Function to get the appropriate reaction icon based on the reaction type
  const getReactionIcon = () => {
    if (reaction_type === 'ins') {
      return <InspiringIcon style={{ height: '3rem', width: '3rem' }} />;
    } else if (reaction_type === 'ei') {
      return <EcoImpactIcon style={{ height: '2.6rem', width: '2.6rem' }} />;
    } else if (reaction_type === 'si') {
      return <SocialImpactIcon style={{ height: '3rem', width: '3rem' }} />;
    } else {
      return <ReactionIcon style={{ height: '2.6rem', width: '2.6rem' }} />;
    }
  };

  // Function to get the tooltip title based on the reaction type and score
  const getTooltipTitle = (reactionType, score) => {
    if (score === 0) {
      return getReactionLabel(reactionType);
    } else {
      return `${numberRoundOff(score)} : ${getReactionLabel(reactionType)}`;
    }
  };

  // Function to get the reaction label based on the reaction type
  const getReactionLabel = (reactionType) => {
    if (reactionType === 'ins') {
      return 'Inspiring';
    } else if (reactionType === 'ei') {
      return 'Eco-Friendly';
    } else if (reactionType === 'si') {
      return 'Socially Impactful';
    }
  };

  return (
    <>
      {goToSignInModal && (
        <GoToSignInModal setGoToSignInModal={setGoToSignInModal} />
      )}
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        icon={getReactionIcon()}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        className={classes.speedDial}
      >
        <SpeedDialAction
          icon={<InspiringIcon style={{ height: '2.4rem', width: '2.4rem' }} />}
          onClick={() => ReactionIconClickHandler('ins')}
          tooltipTitle={getTooltipTitle('ins', inspiring_score)}
          tooltipPlacement="right"
          TooltipClasses={{ tooltip: classes.tooltip }}
          tooltipOpen={width < breakPoint}
        />
        <SpeedDialAction
          icon={<EcoImpactIcon style={{ height: '2rem', width: '2rem' }} />}
          onClick={() => ReactionIconClickHandler('ei')}
          tooltipTitle={getTooltipTitle('ei', eco_score)}
          tooltipPlacement="right"
          TooltipClasses={{ tooltip: classes.tooltip }}
          tooltipOpen={width < breakPoint}
        />
        <SpeedDialAction
          icon={
            <SocialImpactIcon style={{ height: '2.4rem', width: '2.4rem' }} />
          }
          onClick={() => ReactionIconClickHandler('si')}
          tooltipTitle={getTooltipTitle('si', social_score)}
          tooltipPlacement="right"
          TooltipClasses={{ tooltip: classes.tooltip }}
          tooltipOpen={width < breakPoint}
        />
      </SpeedDial>
    </>
  );
};

export default Reactions;
