import React, {useEffect} from 'react';

// Stylesheet import
import classes from './FeaturedCreators.module.css';

// Avatar component imported from MUI
import Avatar from '@mui/material/Avatar';

// Card component import
import Card from '../../../CommonComponents/Card/Card';

// Featured creator data imported from store
import {
  addToFavourites,
  featuredCreatorGet,
  removeFromFavourites,
} from '../../../../Store/featuredCreatorSlice';
import {updateFavouritesCount} from '../../../../Store/userProfileSlice';

// Redux dispatch to dispatch user credentials
import {useDispatch, useSelector} from 'react-redux';

// use navigate imported
import {useNavigate} from 'react-router-dom';

// Custom function imported to capitalize the first letter of the word
import firstLetterCapitalizer from '../../../../HelperFunctions/firstLetterCapitalizer';

// React icons
import {FiUserCheck, FiUserPlus} from 'react-icons/fi';

// Tooltip imported
import MyToolTip from '../../../CommonComponents/MyToolTip';

// API Base URL imported
import API from '../../../../Axios/axios';
import shortenFileName from "../../../../HelperFunctions/shortenFileName";

function FeaturedCreators() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const {featuredCreator} = useSelector(
    (state) => state.featuredCreatorSlice
  );

  // Function to remove that creator form the favorite list of the login user
  const removeFavouritesHandler = async (element) => {
    try {
      const response = await API.post(
        `${element.follow_url}`,
        {},
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      if (response.data.message.status === false) {
        // This will update the status for icon changes
        dispatch(removeFromFavourites(element.id));
        // This will update the favorites count in the user's profile
        dispatch(updateFavouritesCount(response.data.message.total_favourites));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to add that creator to the favorite list of the login user
  const addFavouritesHandler = async (element) => {
    try {
      const response = await API.post(
        `${element.follow_url}`,
        {},
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      if (response.data.message.status === true) {
        // This will update the status for icon changes
        dispatch(addToFavourites(element.id));
        // This will update the favorites count in the user's profile
        dispatch(updateFavouritesCount(response.data.message.total_favourites));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // This will log out the user if the token is expired
    dispatch(featuredCreatorGet())
      .then((response) => {
        if (response.error) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Function to redirect to the profile of creator
  const creatorNameClickHandler = (profileId) => {
    navigate(`/accounts/${profileId}/profile/`);
  };

  return (
    <div>
      <Card>
        <div className={classes.header}>
          <h1 className="component-heading">Featured Creators</h1>
        </div>
        <div className={classes.container}>
          {featuredCreator.map((element) => (
            <div className={classes.element} key={element.id}>
              <Avatar
                alt="profile_pic"
                src={element.user.profile_pic}
                className={classes.avatar}
                onClick={() => creatorNameClickHandler(element.user.id)}
              />
              <div className={classes.description}>
                <h2
                  className={`element-heading ${classes.profileName}`}
                  onClick={() => creatorNameClickHandler(element.user.id)}
                >
                  {shortenFileName(element.user.name, 28)}
                </h2>
                <p className="element-description">
                  {firstLetterCapitalizer(element.user.top_interest)}
                </p>
              </div>
              {element.user.status ? (
                <MyToolTip title="Favourite" placement="left">
                  <div>
                    <FiUserCheck
                      onClick={() => {
                        removeFavouritesHandler(element.user);
                      }}
                      className={classes.fiUserCheck}
                    />
                  </div>
                </MyToolTip>
              ) : (
                <MyToolTip title="Be a Fan" placement="left">
                  <div>
                    <FiUserPlus
                      onClick={() => {
                        addFavouritesHandler(element.user);
                      }}
                      className={classes.follow}
                    />
                  </div>
                </MyToolTip>
              )}
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
}

export default FeaturedCreators;
