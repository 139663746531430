import React from 'react';
import { Box, Grow, Modal } from '@mui/material';
// import icons
import { FiX } from 'react-icons/fi';
// import CSS
import classes from './CloseWarningModal.module.css';

const CloseWarningModal = (props) => {
  const handleClose = () => {
    props.setCloseWarning(false);
  };
  return (
    <>
      <Modal open={true} onClose={handleClose}>
        <div>
          <Grow in={true}>
            <Box className={classes.closeWarningModal}>
              <Box>
                <FiX className="cross-button" onClick={handleClose} />
              </Box>
              <Box className={classes.closeWarningModalMain}>
                <Box className={classes.closeWarningModalHeading}>
                  <h3>All your progress shall be lost.</h3>
                  <p>Are you sure you want to exit?</p>
                </Box>

                <Box className={classes.closeWarningModalBody}>
                  <button
                    className={classes.yesButton}
                    onClick={() => props.onClose()}
                  >
                    YES
                  </button>
                  <button className={classes.noButton} onClick={handleClose}>
                    NO
                  </button>
                </Box>
              </Box>
            </Box>
          </Grow>
        </div>
      </Modal>
    </>
  );
};

export default CloseWarningModal;
