import React, {useEffect, useRef, useState} from 'react';

// Stylesheet import
import classes from './TVCardDescription.module.css';

// Icon import from Feather icon
import {ChevronUp, MoreHorizontal} from 'react-feather';

// Collapse transition import from Material UI
import {Collapse} from '@mui/material';

function TVCardDescription({postData}) {
  const {text} = postData;
  const [isDescriptionActive, setDescriptionActive] = useState(false);
  const [height, setHeight] = useState(0);
  const [num, setNum] = useState(null);
  const observedDiv = useRef(null);
  const handleElementResized = () => {
    if (observedDiv.current.offsetHeight !== height) {
      setHeight(observedDiv.current.offsetHeight);
    }
  };
  const resizeObserver = new ResizeObserver(handleElementResized);
  useEffect(() => {
    resizeObserver.observe(observedDiv.current);
    return function cleanup() {
      resizeObserver.disconnect();
    };
  });

  const activateDescription = () => {
    setDescriptionActive(true);
  };

  const deactivateDescription = () => {
    setDescriptionActive(false);
  };

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      setNum(18);
    } else {
      setNum(20);
    }
  }, [window]);

  return (<div className={classes.descriptionContainer}>
    <Collapse in={isDescriptionActive} collapsedSize={18}>
      <div
        className={`description-text ${classes.description}`}
        ref={observedDiv}
        dangerouslySetInnerHTML={{__html: text}}/>
    </Collapse>

    {Math.ceil(height / num) > 1 ? (isDescriptionActive ? (<ChevronUp
      className={`${classes.icon} ${classes.closeDescriptionIcon}`}
      onClick={deactivateDescription}
    />) : (<MoreHorizontal
      className={classes.icon}
      onClick={activateDescription}
    />)) : null}
  </div>);
}

export default TVCardDescription;
