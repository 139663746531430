import React, {useEffect, useState} from 'react';
import classes from "./UploadingProgressModal.module.css";
import {Box, Grow, Modal} from "@mui/material";
import {useNavigate} from "react-router-dom";
import API from "../../../../../../../Axios/axios";
import {apiErrorCodes} from "../../../../../../../apiErrorCodes";
import {postUpload} from "../../../../../../../Store/TVPagePostsSlice";
import {addMyContentPost} from "../../../../../../../Store/userProfileSlice";
import {useDispatch} from "react-redux";
import AcknowledgementModal from "../AcknowledgementModal/AcknowledgementModal";

const UploadingProgressModal = (props) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const [acknowledgementModal, setAcknowledgementModal] = useState(false);
  const [text, setText] = useState('Uploading...');

  const sendToBackend = async (data) => {
    const formData = new FormData();
    formData.append('content_type', data.contentType);
    formData.append('project_title', data.postTitle);
    formData.append('text', data.postDescription);
    formData.append('aspect_ratio', data.aspectRatio);
    data.attachments.forEach((file) => formData.append('file_attachments', file));
    const categoriesId = []
    data.categories.map((option) => (categoriesId.push(option.id)));

    formData.append('category', categoriesId);
    data.mediaFiles.forEach((media) => formData.append('media', media));

    try {
        const response = await API.post('/posts/', formData, {
        headers: {
          Authorization: `token ${token}`, 'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      if (response.data.code === apiErrorCodes.SUCCESS) {
        // props.onClose();
        setAcknowledgementModal(true);
        console.log(`Acknowledgement Modal`, acknowledgementModal);
        dispatch(postUpload(response.data.message.post));
        dispatch(addMyContentPost(response.data.message.post));
      }
    } catch (error) {
      setText('Something went wrong. Please try again')
      if (error.response.data.code === apiErrorCodes.INVALID_TOKEN || error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED || error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED || error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      } else if (error.response.data.code === apiErrorCodes.UNSUPPORTED_FILE) {
        // setUnsupportedFileModal(true);
        // setErrorType('UNSUPPORTED_FILE_TYPE');
      } else if (error.response.data.code === apiErrorCodes.POST_CATEGORY_REQUIRED) {
        // setAddMinimumCategoriesWarning(true);
      } else if (error.response.data.code === apiErrorCodes.POST_TITLE_REQUIRED) {
        // setProjectTitleMobileError(true);
      }
    }
  };
  const closeModal = () => {
    if (text !== 'Uploading...') {
      props.setOpenProgressModal(false);
    }
  }
  useEffect(() => {
    sendToBackend(props.data)
  }, []);

  return (<>
    {acknowledgementModal && (<AcknowledgementModal
      message={'Content Published Successfully.'}
      setAcknowledgementModal={setAcknowledgementModal}
      onClose={props.onClose}
    />)}
    <Modal open={open} onClose={closeModal}>
      <div>
        <Grow in={true}>
          <Box className={classes.uploadingProgressModal}>
            <Box className={classes.uploadingProgressModalBox}>
              <p className={classes.uploadingProgressModalText}>
                <h1 className='component-heading'>{text}</h1>
              </p>
            </Box>
          </Box>
        </Grow>
      </div>
    </Modal></>);
};

export default UploadingProgressModal;