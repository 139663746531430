import { React } from 'react'
// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import CSS
import classes from './ThumbnailCarousel.module.css'
// Icons
import { ImCross } from 'react-icons/im';
import { MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md'

const ThumbnailCarousel = (props) => {
    const handleDisplayClick = (blob) => {
        props.handleDisplay(blob);
    }
    const handleDeleteClick = (blob) => { 
        props.handleDelete(blob);
    }
    return (
        <div>
            <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                desktop: {
                breakpoint: {
                    max: 3000,
                    min: 1024
                },
                items: 4,
                partialVisibilityGutter: 40
                },
                mobile: {
                breakpoint: {
                    max: 464,
                    min: 0
                },
                items: 2,
                partialVisibilityGutter: 30
                },
                tablet: {
                breakpoint: {
                    max: 1024,
                    min: 464
                },
                items: 3,
                partialVisibilityGutter: 30
                }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            itemClass={classes.carouselItem}
            customLeftArrow={<div className={classes.arrowContainerLeft}><MdArrowBackIos className={classes.carouselBackBtn} /></div>}
            customRightArrow={<div className={classes.arrowContainerRight}><MdArrowForwardIos className={classes.carouselNextBtn}/></div>}
            swipeable
                
            >
            {
                props.mediaBlob.map((blob, key) => 
                {
                    // const image = props.idxDict[index];
                    return(
                    <div className={classes.thumbnailContainer} key={key}>
                        <div className={classes.thumbnail} style={{ backgroundImage: props.videoBlobs.indexOf(blob) === -1 ? `url(${blob})`: `url(${props.thumbnail[blob]})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} onClick={() => handleDisplayClick(blob)}  >
                            <button className={classes.delBtn}>
                                    <ImCross className={classes.deleteImage} onClick={() => handleDeleteClick(blob)} />
                            </button>
                        </div>
                    </div> 
                )}
                )
            }
        </Carousel>
        </div>
    )
}

export default ThumbnailCarousel

