import { countCharacters } from "../countCharacters";

export const isValidImage = (imageFile) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp)$/i; // regex for allowed extensions
    // For single image upload
    if (!allowedExtensions.exec(imageFile.name)) {
      return 'UNSUPPORTED_FILE_TYPE';
    }
    else {
      if (countCharacters(imageFile.name) > 1000) {
        return 'UNSUPPORTED_FILE_NAME';
      }
      else {
          return null;  
      }
    }
}

