import React, { useState } from 'react';
import { Box, Grow, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import classes from './GoToSignInModal.module.css';

export default function GoToSignInModal(props) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const signInClickHandler = () => {
    setOpen(false);
    props.setGoToSignInModal(false);
    navigate('/entry/signin');
  };
  const handleClose = () => {
    setOpen(false);
    props.setGoToSignInModal(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div>
          <Grow in={true}>
            <Box className={classes.goToSignInModal}>
              <Box className={classes.goToSignInModalBox}>
                <p className={classes.GoToSignInText}>
                  Please{' '}
                  <span
                    className={classes.signInText}
                    onClick={signInClickHandler}
                  >
                    SignIn
                  </span>{' '}
                  to continue.
                </p>
              </Box>
            </Box>
          </Grow>
        </div>
      </Modal>
    </>
  );
}
