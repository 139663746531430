import React, { useState } from 'react';
import { Box, Grow, Modal, TextField } from '@mui/material';
import { FiX } from 'react-icons/fi';
import classes from './ThirdModal.module.css';
import styled from 'styled-components';
import { IoIosWarning } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import API from '../../../../../Axios/axios';
import { useNavigate } from 'react-router-dom';
import SuccessMessageModal from '../SuccessMessageModal/SuccessMessageModal';
import { apiErrorCodes } from '../../../../../apiErrorCodes';
import { useSelector } from 'react-redux';

const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;

const ThirdModal = (props) => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [successMessageModal, setSuccessMessageModal] = useState(false);
  const { social_login_type } = useSelector((state) => state.userProfileSlice.userProfileData);
  // react-hook-form stuff
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data) => {
    setButtonEnabled(false);

    const formData = new FormData();
    formData.append('password', data.password);
    try {
      const response = await API.delete('/accounts/delete/', {
        headers: {
          Authorization: `token ${token}`,
        },
        data: formData,
      });
      if (response.data.code === apiErrorCodes.SUCCESS) {
        setSuccessMessageModal(true);
        localStorage.clear();
        setButtonEnabled(true);
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.PASSWORD_IS_REQUIRED ||
        error.response.data.code === apiErrorCodes.INVALID_PASSWORD
      ) {
        // setPasswordError(true);
        setError('password', {
          type: 'manual',
          message: ' Incorrect Password.',
        });
        setButtonEnabled(true);
      } else if (
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.ANONYMOUS_USER ||
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST ||
        error.response.data.code === apiErrorCodes.UNAUTHORIZED_USER
      ) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    props.handleModalClose();
  };

  return (
    <>
      {successMessageModal && (
        <SuccessMessageModal
          setSuccessMessageModal={setSuccessMessageModal}
          handleClose={handleClose}
        />
      )}
      {successMessageModal ? (
        ''
      ) : (
        <Modal open={open}>
          {/* <Modal open={true} onClose={props.handleModalClose}> */}
          <div>
            <Grow in={true}>
              <Box className={classes.thirdModal}>
                <FiX
                  className="cross-button"
                  onClick={props.handleModalClose}
                />
                <Box className={classes.thirdModalBody}>
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <p className={classes.thirdModalTitle}>
                      <IoIosWarning className={classes.icon} />
                      Deleting account will delete all your data and files
                      shared on Srijcon and cannot be recovered.
                      <br />
                    </p>
                      {social_login_type === 'email' &&
                        <StyledTextField
                          label="Enter Password"
                          variant="outlined"
                          type="password"
                          sx={{ width: '100%', margin: '1rem 0' }}
                          InputLabelProps={{
                            style: { fontSize: '1.4rem', color: '#6e6e6e' },
                          }}
                          InputProps={{
                            style: { fontSize: '1.4rem', fontWeight: 400 },
                          }}
                          {...register('password', {
                            required: 'Required field',
                          })}
                          error={!!errors?.password}
                          helperText={
                            errors?.password ? errors.password.message : null
                          }
                        />
                      }

                    <div className={classes.buttons1}>
                      <button
                        className={classes.changeMyMindButton}
                        onClick={props.handleModalClose}
                      >
                        Cancel
                      </button>

                      {buttonEnabled ? (
                        <button
                          type="submit"
                          className={classes.deleteMyAccountButtonEnabled}
                        >
                          Delete Account
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={classes.deleteMyAccountButtonDisabled}
                        >
                          Delete Account
                        </button>
                      )}
                    </div>
                  </form>
                </Box>
              </Box>
            </Grow>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ThirdModal;
