import React from 'react';
import { Box, Grow, Modal } from '@mui/material';
import { FiX } from 'react-icons/fi';
import style from './MaxSelectFileErrorModal.module.css';

export default function MaxSelectFileErrorModal({
  setMaxSelectFileErrorModal,
}) {
  return (
    <>
      <Modal open={true} onClose={() => setMaxSelectFileErrorModal(false)}>
        <div>
          <Grow in={true}>
            <Box className={style.maxSelectFileErrorModal}>
              <FiX
                className="cross-button"
                onClick={() => setMaxSelectFileErrorModal(false)}
              />
              <Box className={style.maxSelectFileErrorModalBox}>
                <p className={style.maxSelectFileErrorText}>
                  Maximum 10 media files are allowed.
                </p>
              </Box>
            </Box>
          </Grow>
        </div>
      </Modal>
    </>
  );
}
