import React, {useRef, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {TiCamera} from 'react-icons/ti';
import {InputAdornment, TextField} from '@mui/material';
import styled from 'styled-components';
import classes from './EditProfileSection.module.css';
import { useViewport } from '../../../CustomHooks/useViewport';

import { countCharacters } from '../../../HelperFunctions/countCharacters';
import UnsupportedFileModal from '../../Navbar/NavbarRight/ContentTypeModal/UploadContentModal/ErrorModals/UnsupportedFileModal/UnsupportedFileModal';

//Import Modal Cropper
import ModalCropper from '../../ImageCropper/ModalCropper';

// Redux dispatch to dispatch user credentials
import {useSelector} from 'react-redux';

// Banner Image for avatar
import BannerImg from '../../../Assets/Images/BannerImg.webp';

// Banner Image camera dropdown components
import BannerImageDropdown from '../EditProfileSection/BannerImageDropdown';

// Country List Data
import {CountryList} from './CountryList.js';
import API from '../../../Axios/axios';
import SuccessMessageModal from './SuccessMessageModal/SuccessMessageModal';
import {apiErrorCodes} from '../../../apiErrorCodes';
import {isValidImage} from '../../../HelperFunctions/checkValidity/isValidImage';

// Gender Data
const genderSelect = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;

function EditProfileSection() {
  const token = localStorage.getItem('token');
  // custom hook to get viewport width
  const {width} = useViewport();
  const {userProfileData} = useSelector((state) => state.userProfileSlice);
  const [successMessageModal, setSuccessMessageModal] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [bannerImg, setBannerImg] = useState('');
  const [avatarImg, setAvatarImg] = useState('');

  const [personData, setPersonData] = useState({
    userName: userProfileData.username,
    firstName: userProfileData.first_name,
    lastName: userProfileData.last_name,
    bannerImg: userProfileData.banner_pic,
    avatarImg: userProfileData.profile_pic,
    dob: userProfileData.dob,
    gender: userProfileData.gender,
    countryCode: userProfileData.country_code,
    phoneNumber: userProfileData.phone,
    address: userProfileData.address,
    city: userProfileData.city,
    state: userProfileData.state,
    country: userProfileData.country,
    zip: userProfileData.zip,
    impact: userProfileData.impact_question,
  });

  const [modalAvatar, setModalAvatar] = useState(personData['avatarImg']);
  const [modalBanner, setModalBanner] = useState(personData['bannerImg']);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);
  const [extension, setExtension] = useState(null);
  const [unsupportedFile, setUnsupportedFile] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [unsupportedFileModal, setUnsupportedFileModal] = useState(false);

  const [personDataError, setPersonDataError] = useState({
    firstNameError: false,
    lastNameError: false,
    userNameError: false,
    userNameValidationError: false,
    phoneNumberError: false,
  });
  const CountryData = CountryList;
  const navigate = useNavigate();
  const inputAvatarFileRef = useRef(null);
  const inputBannerFileRef = useRef(null);
  const [bannerImgDropdownActive, setBannerImgDropdownActive] = useState(false);

  const checkProfileStatus = async () => {
    try {
      const response = await API.get('/accounts/profile/status', {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      if (response.data.message.profile === false)
        navigate('/entry/userdetails');
      else if (response.data.message.creative_interests === false)
        navigate('/entry/creativeinterest');
    }
    catch {
      throw new Error('Error in validating profile');
    }
  };

  useEffect(() => {
    checkProfileStatus();
  }, []);

  const onSubmit = async (event) => {
    if (
      personDataError.firstNameError === true ||
      personDataError.lastNameError === true ||
      personDataError.userNameError === true ||
      personDataError.phoneNumberError === true
    ) {
      event.preventDefault();
      return false;
    } else {
      setButtonDisabled(true);
      event.preventDefault();
      const formData = new FormData();
      formData.append('username', personData.userName);
      formData.append('first_name', personData.firstName);
      formData.append('last_name', personData.lastName);
      if (bannerImg) {
        formData.append('banner_pic', bannerImg);
      }
      if (avatarImg) {
        formData.append('profile_pic', avatarImg);
      }
      console.log(avatarImg)
      if (personData.dob != null) {
        formData.append('dob', personData.dob);
      }

      if (personData.gender != null) {
        formData.append('gender', personData.gender);
      }
      formData.append('country_code', personData.countryCode);

      if (personData.phoneNumber != null) {
        formData.append('phone', personData.phoneNumber);
      }
      formData.append('address', personData.address);
      formData.append('city', personData.city);
      formData.append('state', personData.state);
      formData.append('country', personData.country);
      formData.append('zip', personData.zip);
      if (personData.impact != null) {
        formData.append('impact_question', personData.impact);
      }

      try {
        // using axios
        const response = await API.patch('/accounts/myprofile/', formData, {
          headers: {
            Authorization: `token ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.data.code === apiErrorCodes.SUCCESS) {
          setSuccessMessageModal(true);
          setButtonDisabled(false);
        }
      } catch (error) {
        if (
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
          error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        } else if (
          error.response.data.code === apiErrorCodes.USERNAME_ALREADY_TAKEN ||
          error.response.data.message.username ==
          'Please enter your username.' ||
          error.response.data.message.username ==
          'Character limit: 4-30, a-z, 0-9, dot(.), underscore(_).' ||
          error.response.data.message.username == 'Invalid username.'
        ) {
          setPersonDataError({
            ...personDataError,
            userNameError: true,
          });
          setButtonDisabled(false);
        } else if (
          error.response.data.message.first_name ==
          'Please enter your first name.' ||
          error.response.data.message.first_name ==
          "You can't enter special characters and number in your name." ||
          error.response.data.message.first_name ==
          'This field may not be blank.'
        ) {
          setPersonDataError({
            ...personDataError,
            firstNameError: true,
            lastNameError: false,
          });
          setButtonDisabled(false);
        } else if (
          error.response.data.message.last_name ==
          'Please enter your last name.' ||
          error.response.data.message.last_name ==
          "You can't enter special characters and number in your name." ||
          error.response.data.message.last_name ==
          'This field may not be blank.'
        ) {
          setPersonDataError({
            ...personDataError,
            firstNameError: false,
            lastNameError: true,
          });
          setButtonDisabled(false);
        }
      }
    }
  };
  const handleDropdownBannerImageChange = (imageFile) => {
    const newBannerImage = imageFile;
    const validity = isValidImage(newBannerImage);
    if (validity === null) {
      setModalBanner(URL.createObjectURL(imageFile));
      setExtension(imageFile.name.split('.').pop());
      setIsBannerModalOpen(true);
    } else {
      setUnsupportedFile(true);
      setErrorType(validity);
    }

  };

  const handleBannerImageChange = (e) => {
    const newBannerImage = e.target.files[0];
    e.target.value = null;
    const validity = isValidImage(newBannerImage);
    if (validity === null) {
      setModalBanner(URL.createObjectURL(newBannerImage));
      setExtension(newBannerImage.name.split('.').pop());
      setIsBannerModalOpen(true);
    } else {
      setErrorType(validity);
    }

  };

  const handleBannerImageModalChange = (blob, file) => {
    try {
      setPersonData({
        ...personData,
        bannerImg: blob,
      });
      setBannerImg(file);
      setIsBannerModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAvatarImageChange = (e) => {
    const newAvatarImage = e.target.files[0];
    e.target.value = null;
    const validity = isValidImage(newAvatarImage);
    if (validity === null) {
      setModalAvatar(URL.createObjectURL(newAvatarImage));
      setExtension(newAvatarImage.name.split('.').pop());
      setIsAvatarModalOpen(true);
    } else {
      setUnsupportedFile(true);
      setErrorType(validity);
    }

  };

  const handleAvatarImageModalChange = (blob, file) => {
    try {
      setPersonData({
        ...personData,
        avatarImg: blob,
        // avatarCroppedAreaPixels: sendData['croppedAreaPixels'],
        // avatarRotation: sendData['rotation']
      });
      setAvatarImg(file);
      setIsAvatarModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFirstNameChange = (event) => {
    if (event.target.value === '') {
      setPersonDataError({...personDataError, firstNameError: true});
    } else {
      setPersonDataError({...personDataError, firstNameError: false});
    }
    setPersonData({
      ...personData,
      firstName: event.target.value
        .replace(/[^a-zA-Z ]/gm, '')
        .replace(/\s+/g, ' ')
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }),
    });
  };

  const handleLastNameChange = (event) => {
    if (event.target.value === '') {
      setPersonDataError({...personDataError, lastNameError: true});
    } else {
      setPersonDataError({...personDataError, lastNameError: false});
    }
    setPersonData({
      ...personData,
      lastName: event.target.value
        .replace(/[^a-zA-Z ]/gm, '')
        .replace(/\s+/g, ' ')
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }),
    });
  };

  const handleUserNameChange = (event) => {
    if (event.target.value === '') {
      setPersonDataError({...personDataError, userNameError: true});
      setPersonDataError({
        ...personDataError,
        userNameValidationError: false,
      });
    } else if (event.target.value.length < 4) {
      setPersonDataError({...personDataError, userNameError: true});
      setPersonDataError({
        ...personDataError,
        userNameValidationError: false,
      });
    } else if (
      /^(?=[a-zA-Z0-9._]{4,30}$)(?!.*[._]{2})[^._].*[^._]$/.test(
        event.target.value
      )
    ) {
      setPersonDataError({...personDataError, userNameError: false});
      setPersonDataError({
        ...personDataError,
        userNameValidationError: false,
      });
    } else {
      setPersonDataError({...personDataError, userNameValidationError: true});
    }
    setPersonData({
      ...personData,
      userName: event.target.value.replace(/[^a-zA-Z0-9._]/gm, ''),
    });
  };
  const handleDOBChange = (event) => {
    setPersonData({...personData, dob: event.target.value});
  };
  const handleGenderChange = (event) => {
    setPersonData({...personData, gender: event.target.value});
  };
  const handleCountryCodeChange = (event) => {
    setPersonData({...personData, countryCode: event.target.value});
  };
  const handlePhoneNumberChange = (event) => {
    if (event.target.value.length < 10) {
      setPersonDataError({...personDataError, phoneNumberError: true});
    } else {
      setPersonDataError({...personDataError, phoneNumberError: false});
    }
    setPersonData({
      ...personData,
      phoneNumber: event.target.value.replace(/[^0-9 ]/gm, ''),
    });
  };
  const handleAddressChange = (event) => {
    setPersonData({
      ...personData,
      address: event.target.value.replace(
        /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@. ]/gm,
        ''
      ),
    });
  };
  const handleCityChange = (event) => {
    setPersonData({
      ...personData,
      city: event.target.value.replace(/[^a-zA-Z!#$%&'*+-/=?^_`{|@. ]/gm, ''),
    });
  };

  const handleStateChange = (event) => {
    setPersonData({
      ...personData,
      state: event.target.value.replace(/[^a-zA-Z!#$%&'*+-/=?^_`{|@. ]/gm, ''),
    });
  };

  const handleCountryChange = (event) => {
    setPersonData({
      ...personData,
      country: event.target.value.replace(/[^a-zA-Z ]/gm, ''),
    });
  };
  const handleZipChange = (event) => {
    setPersonData({
      ...personData,
      zip: event.target.value.replace(/[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@. ]/gm, ''),
    });
  };
  const handleImpactChange = (event) => {
    setPersonData({
      ...personData,
      impact: event.target.value.replace(
        /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@. ]/gm,
        ''
      ),
    });
  };

  const handleBannerImgDropdown = () => {
    setBannerImgDropdownActive(!bannerImgDropdownActive);
  };
  const handleAvatarClose = () => {
    setIsAvatarModalOpen(false);
  };
  const handleBannerClose = () => {
    setIsBannerModalOpen(false);
  };
  return (
    <>
      {successMessageModal && (
        <SuccessMessageModal setSuccessMessageModal={setSuccessMessageModal}/>
      )}
      {unsupportedFileModal && (
        <UnsupportedFileModal
          setUnsupportedFileModal={setUnsupportedFileModal}
          errorType={errorType}
        />
      )}
      <div className={classes.main}>
        <form onSubmit={onSubmit} autoComplete="off">
          <div className={classes.EditProfileSection}>
            {/* For Banner At the top of the Above section */}
            <div className={classes.bannerEp}>
              <div className={classes.editBanner}>
                <img
                  draggable="false"
                  src={personData.bannerImg}
                  alt="BannerImage"
                />
                {isBannerModalOpen && (
                  <ModalCropper
                    image={modalBanner}
                    onClose={handleBannerClose}
                    handleApply={handleBannerImageModalChange}
                    aspect={3}
                    extension={extension}
                    shape="rect"
                  />
                )}
                {personData.bannerImg === BannerImg ? (
                  <div className={classes.cameraDropdownContainer}>
                    <div
                      className={classes.cameraIcon1}
                      onClick={() => inputBannerFileRef.current.click()}
                    >
                      <TiCamera className={classes.fillCameraIcon}/>
                      <input
                        ref={inputBannerFileRef}
                        hidden
                        accept=".jpg, .jpeg, .png"
                        id="avatar-image-upload"
                        type="file"
                        onChange={(e) => handleBannerImageChange(e)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={classes.cameraDropdownContainer}>
                    <div
                      className={classes.cameraIcon1}
                      onClick={handleBannerImgDropdown}
                    >
                      <TiCamera className={classes.fillCameraIcon}/>
                    </div>
                    {bannerImgDropdownActive && (
                      <BannerImageDropdown
                        setBannerImgDropdownActive={setBannerImgDropdownActive}
                        personData={personData}
                        setPersonData={setPersonData}
                        setBannerImg={setBannerImg}
                        onImageChange={handleDropdownBannerImageChange}
                      />
                    )}
                  </div>
                )}
                <p>Showcase your creative interest</p>
              </div>
            </div>

            {/* User Profile Picture Display */}
            <div className={classes.editAvatar}>
              <div className={classes.editImgAvt1}>
                {isAvatarModalOpen && (
                  <ModalCropper
                    image={modalAvatar}
                    onClose={handleAvatarClose}
                    handleApply={handleAvatarImageModalChange}
                    aspect={1}
                    extension={extension}
                    shape="round"
                  />
                )}
                <img
                  draggable="false"
                  src={personData.avatarImg}
                  alt="AvatarImage"
                />
              </div>
              <div className={classes.cameraIcon2}>
                <TiCamera
                  className={classes.fillCameraIcon}
                  onClick={() => inputAvatarFileRef.current.click()}
                />
                <input
                  ref={inputAvatarFileRef}
                  hidden
                  accept=".jpg, .jpeg, .png"
                  id="avatar-image-upload"
                  type="file"
                  onChange={handleAvatarImageChange}
                />
              </div>
            </div>

            {/* Bottom Area */}
            <div className={classes.bottomTextFieldsContainer}>
              <div className={classes.fields}>
                <div className={classes.names}>
                  <StyledTextField
                    className={classes.textCheckOne}
                    value={personData.firstName}
                    name="firstName"
                    onChange={(event) => handleFirstNameChange(event)}
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    InputProps={{style: {fontSize: '1.4rem'}}}
                    inputProps={{
                      maxLength: 40,
                      style: {textTransform: 'uppercase'},
                    }}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: '#6e6e6e'},
                      shrink: true,
                    }}
                    helperText={
                      personDataError.firstNameError ? 'Required field' : ''
                    }
                  />

                  <StyledTextField
                    className={classes.textCheckOne}
                    value={personData.lastName}
                    name="lastName"
                    onChange={(event) => handleLastNameChange(event)}
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    InputProps={{style: {fontSize: '1.4rem'}}}
                    inputProps={{
                      maxLength: 40,
                      style: {textTransform: 'uppercase'},
                    }}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: '#6e6e6e'},
                      shrink: true,
                    }}
                    helperText={
                      personDataError.lastNameError ? 'Required field' : ''
                    }
                  />
                </div>
              </div>
              <div className={classes.fields}>
                <div className={classes.names}>
                  <StyledTextField
                    disabled
                    className={classes.textCheckTwo}
                    value={personData.userName}
                    name="userName"
                    onChange={(event) => handleUserNameChange(event)}
                    id="outlined-basic"
                    label="Username"
                    variant="outlined"
                    InputProps={{style: {fontSize: '1.4rem'}}}
                    inputProps={{
                      maxLength: 30,
                      style: {textTransform: 'lowercase'},
                    }}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: '#6e6e6e'},
                      shrink: true,
                    }}
                    // helperText={personDataError.userNameValidationError ? "User name hi galat hai yaar" : ""}
                    helperText={
                      personDataError.userNameError
                        ? 'Required field'
                        : personDataError.userNameValidationError
                          ? 'User name hi galat hai yaar'
                          : ''
                    }
                  />
                </div>
              </div>
              <div className={classes.fields}>
                <div className={classes.names}>
                  <StyledTextField
                    className={classes.textCheckOne}
                    value={personData.dob}
                    name="dob"
                    onChange={(event) => handleDOBChange(event)}
                    type="date"
                    id="outlined"
                    label="Date Of Birth"
                    variant="outlined"
                    inputProps={{max: new Date().toISOString().slice(0, 10)}}
                    InputProps={{style: {fontSize: '1.4rem'}}}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: 'gray'},
                      shrink: true,
                    }}
                  />
                  <StyledTextField
                    className={classes.textCheckOne}
                    value={personData.gender}
                    name="gender"
                    onChange={(event) => handleGenderChange(event)}
                    select
                    id="outlined-select-gender"
                    label="Gender"
                    variant="outlined"
                    SelectProps={{native: true}}
                    InputProps={{style: {fontSize: '1.4rem'}}}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: '#6e6e6e'},
                      shrink: true,
                    }}
                  >
                    {genderSelect.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </StyledTextField>
                </div>
              </div>

              <div className={classes.fields}>
                <div className={classes.names}>
                  <StyledTextField
                    className={classes.textCheckTwo}
                    value={personData.phoneNumber}
                    name="phoneNumber"
                    onChange={(event) => handlePhoneNumberChange(event)}
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    onInput={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{type: 'text'}}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: '#6e6e6e'},
                      shrink: true,
                    }}
                    onKeyDown={(e) =>
                      ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <StyledTextField
                            sx={{width: 150}}
                            select
                            variant="standard"
                            value={personData.countryCode}
                            name="countryCode"
                            onChange={(event) => handleCountryCodeChange(event)}
                            SelectProps={{native: true}}
                            InputProps={{
                              style: {fontSize: '1.4rem'},
                              disableUnderline: true,
                            }}
                            InputLabelProps={{
                              style: {fontSize: '1.4rem', color: '#6e6e6e'},
                              shrink: true,
                            }}
                          >
                            {CountryData.map((option) => (
                              <option key={option.name} value={option.name}>
                                {option.name} ({option.code}) + {option.number}
                              </option>
                            ))}
                          </StyledTextField>
                        </InputAdornment>
                      ),
                      style: {fontSize: '1.4rem'},
                    }}
                    // helperText={personDataError.phoneNumberError ? "Invalid Input" : ""}
                  />
                </div>
              </div>
              <div className={classes.fields}>
                <div className={classes.names}>
                  <StyledTextField
                    className={classes.textCheckTwo}
                    value={personData.address}
                    name="address"
                    onChange={(event) => handleAddressChange(event)}
                    id="outlined-basic"
                    label="Address"
                    variant="outlined"
                    InputProps={{style: {fontSize: '1.4rem'}}}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: '#6e6e6e'},
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div className={classes.fields}>
                <div className={classes.names}>
                  <StyledTextField
                    className={classes.textCheckOne}
                    value={personData.city}
                    name="city"
                    onChange={(event) => handleCityChange(event)}
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    InputProps={{style: {fontSize: '1.4rem'}}}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: '#6e6e6e'},
                      shrink: true,
                    }}
                  />
                  <StyledTextField
                    className={classes.textCheckOne}
                    value={personData.state}
                    name="state"
                    onChange={(event) => handleStateChange(event)}
                    id="outlined-basic"
                    label="State"
                    variant="outlined"
                    InputProps={{style: {fontSize: '1.4rem'}}}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: '#6e6e6e'},
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div className={classes.fields}>
                <div className={classes.names}>
                  <StyledTextField
                    className={classes.textCheckOne}
                    value={personData.zip}
                    name="zip"
                    onChange={(event) => handleZipChange(event)}
                    id="outlined-basic"
                    label="Zip Code"
                    variant="outlined"
                    inputProps={{type: 'text'}}
                    InputProps={{style: {fontSize: '1.4rem'}}}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: '#6e6e6e'},
                      shrink: true,
                    }}
                    onKeyDown={(e) =>
                      ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                    }
                  />

                  <StyledTextField
                    select
                    label="Country"
                    className={classes.textCheckOne}
                    value={personData.country}
                    name="country"
                    onChange={(event) => handleCountryChange(event)}
                    id="outlined-select-country"
                    variant="outlined"
                    SelectProps={{native: true}}
                    InputProps={{style: {fontSize: '1.4rem'}}}
                    InputLabelProps={{
                      style: {fontSize: '1.4rem', color: '#6e6e6e'},
                      shrink: true,
                    }}
                  >
                    {CountryData.map((option) => (
                      <option key={option.code} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </StyledTextField>
                </div>
              </div>
              <div className={classes.impactHeading}>
                <p style={{color: '#6e6e6e'}}>
                  What impact do you want to bring through your creations?
                </p>
              </div>
              <label>
                <StyledTextField
                  multiline
                  rows={width < 600 ? 7 : 10}
                  variant="outlined"
                  name="impact"
                  value={personData.impact}
                  onChange={(event) => handleImpactChange(event)}
                  sx={{
                    '& legend': {display: 'none'},
                    '& fieldset': {top: 0},
                    width: '100%',
                    margin: '1rem 0',
                  }}
                  InputLabelProps={{
                    style: {fontSize: '1.4rem', color: '#6e6e6e'},
                    shrink: true,
                  }}
                  InputProps={{
                    style: {fontSize: '1.4rem', fontWeight: 400},
                  }}
                  inputProps={{maxLength: 1000}}
                  type="text"
                />
              </label>
              <div className={classes.fields}>
                <div className={classes.btnContainer}>
                  <button
                    className={classes.btnCancel}
                    onClick={() => {
                      navigate('/userprofile');
                    }}
                  >
                    Cancel
                  </button>
                  {!buttonDisabled ? (
                    <button type="submit" className={classes.btnSave}>
                      Save
                    </button>
                  ) : (
                    <button type="button" className={classes.btnSaveDisabled}>
                      Save
                    </button>
                  )}
                </div>
              </div>
              <div className={classes.endOfForm}></div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditProfileSection;
