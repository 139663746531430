import React from 'react';

// Stylesheet import
import classes from './BannerImageDropdown.module.css';

// Card Component import from UI section
import Card from '../../CommonComponents/Card/Card';

import { Edit2 as Edit } from 'react-feather';

// Zoom & Grow transition import form Material UI
import { Grow, ClickAwayListener } from '@mui/material';

// Icon import from Feather icon
import { Trash as Delete } from 'react-feather';

// Default banner image
import BannerImg from '../../../Assets/Images/BannerImg.webp';
import { useRef } from 'react';

function BannerImageDropdown(props) {
  const inputFileRef = useRef(null);

  // const bannerImageChangeHandler = (e) => {
  //   const newBannerImage = e.target?.files?.[0];
  //   props.setBannerImg(e.target?.files?.[0]);
  //   props.setPersonData({ ...props.personData, bannerImg: URL.createObjectURL(newBannerImage) });
  //   props.setBannerImgDropdownActive(false);
  // }

  const bannerImageChangeHandler = (e) => {
    const newBannerImage = e.target?.files?.[0];
    // props.setBannerImg(e.target?.files?.[0]);
    // props.setPersonData({ ...props.personData, bannerImg: URL.createObjectURL(newBannerImage) });
    props.setBannerImgDropdownActive(false);
    if (newBannerImage) {
      props.onImageChange(newBannerImage);
    }
    e.target.value = null;
  }

  const bannerImgDeleteHandler = () => {
    props.setPersonData({ ...props.personData, bannerImg: BannerImg });
    props.setBannerImgDropdownActive(false);
  }

  return (
    <ClickAwayListener onClickAway={() => props.setBannerImgDropdownActive(false)}>
      <Grow in={true}>
        <div className={classes.dropdown}>
          <Card>
            <div className={classes.element}
              onClick={() => inputFileRef.current.click()}
            >
              <input
                ref={inputFileRef}
                hidden
                accept=".jpg, .jpeg, .png"
                id="avatar-image-upload"
                type="file"
                onChange={(e) => bannerImageChangeHandler(e)}
              />
              <Edit className={classes.icon} />
              <h6 style={{ display: 'contents' }} className="element-heading">Edit</h6>

            </div>
            <div className={classes.element} onClick={bannerImgDeleteHandler}>
              <Delete className={classes.icon} />
              <h6 style={{ display: 'contents' }} className="element-heading">Delete</h6>
            </div>
          </Card>
        </div>
      </Grow>
    </ClickAwayListener>
  );
}

export default BannerImageDropdown;
