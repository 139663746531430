import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HiPlay, HiVolumeOff, HiVolumeUp } from "react-icons/hi";
import { MdOutlineFullscreen } from "react-icons/md";
import classes from './TVCardVideo.module.css';
import { toggleMuteState } from '../../../../Store/videoPreferenceSlice';

export default function TVCardVideo({ src, aspectRatio, isVisible }) {
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(true);
  const isMuted = useSelector((state) => state.videoPreferenceSlice.isMuted);

  const onClickHandler = () => {
    if (isPlaying) {
      setIsPlaying(false);
      videoRef.current.pause();
    }
    else {
      setIsPlaying(true);
      videoRef.current.play();
    }
  };

  const onMuteClickHandler = () => {
    if (isMuted) {
      dispatch(toggleMuteState(false));
      videoRef.current.muted = false;
    }
    else {
      dispatch(toggleMuteState(true));
      videoRef.current.muted = true;
    }
  }

  const onFullscreenClickHandler = () => {
    videoRef.current.requestFullscreen();
  }

  const visibilityEffect = () => {
    if (isVisible) {
      setIsPlaying(true);
      videoRef.current.play();
    }
    else {
      setIsPlaying(false);
      videoRef.current.pause();
    }
  } 

  useEffect(() => {
    visibilityEffect();
  }, [isVisible]);

  useEffect(() => {
    const autoPlayTimer = setTimeout( visibilityEffect, 500);
    return () => clearTimeout(autoPlayTimer);
  },[]);

  useEffect(()=>{
    const onFullScreenChange = (_) => {
      if (!document.fullscreenElement) {
        dispatch(toggleMuteState(videoRef.current.muted));
        setIsPlaying(!videoRef.current.paused);
      }
    }
    videoRef.current.addEventListener('fullscreenchange', onFullScreenChange);
    return () => videoRef?.current && videoRef.current.removeEventListener('fullscreenchange', onFullScreenChange);
  }, [])

  return (
  <div className={classes.mainDiv}>
    <figure style={{ display: 'flex', position: 'relative', width:'100%', height:'100%' }} className={classes.videoContainer}>
      <video
        loop
        ref={videoRef}
        muted={isMuted}
        disablePictureInPicture
        preload='metadata'
        controlsList='nodownload'
        src={src}
        alt="PlaceholderVid"
        style={{ aspectRatio: `${1 / aspectRatio}`, backgroundColor: 'black' }}
      />

        {!isPlaying ?
          <button
            className={classes.play}
            onClick={onClickHandler}
          >
            <HiPlay className={classes.playpauseIcon} />
          </button>
          :
          <button
            className={classes.pause}
            onClick={onClickHandler}
          />
        }

      <div className={classes.controls}>
      <button 
      className={classes.mute} 
      onClick={onMuteClickHandler} 
      >
        {isMuted ?
          <HiVolumeOff className={classes.muteIcon} /> :
          <HiVolumeUp className={classes.muteIcon} />
        }
      </button>

      <button 
      className={classes.fullScreen} 
      onClick={onFullscreenClickHandler} 
      >
        <MdOutlineFullscreen className={classes.fullScreenIcon} />
      </button>
      </div>
    </figure>
  </div>);
}
